import React, {useState, useEffect, useRef} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import {useNavigate} from 'react-router-dom'
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import {useLocation} from "react-router-dom";
import CustomButtonContained from "../../components/general/CustomButtonContained";
import BackArrow from "../../assets/logos/backArrow.svg";
import Constants from '../../config/contants'
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import CustomTextFieldNew from "../../components/general/CustomTextFieldNew";
import {vehiclesMapSelector} from '../../redux/slices/vehiclesSlice'
import CustomSlider from '../../components/general/CustomSlider'
import CloudUploadIcon from "../../assets/logos/cloudUpload.svg";
import Styles from './styles/CheckInCheckOut.module.scss'
import Carousel from 'react-material-ui-carousel'
import ReactS3Client from "../../services/S3Client";
import Config from "../../config";
import {RENTAL_STATUS, API_CALL_STATUS} from '../../metadata/enums'
import {
	setUpdateReservationStates,
	updateReservation,
	updateReservationAndConfirm
} from '../../redux/slices/reservationsSlice'



const CheckInCheckOut = () => {
	const location = useLocation();
	const dispatch = useDispatch()
	const navigate = useNavigate();
	let path = location.pathname
	let fileRef = useRef(null)

	const token = useSelector(state => state.auth.token)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	const reservationState = useSelector(state => state.reservations)

	const [loading, setLoading] = useState(false)
	const [uploading, setUploading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [mileage, setMileage] = useState('')
	const [fuel, setFuel] = useState(0)
	const [mileageAllowance, setMileageAllowance] = useState('')
	const [selectedImages, setSelectedImages] = useState()
	const [finalToSave, setFinalToSave] = useState(null)

	useEffect(() => {
		if(!reservationState.reservation_to_update){
			navigate('/home/reservations')
		} else {
			if(!mileageAllowance) {
				let vehicle = vehiclesMap[reservationState.reservation_to_update.vehicle_id]
				setMileageAllowance(vehicle.mileage_allowance?.toString())
			}
		}

		if(reservationState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else {
			setLoading(false)
			if(reservationState.updated_reservation) {
				//it was successfully saved, go back
				dispatch(updateReservation(finalToSave))
				dispatch(setUpdateReservationStates(false))
				navigate(-1)
			}
		}
	},[reservationState])

	const handleFileChange = (event) => {
		let selectedFiles = event.target.files
		if(!selectedFiles || Object.keys(selectedFiles).length === 0)
			return

		setSelectedImages(Object.values(selectedFiles))
	}

	const onSubmitClicked = () => {
		if(!mileage || mileage.length === 0) {
			setErrorMessage('Please enter vehicle mileage')
			setShowError(true)
			return false
		}

		if(!mileageAllowance || mileageAllowance.length === 0) {
			setErrorMessage('Please enter daily mileage allowance')
			setShowError(true)
			return false
		}

		if(selectedImages && selectedImages.length > 0) {
			setUploading(true)
			let promises = []
			selectedImages.forEach((image,index) => {
				promises.push(uploadImageToS3(image))
			})

			Promise.all(promises)
				.then(values => {
					console.log('uploaded all images', values )
					setUploading(false)
					saveData(values)

				}).catch(err => {
				LOGGER.error('error when uploading images', err)
				setShowError(true)
				setErrorMessage('Something went wrong when uploading images')
				setLoading(false)
				setUploading(false)
			})


		} else {
			saveData([])
		}

	}

	const saveData = (imageUrls) => {
		setLoading(true)
		const checkObject = {
			mileage: Number(mileage),
			mileage_allowance: Number(mileageAllowance),
			fuel: Number(fuel),
			images: imageUrls
		}

		let toSave = {}

		if(path.includes('in')) {
			toSave['check_in'] = checkObject
			toSave['rental_status'] = RENTAL_STATUS.RENTING
		} else {
			toSave['check_out'] = checkObject
			toSave['rental_status'] = RENTAL_STATUS.RETURNED
		}

		let toSend = Object.assign({}, reservationState.reservation_to_update, toSave)
		setFinalToSave(toSend)
		dispatch(updateReservationAndConfirm({token, data: toSend}))

	}

	const uploadImageToS3 = async (image) => {
		return new Promise((resolve, reject) => {
			ReactS3Client
				.uploadFile(image)
				.then(data => {
					LOGGER.log('success', data)
					let url = Config.AWS_CLOUDFRONT_URL + data.key
					resolve(url)
				})
				.catch(err => LOGGER.error('error ', err))
		})
	}

	const renderImageSelector = () => {

		if(uploading) {
			return (
				<div className={`${Styles.imageContainer} ${GeneralStyles.boxShadow}`}>
					<CustomLoaderSpinner/>
				</div>
			)
		}

		if(selectedImages && Object.keys(selectedImages).length > 0) {
			return (
				<Carousel
					indicatorIconButtonProps={{style: {color: 'gray'}}}
					activeIndicatorIconButtonProps={{style: {backgroundColor: Colors.theme, color: Colors.theme}}}
				>
					{selectedImages.map((image,index) => {
						return (
							<div key={index.toString()} style={{width: '100%', marginLeft: 20, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
								<img alt="not fount" width={'100%'} height={260} src={URL.createObjectURL(image)} style={{borderRadius: 10}}/>
								<p
									style={{textDecoration: 'underline', cursor: 'pointer'}}
									onClick={() => {
										let temp = [...selectedImages]
										temp.splice(index,1)
										setSelectedImages(temp)
									}}
								>
									delete
								</p>
							</div>
						)
					})}
				</Carousel>
			)
		}

		return (
			<div className={`${Styles.imageContainer} ${GeneralStyles.boxShadow}`}>
				<img src={CloudUploadIcon} height={50} width={50} />
				<p className={GeneralStyles.darkText}>Upload Interior and Exterior Pictures</p>
				<CustomButtonContained
					style={{margin: '0 auto', height: 30}}
					text={'Browse to upload'}
					onClick={() => fileRef.current.click()}/>
				<input
					multiple={true}
					id="image"
					name="user[image]"
					type="file"
					accept="image/*"
					style={{display: 'none'}}
					onChange={handleFileChange}
					ref={fileRef} />

			</div>
		)
	}


	const renderContent = () => {
		let isStart = path.includes('in')
		return (
			<div style={{marginTop: 20}}>
				<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
					<div style={{width: '50%'}}>
						<label style={{fontSize: Constants.labelSize, fontWeight: 'bold', color: Colors.primaryTextColor}}>{`${isStart ? 'Starting' : 'Ending'} Information`}</label>
						<div style={{display: 'flex', flexDirection: 'column', marginBottom: 15, marginTop: 10}}>
							<CustomTextFieldNew
								width={'100%'}
								label={`${isStart ? 'Starting' : 'Ending'} Mileage`}
								placeholder={'Enter Mileage'}
								value={mileage}
								onChange={(text) => {
									if(isNaN(text))
										return
									setMileage(text)
								}}
							/>
						</div>

						<div style={{display: 'flex', flexDirection: 'column', marginBottom: 15, marginTop: 20}}>
							<CustomTextFieldNew
								width={'100%'}
								label={`Daily Mileage Allowance(miles)`}
								placeholder={'Enter Daily Mileage Allowance'}
								value={mileageAllowance}
								onChange={(text) => {
									if(isNaN(text))
										return
									setMileageAllowance(text)
								}}
							/>
						</div>

						<div style={{display: 'flex', flexDirection: 'column', marginTop: 40}}>
							<label style={{fontSize: Constants.labelSize, fontWeight: 'bold', color: Colors.primaryTextColor}}>{`${isStart ? 'Starting' : 'Ending'} Fuel Level`}</label>
							<div style={{marginTop: 40, width: '100%', alignSelf: 'center', marginLeft: 14}}>
								<CustomSlider
									max={100}
									min={0}
									value={fuel}
									setValue={setFuel}
									step={10}
								/>
							</div>
						</div>

					</div>


					{/*image selector*/}
					<div style={{width: '50%', marginLeft: 40}}>
						<label style={{fontSize: Constants.labelSize, fontWeight: 'bold', color: Colors.primaryTextColor}}>{`${isStart ? 'Starting' : 'Ending'} Pictures`}</label>
						{renderImageSelector()}
					</div>

				</div>

			</div>
		)
	}

	if(loading) {
		return (
			<div className={GeneralStyles.container} style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container} style={{overflowY: 'scroll', height: '98vh'}}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader
					header={
					path.includes('in') ?
						`Start Reservation - #${reservationState.reservation_to_update?._id}`
						:
						`End Reservation - #${reservationState.reservation_to_update?._id}`
				}/>

				<div style={{position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<CustomButtonContained
						style={{marginRight: 2}}
						text={path.includes('in') ? 'Confirm Reservation Start' : 'Confirm Reservation End'}
						onClick={onSubmitClicked}
					/>
					<img
						onClick={() => navigate(-1)}
						src={BackArrow} style={{width: 40, height: 40, cursor: 'pointer'}}/>
				</div>


			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setErrorMessage('')
				}}
				severity={'error'}
				openFlag={showError}
				message={errorMessage}
			/>

			{renderContent()}
		</div>
	)
}

export default CheckInCheckOut
