import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/userSlice'
import vehiclesSlice from './slices/vehiclesSlice'
import reservationsSlice from "./slices/reservationsSlice";
import authSlice from './slices/authSlice'
import analyticsSlice from "./slices/analyticsSlice"
import settingsSlice from "./slices/settingsSlice";
import clientsSlice from './slices/clientsSlice';
import claimsSlice from './slices/claimsSlice'

const store = configureStore({
	reducer: {
		// Define a top-level state field named `todos`, handled by `todosReducer`
		user: userSlice,
		vehicles: vehiclesSlice,
		reservations: reservationsSlice,
		auth: authSlice,
		analytics: analyticsSlice,
		settings: settingsSlice,
		clients: clientsSlice,
		claims: claimsSlice
	}
})


export default store
