import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Colors from '../../config/colors'
import Styles from "./Styles/CustomNavBar.module.scss";
import {auth} from '../../services/firebase'
import {useDispatch, useSelector} from 'react-redux'
import userSlice, {addUser} from '../../redux/slices/userSlice'
import { useLocation } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import CONSTANTS from '../../config/contants'
import {updateReservation} from '../../redux/slices/reservationsSlice'
import AddGray from '../../assets/logos/addGray.png'
import AddWhite from '../../assets/logos/addWhite.png'
import CalendarWhite from '../../assets/logos/calendarWhite.png'
import CalendarGray from '../../assets/logos/calendarGray.png'
import OverviewWhite from '../../assets/logos/overviewWhite.png'
import OverviewGray from '../../assets/logos/overviewGray.png'
import VehiclesWhite from '../../assets/logos/vehiclesWhite.png'
import VehiclesGray from '../../assets/logos/vehiclesGray.png'
import SettingsWhite from '../../assets/logos/settingsWhite.png'
import SettingsGray from '../../assets/logos/settingsGray.png'
import Logout from '../../assets/logos/logout.png'
import AnalyticsGray from '../../assets/logos/analyticsGray.png'
import AnalyticsWhite from '../../assets/logos/analyticsWhite.png'
import ClientsGray from '../../assets/logos/clientsGray.png'
import ClientsWhite from '../../assets/logos/clientsWhite.png'
import ClaimsGray from '../../assets/logos/claimsGray.svg'
import ClaimsWhite from '../../assets/logos/claimsWhite.svg'
import ExpandIcon from '../../assets/logos/expand.png'
import GPSWhite from '../../assets/logos/gpsWhite.svg'
import GPSGray from '../../assets/logos/gpsGray.svg'
import PaymentsWhite from '../../assets/logos/paymentsWhite.svg'
import PaymentsGray from '../../assets/logos/paymentsGray.svg'
import {updateClientToUpdate} from "../../redux/slices/clientsSlice";
import {updateClaimToUpdate} from "../../redux/slices/claimsSlice";
import {updateVehicle} from "../../redux/slices/vehiclesSlice";
import Config from '../../config'

const LINKS = [
	{label: 'Overview', id: 1, white: OverviewWhite, gray: OverviewGray},
	{label: 'Reservations', id: 2,white: AddWhite, gray: AddGray},
	{label: 'Calendar', id: 3, white: CalendarWhite, gray: CalendarGray},
	{label: 'Clients', id: 4, white: ClientsWhite, gray: ClientsGray},
	{label: 'Vehicles', id: 5, white: VehiclesWhite, gray: VehiclesGray},
	{label: 'GPS Telematics', id: 6, white: GPSWhite, gray: GPSGray},
	{label: 'Payments', id: 7, white: PaymentsWhite, gray: PaymentsGray},
	{label: 'Analytics', id: 8, white: AnalyticsWhite, gray: AnalyticsGray},
	{label: 'Claims', id: 9, white: ClaimsWhite, gray: ClaimsGray},
]

const CustomNavBar = ({accessCallback}) => {
	let navigate = useNavigate()
	let dispatch = useDispatch()
	const location = useLocation();
	let path = location.pathname
	const user = useSelector(state => {
		return state.user.user
	})

	const [activeItemId, setActiveItemId] = useState(1)
	const [collapsed, setCollapsed] = useState(false)

	useEffect(() => {
		if(path.includes('overview'))
			setActiveItemId(1)
		else if(path.includes('reservation') || path.includes('new-reservation') || path.includes('new-chauffeur'))
			setActiveItemId(2)
		else if(path.includes('calendar'))
			setActiveItemId(3)
		else if(path.includes('clients'))
			setActiveItemId(4)
		else if(path.includes('vehicle'))
			setActiveItemId(5)
		else if(path.includes('gps'))
			setActiveItemId(6)
		else if(path.includes('payments'))
			setActiveItemId(7)
		else if(path.includes('analytics'))
			setActiveItemId(8)
		else if(path.includes('claims'))
			setActiveItemId(9)
		else if(path.includes('settings'))
			setActiveItemId(10)
	},[path])

	const onSignOutClicked = () => {
		dispatch(addUser(null))
		auth.signOut();
	}

	const getIcon = ({id, white, gray}) => {
			return (
				<img
					src={activeItemId === id ? white : gray}
					style={{height: 20, width: 22, marginLeft: collapsed ? 10 : 20}}
				/>
			)

	}

	const onItemSelect = (itemId) => {
		setActiveItemId(itemId)
		dispatch(updateReservation(null))
		dispatch(updateClientToUpdate(null))
		dispatch(updateClaimToUpdate(null))
		dispatch(updateVehicle(null))
		switch (itemId) {
			case 1:
				navigate('/home/overview')
				break;
			case 2:
				navigate('/home/reservations')
				break;
			case 3:
				navigate('/home/calendar')
				break;
			case 4:
				navigate('/home/clients')
				break;
			case 5:
				navigate('/home/vehicles')
				break;
			case 6:
				navigate('/home/gps')
				break;
			case 7:
				navigate('/home/payments')
				break;
			case 8:
				navigate('/home/analytics')
				break;
			case 9:
				navigate('/home/claims')
				break;
			default:
		}
	}

	const renderLinks = () => {
		let elements = LINKS.map((link,index) => {
			return (
				<div
					style={{
						borderRight: activeItemId === link.id ? `3px solid white` : '',
						marginTop: 10
					}}
					key={index.toString()}
					onClick={() => onItemSelect(link.id)}
					className={Styles.linkRow}>
					{
						collapsed ?
							<Tooltip title={link.label}>
								{getIcon(link)}
							</Tooltip>
							:
							getIcon(link)
					}
					{!collapsed &&
						<p
							style={
								{
									width: '100%',
									color: activeItemId !== link.id ?  'gray' : 'white' ,
									marginLeft: 20}}>
							{link.label}
						</p>}
				</div>
			)
		})

		return (
			<div style={{display: 'flex', flexDirection: "column", justifyContent: "center", width: '100%', alignItems: 'center', marginTop: 20}}>
				{elements}
			</div>
		)
	}

	const renderFullContent = () => {
		return (
			<div style={{width: '100%'}}>
				<div style={{display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-evenly', width: '100%', marginTop: 20}}>
					{/*<p style={{fontWeight: 'bold', fontSize: 20}}>GoLux Rentals</p>*/}
					{
						Config.NAME.length > 10 ?
						<label style={{fontSize: 16, fontWeight: 'bold', letterSpacing: '.2rem'}}>{Config.NAME}</label>
						:
						<label style={{fontSize: 20, fontWeight: 'bold', letterSpacing: '.3rem'}}>{Config.NAME}</label>
					}

					{/*<img*/}
					{/*	src={require('../../assets/images/logo.png').default} width={160} />*/}
					<img
						onClick={() => setCollapsed(true)}
						src={ExpandIcon} style={{height: 17, width: 17, cursor: 'pointer'}} />
				</div>
				{renderLinks()}



				<div
					style={{position: 'absolute', bottom: 20, padding: '0 20px'}}>
					<div
						onClick={() => {
							setActiveItemId(10)
							navigate('/home/settings')
						}}
						className={Styles.linkRow}>
						<img
							src={SettingsWhite} style={{height: 18, width: 18}} />
						<label
							style={{color: Colors.tertiaryTextColor, marginLeft: 20, cursor: 'pointer'}}>
							Settings
						</label>
					</div>

					<div
						onClick={onSignOutClicked}
						className={Styles.linkRow}>
						<img
							src={Logout} style={{height: 18, width: 18}} />
						<label
							style={{color: Colors.tertiaryTextColor, marginLeft: 20, cursor: 'pointer'}}>
							Logout
						</label>
					</div>
				</div>
			</div>
		)
	}

	const renderCollapsedContent = () => {
		return (
			<div style={{width: '100%'}}>
				<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
					<img
						onClick={() => setCollapsed(false)}
						src={ExpandIcon} style={{height: 17, width: 17, cursor: 'pointer'}} />
				</div>

				{renderLinks()}
				<div style={{position: 'absolute', bottom: 20, padding: '0px 4px', cursor: 'pointer'}}>
					<Tooltip title={'Sign Out'}>
						<img
							onClick={() => {
								setActiveItemId(10)
								navigate('/home/settings')
							}}
							src={SettingsWhite} style={{height: 18, width: 18, marginLeft: 10, marginBottom: 20}}></img>
					</Tooltip>

					<Tooltip title={'Sign Out'}>
						<img
							onClick={onSignOutClicked}
							src={Logout} style={{height: 18, width: 18, marginLeft: 10}}></img>
					</Tooltip>
				</div>
			</div>
		)
	}

	return (
		<div
			className={Styles.navBarContainer}
			style={{width: collapsed ? 50 : 260}}

		>
			{
				collapsed ?
					renderCollapsedContent()
					:
					renderFullContent()
			}
		</div>
	)
}

export default CustomNavBar
