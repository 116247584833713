import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from '../../components/general/PageHeader'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker, Calendar } from 'react-date-range';
import moment from 'moment'
import CustomModal from '../../components/general/CustomModal'
import Colors from '../../config/colors'
import Styles from './styles/NewReservation.module.scss'
import {useSelector} from 'react-redux'
import CustomButtonContained from "../../components/general/CustomButtonContained";
import {API_CALL_STATUS, RENTAL_STATUS, LOCATIONS, VEHICLE_TYPES} from "../../metadata/enums";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import colors from "../../config/colors";
import Config from '../../config'

import Helpers from '../../utils/helpers'
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import {useDispatch} from 'react-redux'
import {vehiclesMapSelector} from '../../redux/slices/vehiclesSlice'
import {updateNewReservationStatus, addReservationAndConfirm, updateReservationAndConfirm, setUpdateReservationStates, updateReservation} from '../../redux/slices/reservationsSlice'
import {useNavigate} from 'react-router-dom'
import reservations from "./Reservations";
import CustomSelect from '../../components/general/CustomSelect'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import CustomPDFChauffeur from '../../components/general/CustomPDFChauffeur'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomGooglePlaces from "../../components/general/CustomGooglePlaces";
import {getBusinessSettings, getPriceSettings, getSalesSettings} from "../../redux/slices/settingsSlice";
import Constants from "../../config/contants";
import BackArrow from "../../assets/logos/backArrow.svg";
import CustomSlider from "../../components/general/CustomSlider";
import Carousel from "react-material-ui-carousel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChauffeurPdfViewerComponent from '../../components/general/ChauffeurPdfViewerComponent'
import {changeUserAccess} from "../../redux/slices/authSlice";
import {getSalesUsers} from "../../redux/slices/userSlice";
import {
	addClient,
	addReservationForClient,
	clientsMapSelector,
	updateClientAndConfirm
} from '../../redux/slices/clientsSlice'
import CustomGooglePlacesNew from "../../components/general/CustomGooglePlacesNew";
import CustomTextFieldNew from '../../components/general/CustomTextFieldNew'
import ScrollDocImage from '../../assets/logos/scroll.svg'
import axios from "axios";
import {LOGGER} from "../../utils/Logger";



const SORT_ORDER_INDEXES = ['price-inc', 'price-dec', 'name-inc', 'name-dec']

const getEmptyCustomer = () => {
	return (
		{
			fName: '',
			lName: '',
			email: '',
			phone: '',
			address: null
		}
	)
}

const getEmptyLicense = () => {
	return {
		number: '',
		expiry_date: new Date(),
		state: ''
	}
}

const getEmptyInsurance = () => {
	return {
		provider: '',
		policy_no: ''
	}
}



const NewChauffeur = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const mongoUser = useSelector(state => state.user.mongoUser)
	const settingsState = useSelector(state => state.settings)
	const clientsState = useSelector(state => state.clients)
	const priceSettings = useSelector(state => getPriceSettings(state.settings))
	const businessSettings = useSelector(state => getBusinessSettings(state.settings))
	const token = useSelector(state => state.auth.token)

	const vehiclesState = useSelector(state => {
		return state.vehicles
	})

	const salesUsers = useSelector(state => getSalesUsers(state))

	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))

	const reservationState = useSelector(state => state.reservations)

	const [fetchingBlockedVehicles, setFetchingBlockedVehicles] = useState(false)
	const [clientInfoUpdated, setClientInfoUpdated] = useState(false)
	const [selectedClient, setSelectedClient] = useState({label: 'New Client', value: 'none'})
	const [pricing, setPricing] = useState(null)
	const [startDate, setStartDate] = useState(moment().add(1,'day').toDate())
	const [endDate, setEndDate] = useState(moment().add(1,'day').toDate())
	const [pickupTime, setPickupTime] = useState(moment().minutes(0).hours(10).toDate())
	const [returnTime, setReturnTime] = useState(moment().minutes(0).hours(13).toDate())
	const [showDateSelector, setShowDateSelector] = useState(false)
	const [availableVehicles, setAvailableVehicles] = useState([])
	const [unavailableVehicles, setUnavailableVehicles] = useState([])
	const [showLoading, setShowLoading] = useState(false)
	const [selectedVehicle, setSelectedVehicle] = useState(null)
	const [sortOrder, setSortOrder] = useState(null)
	const [notes, setNotes] = useState('')
	const [totalPrice, setTotalPrice] = useState(0)
	const [hourlyPrice, setHourlyPrice] = useState(0)
	const [selectedType, setSelectedType] = useState('all')
	const [showError, setShowError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [showPdfModal, setShowPdfModal] = useState(false)
	const [showBlockingReservationModal, setShowBlockingReservationModal] = useState(false)
	const [blockingReservationToDisplay, setBlockingReservationToDisplay] = useState(null)
	const [salesPerson, setSalesPerson] = useState(null)
	const [locations, setLocations] = useState([null,null])
	const [showDiscountModal, setShowDiscountModal] = useState(false)
	const [discountCode, setDiscountCode] = useState(null)
	const [previouslySelected, setPreviouslySelected] = useState(null)
	const [changeVehicleClicked, setChangeVehicleClicked] = useState(false)
	const [customDiscount, setCustomDiscount] = useState('')
	const [tab, setTab] = useState(1)
	const [customerInfo, setCustomerInfo] = useState({
		fName: '',
		lName: '',
		email: '',
		phone: '',
		address: null
	})

	const [licenseInfo, setLicenseInfo] = useState({
		number: '',
		expiry_date: new Date(),
		state: ''
	})
	const [insuranceInfo, setInsuranceInfo] = useState({
		provider: '',
		policy_no: '',
	})
	const [showLicenseDateSelector, setShowLicenseDateSelector] = useState(false)



	useEffect(() => {
		if(clientsState.status !== API_CALL_STATUS.LOADING
			&& clientsState.clients.length > 0
			&& reservationState.reservation_to_update) {

			let clientsMap = clientsMapSelector(clientsState)
			let client = clientsMap[reservationState.reservation_to_update.client] || {}
			setSelectedClient({label: `${client.fName} ${client.lName}`, value: client._id})
			setCustomerInfo(client)
			if(client.insurance)
				setInsuranceInfo(client.insurance)
			else
				setInsuranceInfo(getEmptyInsurance())
			if(client.license) {
				let temp = Object.assign({}, client.license, {expiry_date: moment(client.license.expiry_date).toDate()})
				setLicenseInfo(temp)
			} else {
				setLicenseInfo(getEmptyLicense())
			}

		}
	},[clientsState, reservationState.reservation_to_update])

	useEffect(() => {
		if(selectedClient && selectedClient.value !== 'none') {
			let clientsMap = clientsMapSelector(clientsState)
			let client = clientsMap[selectedClient.value] || {}
			setCustomerInfo(client)
			if(client.insurance)
				setInsuranceInfo(client.insurance)
			else
				setInsuranceInfo(getEmptyInsurance())
			if(client.license) {
				let temp = Object.assign({}, client.license, {expiry_date: moment(client.license.expiry_date).toDate()})
				setLicenseInfo(temp)
			}else {
				setLicenseInfo(getEmptyLicense())
			}

		} else {
			setCustomerInfo(getEmptyCustomer())
			setLicenseInfo(getEmptyLicense())
			setInsuranceInfo(getEmptyInsurance())

		}
	},[selectedClient])

	useEffect(() => {
		if(reservationState.status !== API_CALL_STATUS.LOADING) {
			setShowLoading(false)

			if(reservationState.updated_reservation)  {
				//got back here after the reservation awas update
				setSelectedVehicle(null)
				setPreviouslySelected(null)
				dispatch(updateReservation(null))
				dispatch(setUpdateReservationStates(false))
				navigate(-1)
			}
		} else {
			setShowLoading(true)
		}
	},[reservationState])

	useEffect(() => {
		//if reservation exists, get the attribution from it or keep it null
		if(reservationState.reservation_to_update) {
			if(!salesPerson && reservationState.reservation_to_update.sales_attribution) {
				let salesAtt = reservationState.reservation_to_update.sales_attribution
				salesUsers.forEach(user => {
					if(user._id === salesAtt) {
						setSalesPerson({label: user.name, value: user._id})
					}
				})
			}
		} else {
			//if it doesnt exist, check if the logged in user is a sales associate and use them automatically
			if(!salesPerson && mongoUser?.role === 3)
				setSalesPerson({label: mongoUser.name, value: mongoUser._id})
		}
	},[salesUsers])

	useEffect(() => {
		//if the user is updating the exiting reservation
		if(reservationState.reservation_to_update) {
			let reservationToUpdate = JSON.parse(JSON.stringify(reservationState.reservation_to_update))
			let chosenVehicle = vehiclesMap[reservationToUpdate.vehicle_id]
			let chosenLocation = reservationToUpdate.pickup_location
			let pickupDateTime = new Date(reservationToUpdate.pickup_datetime)
			let returnDateTime = new Date(reservationToUpdate.dropoff_datetime)
			setSelectedVehicle(chosenVehicle)
			setPreviouslySelected(chosenVehicle)
			setHourlyPrice(reservationToUpdate.hourlyPrice || chosenVehicle.hourlyPrice)
			setTotalPrice(reservationToUpdate.totalPrice)
			setPickupTime(pickupDateTime)
			setReturnTime(returnDateTime)
			setStartDate(pickupDateTime)
			setEndDate(returnDateTime)
			setLocations(reservationToUpdate.locations)
			if(reservationToUpdate.customer) {
				//new client is added, so add them to the client list
				dispatch(addClient(reservationToUpdate.customer))
				setCustomerInfo(reservationToUpdate.customer)
			}



			let pricing = {
				deposit: reservationToUpdate.pricing?.deposit || chosenVehicle.deposit,
				mileage_overage_price: reservationToUpdate.pricing?.mileage_overage_price || chosenVehicle.mileage_overage_price,
				service_tax: reservationToUpdate.pricing?.service_tax || priceSettings?.service_tax['chauffeur'],
				discount: reservationToUpdate.pricing?.discount || {},
			}

			setPricing(pricing)
		}
	},[reservationState.reservation_to_update])

	useEffect(() => {
		if(!token || Object.keys(vehiclesMap).length === 0)
			return

		if(!reservationState.reservation_to_update)
			setSelectedVehicle(null)
		if(!fetchingBlockedVehicles)
			asyncFunctions()
		else
			return

	},[vehiclesState, startDate, endDate, pickupTime, returnTime, reservationState, token])

	async function asyncFunctions() {
		let finalPickupDatetime = moment(startDate).hours(pickupTime?.getHours()).minutes(pickupTime?.getMinutes()).toDate().toISOString()
		let finalReturnDatetime = moment(endDate).hours(returnTime?.getHours()).minutes(returnTime?.getMinutes()).toDate().toISOString()
		if(moment(finalReturnDatetime).diff(finalPickupDatetime, 'hours') < 1)
			return
		setFetchingBlockedVehicles(true)

		let blockingRes = await getBlockingResOrMain(true, finalPickupDatetime, finalReturnDatetime)
		let blockingMain = await getBlockingResOrMain(false, finalPickupDatetime, finalReturnDatetime)
		setFetchingBlockedVehicles(false)
		let total = blockingRes.concat(blockingMain)
		//if the user is changing the dates, do not consider the current reservation itself as blocking
		if(reservationState.reservation_to_update) {
			total = total.filter(temp => temp._id !== reservationState.reservation_to_update._id)
		}
		//dedupe and get only ids
		let forbiddenVehicleIds = total.filter((v,i,a)=>a.findIndex(v2=>(v2.vehicle_id===v.vehicle_id))===i).map(temp => temp.vehicle_id)
		let ava = []
		let unAva = []
		console.log('forbidden', forbiddenVehicleIds)
		const GRACE_PERIOD = 0
		Object.values(vehiclesMap).forEach(vehicle => {
			if(vehicle.status === false)
				return


			let temp = Object.assign({}, vehicle)
			let blockingReservationsForVehicle = blockingRes.filter(res => res.vehicle_id === temp.id)
			let blockingMaintenancesForVehicle = blockingMain.filter(res => res.vehicle_id === temp.id)
			blockingReservationsForVehicle.sort((a,b) => {
				return new Date(a.dropoff_datetime) - new Date(b.pickup_datetime)
			})

			blockingMaintenancesForVehicle.sort((a,b) => {
				return new Date(a.end_date) - new Date(b.start_date)
			})



			if(forbiddenVehicleIds.includes(temp.id)) {
				temp.available = false
				let lastBlockingReservation = blockingReservationsForVehicle[blockingReservationsForVehicle.length - 1] || {dropoff_datetime: moment().subtract(1, 'year').toDate()}
				let lastBlockingMaintenance = blockingMaintenancesForVehicle[blockingMaintenancesForVehicle.length - 1] || {end_date: moment().subtract(1, 'year').toDate()}
				if(moment(lastBlockingReservation.dropoff_datetime).isAfter(moment(lastBlockingMaintenance.end_date))) {
					temp.nextAvailable = moment(lastBlockingReservation.dropoff_datetime).add(GRACE_PERIOD,'hour').toDate()
					temp.blockingReservation = lastBlockingReservation
				} else {
					temp.nextAvailable = moment(lastBlockingMaintenance.end_date).add(GRACE_PERIOD,'hour').toDate()
					temp.blockingMaintenance = lastBlockingMaintenance
				}

				// temp.blockingReservation = lastBlockingReservation
				if(temp.id === previouslySelected?.id) {
					temp.previous = true
					unAva.unshift(temp)
				} else {
					unAva.push(temp)
				}
			} else {
				temp.available = true
				if(temp.id === previouslySelected?.id) {
					temp.previous = true
					ava.unshift(temp)
				} else {
					ava.push(temp)
				}

			}
		})

		setAvailableVehicles(ava)
		setUnavailableVehicles(unAva)
		setShowLoading(false)

	}


	const getBlockingResOrMain = async (isReservation, startDate, endDate) => {
		return new Promise((resolve, reject) => {
			let url
			if(isReservation)
				url = `${Config.BACKEND_URL}reservations/blocking?start_date=${startDate}&end_date=${endDate}`
			else
				url = `${Config.BACKEND_URL}maintenances/blocking?start_date=${startDate}&end_date=${endDate}`

			const config = {
				method: 'get',
				url: url,
				headers: { Authorization: token, contentType: "application/json"},
			}
			axios(config)
				.then(res => resolve(res.data))
				.catch(err => {
					LOGGER.error('error when getting blocing something', err)
					reject(err)
				})
		})
	}

	const handleDateSelect = (ranges) => {
		let {selection} = ranges
		// if(!moment(selection.startDate).isAfter(moment())) {
		// 	setShowError(true)
		// 	setErrorMessage('Pick up date must be in the future')
		// 	return
		// }

		setStartDate(selection.startDate)
		setEndDate(selection.endDate)
	}

	const handlePhoneChange = (text) => {
		if(selectedClient && selectedClient.value !== 'none')
			setClientInfoUpdated(true)

		setCustomerInfo(Object.assign({}, customerInfo, {phone: Helpers.checkPhoneNumberFormat(text)}))
	}

	const validateData = () => {
		let missingField = false
		let ignoreFields = ['total_reservations', 'insurance', 'license', 'address']
		Object.keys(customerInfo).every(field => {
			if(ignoreFields.includes(field))
				return true

			if(!customerInfo[field] || customerInfo[field].length === 0) {
				missingField = true
				return false
			}

			return true
		})

		if(missingField) {
			setShowError(true)
			setErrorMessage(`Missing customer information`)
			return false
		}

		if(!customerInfo.address?.label || customerInfo.address.label.length === 0) {
			setShowError(true)
			setErrorMessage('Missing Customer Address')
			return false
		}

		if(!customerInfo['address'] || !customerInfo['address'].label) {
			setShowError(true)
			setErrorMessage(`Missing customer address`)
			return false
		}

		if(!Helpers.validateEmail(customerInfo.email)) {
			setShowError(true)
			setErrorMessage(`Invalid Email`)
			return false
		}

		if(customerInfo.phone.length !== 14) {
			setShowError(true)
			setErrorMessage(`Invalid phone number`)
			return false
		}

		if(totalPrice.length === 0) {
			setShowError(true)
			setErrorMessage(`Please enter total price for the contract`)
			return false
		}

		let locationsFlag = false
		locations.forEach((location,i) => {
			if((!location || location.label?.length === 0) && (i === 0 || i === 1))
				locationsFlag = true

		})

		if(locationsFlag) {
			setShowError(true)
			setErrorMessage(`Please enter pick-up location and drop-off location`)
			return false
		}

		return true
	}

	const onSubmitClicked = () => {
		if(!validateData()) {
			return
		}



		let finalPickupDatetime = moment(startDate).hours(pickupTime.getHours()).minutes(pickupTime.getMinutes()).toDate().toISOString()
		let finalReturnDatetime = moment(endDate).hours(returnTime.getHours()).minutes(returnTime.getMinutes()).toDate().toISOString()

		let pricingTemp = {}
		for (let key in pricing) {
			// skip loop if the property is from prototype
			if (!pricing.hasOwnProperty(key)) continue;
			if(key === 'discount')
				pricingTemp[key] = pricing[key]
			else
				pricingTemp[key] = Number(pricing[key])
		}

		let toSaveReservation = {
			vehicle_id: selectedVehicle.id,
			pickup_datetime: finalPickupDatetime,
			dropoff_datetime: finalReturnDatetime,
			rental_status: RENTAL_STATUS.WAITING_PICKUP,
			paid: false,
			totalPrice: getTotalTime() * (hourlyPrice || selectedVehicle.hourlyPrice),
			hourlyPrice: hourlyPrice || selectedVehicle.hourlyPrice,
			isChauffeur: true,
			locations: locations.filter(location => location && location.label?.length > 0),
			sales_attribution: salesPerson?.value,
			pricing: pricingTemp
		}

		if(selectedClient && selectedClient.value !== 'none') {
			toSaveReservation['client'] = selectedClient.value
			dispatch(addReservationForClient(selectedClient.value))
			if(clientInfoUpdated) {
				console.log('client needs to be updated as well')
				let toSend = Object.assign({}, customerInfo, {_id: selectedClient.value})
				if(insuranceInfo?.provider && insuranceInfo?.provider.length > 0)
					toSend['insurance'] = insuranceInfo

				if(licenseInfo?.number && licenseInfo?.number.length > 0)
					toSend['license'] = Object.assign({}, licenseInfo, {expiry_date: new Date(licenseInfo.expiry_date).toISOString()})


				dispatch(updateClientAndConfirm({token, data: toSend}))
				setClientInfoUpdated(false)
			}
		} else {
			let customer = Object.assign({}, customerInfo, {created_at: new Date().toISOString()})
			if(insuranceInfo?.provider && insuranceInfo?.provider.length > 0)
				customer['insurance'] = insuranceInfo

			if(licenseInfo?.number && licenseInfo?.number.length > 0)
				customer['license'] = Object.assign({}, licenseInfo, {expiry_date: new Date(licenseInfo.expiry_date).toISOString()})

			toSaveReservation['customer'] = customer

		}


		setShowLoading(true)
		if(reservationState.reservation_to_update) {
			toSaveReservation._id = reservationState.reservation_to_update._id
			toSaveReservation['rental_status'] = reservationState.reservation_to_update.rental_status
			if(reservationState.reservation_to_update.check_in)
				toSaveReservation['check_in'] = reservationState.reservation_to_update.check_in

			if(reservationState.reservation_to_update.check_out)
				toSaveReservation['check_out'] = reservationState.reservation_to_update.check_out

			dispatch(updateReservationAndConfirm({token, data: toSaveReservation}))
		} else {
			dispatch(addReservationAndConfirm({token, data: toSaveReservation}))
		}

	}

	const onAddDiscountClicked = () => {
		if(!discountCode && (!customDiscount || customDiscount.length === 0)) {
			setShowError(true)
			setErrorMessage('Please select a discount code or enter a custom discount amount')
			return
		}

		setShowDiscountModal(false)
		let temp = JSON.parse(JSON.stringify(pricing))
		temp['discount'] = {
			label: discountCode ? discountCode.label : 'Custom Amount',
			value: discountCode ? Number(discountCode.value) : Number(customDiscount),
		}
		setPricing(temp)
	}

	const onBackClicked = () => {
		if(selectedVehicle) {
			setPreviouslySelected(null)
			setSelectedVehicle(null)
			setCustomerInfo(getEmptyCustomer())
			setLicenseInfo(getEmptyLicense())
			setInsuranceInfo(getEmptyInsurance())
			if(reservationState.reservation_to_update) {
				dispatch(updateReservation(null))
				navigate(-1)
			}
		} else {
			navigate(-1)
		}
	}

	const renderLicenseDateModal = () => {
		return (
			<CustomModal
				show={showLicenseDateSelector}
				handleClose={() => setShowLicenseDateSelector(false)}
				containerWidth={window.innerWidth/1.5}
				containerHeight={window.innerHeight/1.8}
			>
				<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: Colors.backgroundColor}}>
					<Calendar
						date={moment(licenseInfo?.expiry_date).toDate()}
						onChange={(date) => {
							if(selectedClient && selectedClient.value !== 'none')
								setClientInfoUpdated(true)
							setLicenseInfo(Object.assign({}, licenseInfo, {expiry_date: date}))
						}}
					/>

					<CustomButtonContained
						text={'Done'}
						onClick={() => {
							setShowLicenseDateSelector(false)
						}}
						style={{marginTop: 2}}
					/>

				</div>
			</CustomModal>
		)
	}



	const renderTimeSelector = (value, setValue, isPickUp) => {
		return (
			<div style={{width: 140, backgroundColor: Colors.theme, height: 40,display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderRadius: 10}}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<TimePicker
						value={value}
						onChange={(newValue) => {
							setSelectedVehicle(null)
							setValue(newValue)
						}}
						renderInput={(params) => {
							return <TextField
								sx={{
									'.MuiOutlinedInput-root': {backgroundColor: Colors.theme, color: Colors.tertiaryTextColor, height: 30, borderRadius: 5},
									'.MuiSvgIcon-root': {color: Colors.tertiaryTextColor},
								}}
								InputLabelProps={{style:{color: colors.tertiaryTextColor}}}
								{...params} />
						}}
					/>
				</LocalizationProvider>
			</div>
		)
	}

	const onDateRangeSelectorClicked = () => {
		if(reservationState.reservation_to_update)
			setSelectedVehicle(null)

		setShowDateSelector(true)
	}

	const renderDateSelectors = () => {
		return (
			<div style={{marginTop: 10}}>
				<label
					className={GeneralStyles.darkText}
					style={{fontWeight: 'bold', width: '100%', marginBottom: 20, marginTop: 10, fontSize: 18}}>Chauffeur Dates</label>
				<div
					className={Styles.dateRangeDisplayContainer}>
					<div
						onClick={onDateRangeSelectorClicked}
						style={{display: 'flex', flexDirection: 'row', width: 280, borderRadius: 10, alignItems: 'center', justifyContent: 'space-evenly', backgroundColor: Colors.theme, height: 40}}>
						<p className={GeneralStyles.lightText}>{moment(startDate).format('dddd, MMM Do YYYY')}</p>
						<CalendarMonthIcon style={{color: 'white'}}/>
					</div>
					{renderTimeSelector(pickupTime, setPickupTime)}
					<p className={GeneralStyles.darkText} style={{fontSize: 28, marginLeft: '2%', marginRight: '2%'}}>
						{`\u2B64`}
					</p>
					<div
						onClick={onDateRangeSelectorClicked}
						style={{display: 'flex', flexDirection: 'row', width: 280, borderRadius: 10, alignItems: 'center', justifyContent: 'space-evenly', backgroundColor: Colors.theme, height: 40}}>
					<p className={GeneralStyles.lightText}>{moment(endDate).format('dddd, MMM Do YYYY')}</p>
						<CalendarMonthIcon style={{color: 'white'}}/>
					</div>

					{renderTimeSelector(returnTime, setReturnTime)}


				</div>
			</div>
		)
	}

	const renderDateSelectorModal = () => {
		const selectionRange = {
			startDate: startDate,
			endDate: endDate,
			key: 'selection',
		}
		return (
			<CustomModal show={showDateSelector} handleClose={() => setShowDateSelector(false)} containerWidth={window.innerWidth/2}>
				<DateRangePicker
					rangeColors={[Colors.themeLight]}
					ranges={[selectionRange]}
					onChange={handleDateSelect}
				/>
			</CustomModal>
		)
	}

	const getChangeButton = () => {
		return (
			<CustomButtonContained
				color={"secondary"}
				borderColor={Colors.themeLight}
				style={{border: `1px solid ${Colors.themeLight}`}}
				text={'Change Vehicle'}
				onClick={() => {
					setPreviouslySelected(selectedVehicle)
					setSelectedVehicle(null)
					setChangeVehicleClicked(!changeVehicleClicked)
				}}/>
		)
	}

	const onVehicleSelected = (vehicle) => {

		let totalPrice = getTotalTime() * vehicle.hourlyPrice
		setHourlyPrice(vehicle.hourlyPrice)
		setTotalPrice(totalPrice)
		setSelectedVehicle(vehicle)
		setPreviouslySelected(vehicle)

		let reservationToUpdate = reservationState.reservation_to_update

		//the info can come either form reservation or the chosen vehicle and settings
		let pricing = {
			deposit: reservationToUpdate?.pricing?.deposit || vehicle.deposit,
			mileage_overage_price: reservationToUpdate?.pricing?.mileage_overage_price || vehicle.mileage_overage_price,
			service_tax: reservationToUpdate?.pricing?.service_tax || priceSettings?.service_tax['chauffeur'],
			discount: reservationToUpdate?.pricing?.discount || {},
		}
		setPricing(pricing)
	}

	const getSelectButton = (vehicle) => {
		if(vehicle.available) {
			return (
				<CustomButtonContained
					style={{}}
					text={'Select Vehicle'}
					onClick={() => onVehicleSelected(vehicle)}/>
			)
		} else {
			let nextAvailable = moment(vehicle.nextAvailable).format('MM/DD/YY')
			return (
				<CustomButtonContained
					textColor={Colors.themeLight}
					color={'secondary'}
					borderColor={Colors.themeLight}
					text={`Next Available: ${nextAvailable}`}
					onClick={() => {
						setShowBlockingReservationModal(true)
						setBlockingReservationToDisplay(vehicle.blockingReservation || vehicle.blockingMaintenance)
					}}/>

			)
		}
	}

	const getAvailableLabel = (vehicle) => {
		if(vehicle.available) {
			return (
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<FiberManualRecordIcon style={{color: Colors.availableColor, height: 20, width: 20, marginRight: 6}}/>
					<label style={{color: Colors.primaryTextColor, fontWeight: 600, fontSize: 13}}>Available</label>
				</div>
			)
		} else {
			return (
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<FiberManualRecordIcon style={{color: Colors.unavailableColor, height: 20, width: 20, marginRight: 6}}/>
					<label style={{color: Colors.primaryTextColor, fontWeight: 600, fontSize: 13}}>Unvailable</label>
				</div>
			)
		}
	}

	const renderContractBox = () => {
		return (
			<div style={{marginBottom: 30, height: 150, display: reservationState.reservation_to_update ? 'block' : 'none', width: 300}}>
				{/*<p style={{fontSize: 18}}>Pending Rental Agreement</p>*/}
				<div
					className={GeneralStyles.boxShadow}
					style={{height: '100%',  borderRadius: 10, marginTop: 20, width: '100%', marginRight: 10, paddingBottom: 10}}>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
						<img src={ScrollDocImage} height={30} width={30}/>
						<p style={{fontWeight: 'bold', marginLeft: 5, fontSize: 14}}>Chauffeur Contract</p>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						width: '100%',
						justifyContent: 'space-evenly',
						marginTop: 20,
					}}>
					<CustomButtonContained
						text={'Print'}
						onClick={onPrintClicked}
						style={{height: 34}}
						textPadding={'4px 10px'}
					/>
					<CustomButtonContained
						borderColor={Colors.themeLight}
						color={"secondary"}
						text={'Download'}
						onClick={onPrintClicked}
						style={{height: 34, border: '1px solid white'}}
						textPadding={'4px 10px'}
					/>
				</div>

				</div>
			</div>
		)
	}

	const getVehicleVisibility = (vehicle) => {
		if(selectedType === 'all')
			return 'flex'

		if(vehicle.type.includes(selectedType))
			return 'flex'
		else
			return 'none'
	}

	const getTotalTime = () => {
		let finalPickupDatetime = moment(startDate).hours(pickupTime.getHours()).minutes(pickupTime.getMinutes())
		let finalReturnDatetime = moment(endDate).hours(returnTime.getHours()).minutes(returnTime.getMinutes())
		return (moment(finalReturnDatetime).diff(moment(finalPickupDatetime), 'hours'))
	}

	const renderVehicleRow = (vehicle,index, isForm) => {
		let totalPriceToDisplay = getTotalTime() * (hourlyPrice || vehicle.hourlyPrice)
		let reservationToUpdate = reservationState.reservation_to_update

		let tax = reservationToUpdate?.pricing?.service_tax || priceSettings['service_tax']['chauffeur']
		let priceWithTax = Number(totalPriceToDisplay + (tax * totalPriceToDisplay)/100)

		return (
			<div
				className={`${Styles.vehicleRow} ${GeneralStyles.boxShadow}`}
				key={index.toString()}
				style={{
					display: getVehicleVisibility(vehicle)
				}}
			>
				<div style={{width: '30%', marginRight: 10, minHeight: 100}}>
					<img src={vehicle.pictureUrl} width={'100%'} height={'100%'} style={{borderRadius: 10}}/>
				</div>

				<div style={{position: 'relative', flex: 1, height: '100%'}}>
					<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
						<label style={{fontSize:20,  color: Colors.primaryTextColor}}>{vehicle.make}</label>

						{
							!isForm &&
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
								{
									vehicle.previous && <label style={{fontSize:18, marginRight: 20,  color: Colors.primaryTextColor}}>Currently Selected</label>
								}
								{getAvailableLabel(vehicle)}
							</div>

						}
					</div>


					<div style={{marginTop: '2%'}}>
						<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
							<label style={{fontSize: Constants.entryTextSize, color: Colors.primaryTextColor, width: '23%'}}>Mileage Allowance :</label>
							<label style={{fontSize: Constants.entryTextSize, color: Colors.secondaryTextColor}}>{`${pricing?.mileage_allowance || vehicle.mileage_allowance} Miles / Day`}</label>
						</div>
						<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
							<label style={{fontSize: Constants.entryTextSize, color: Colors.primaryTextColor, width: '23%'}}>Mileage Overage :</label>
							<label style={{fontSize: Constants.entryTextSize, color: Colors.secondaryTextColor}}>{`$${pricing?.mileage_overage_price || vehicle.mileage_overage_price} / Mile`}</label>
						</div>
						<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
							<label style={{fontSize: Constants.entryTextSize, color: Colors.primaryTextColor, width: '23%'}}>Security Deposit :</label>
							<label style={{fontSize: Constants.entryTextSize, color: Colors.secondaryTextColor}}>{`$${pricing?.deposit || vehicle.deposit || ' '}`}</label>
						</div>
					</div>

					<div style={{display: 'flex', flexDirection:'row', position: 'absolute', bottom: 0, width: '100%', justifyContent: 'space-between'}}>
						<div style={{display: 'flex', flexDirection:'row', fontSize: 20}}>
							<label style={{color: Colors.primaryTextColor, marginRight: 10, alignSelf: 'flex-end', fontSize: Constants.headerSize}}>Hourly Price: </label>
							<label style={{alignSelf: 'flex-end', fontSize: Constants.headerSize, color: Colors.secondaryTextColor}}>{selectedVehicle ? `$${hourlyPrice}` : `$${vehicle.hourlyPrice}`}</label>
						</div>

						<div style={{display: 'flex', flexDirection: 'row'}}>
							{
								(vehicle.available || reservationState.reservation_to_update) &&
								<div style={{marginRight: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
									<div style={{flexDirection: 'row', display: 'flex', fontSize: Constants.entryTextSize}}>
										<label style={{color: Colors.secondaryTextColor, marginRight: 10}}>Total Price: </label>
										<label style={{color: Colors.secondaryTextColor}}>${Number(totalPriceToDisplay)?.toFixed(2)}</label>
									</div>
									<div style={{flexDirection: 'row', display: 'flex', fontSize: Constants.headerSize}}>
										<label style={{color: Colors.primaryTextColor, marginRight: 10}}>Inc. Taxes: </label>
										<label style={{color: Colors.primaryTextColor}}>${Number(priceWithTax)?.toFixed(2)}</label>
									</div>
								</div>
							}

							{
								isForm ?
									getChangeButton()
									:
									getSelectButton(vehicle)

							}
						</div>

					</div>

				</div>
			</div>
		)
	}

	const onSortOrderClicked = (sortOrderIndex) => {
		setSortOrder(SORT_ORDER_INDEXES[sortOrderIndex])
		let temp = [...availableVehicles]
		let temp2 = [...unavailableVehicles]

		switch (sortOrderIndex) {
			case 0:
				//price -inc
				temp.sort((a,b) => (a.hourlyPrice - b.hourlyPrice))
				temp2.sort((a,b) => (a.hourlyPrice - b.hourlyPrice))
				setAvailableVehicles(temp)
				setUnavailableVehicles(temp2)
				break;
			case 1:
				//price-dec
				temp.sort((a,b) => (b.hourlyPrice - a.hourlyPrice))
				temp2.sort((a,b) => (b.hourlyPrice - a.hourlyPrice))
				setAvailableVehicles(temp)
				setUnavailableVehicles(temp2)
				break;
			case 2:
				//name -inc
				temp.sort((a,b) => {
					if(a.make < b.make) return -1
					if(a.make > b.make) return 1
				})
				temp2.sort((a,b) => {
					if(a.make < b.make) return -1
					if(a.make > b.make) return 1
				})
				setAvailableVehicles(temp)
				setUnavailableVehicles(temp2)
				break;
			case 3:
				//name-dec
				temp.sort((a,b) => {
					if(a.make < b.make) return 1
					if(a.make > b.make) return -1
				})
				temp2.sort((a,b) => {
					if(a.make < b.make) return 1
					if(a.make > b.make) return -1
				})
				setAvailableVehicles(temp)
				setUnavailableVehicles(temp2)
				break;

			default:

		}
	}

	const onPrintClicked = () => {
		if(!validateData()) {
			return
		}

		// setShowLoading(true)
		setShowPdfModal(true)
	}

	const getSortButtons = () => {
		return (
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10}}>
				<div style={{cursor: 'pointer'}}>
					{
						sortOrder === SORT_ORDER_INDEXES[0] ?
							<p onClick={() => onSortOrderClicked(1)} style={{color: Colors.primaryTextColor, padding: '5px 0xp', fontWeight: 'bold'}}>price {`\u2191`}</p>
							:
							sortOrder === SORT_ORDER_INDEXES[1] ?
								<p onClick={() => onSortOrderClicked(0)} style={{color: Colors.primaryTextColor, padding: '5px 0xp', fontWeight: 'bold'}}>price {`\u2193`}</p>
								:
								<p onClick={() => onSortOrderClicked(1)} style={{color: Colors.primaryTextColor, padding: '5px 0xp'}}>price</p>
					}
				</div>
				<div style={{marginLeft: 20, cursor: 'pointer'}}>
					{
						sortOrder === SORT_ORDER_INDEXES[2] ?
							<p onClick={() => onSortOrderClicked(3)} style={{color: Colors.primaryTextColor, padding: '5px 0xp', fontWeight: 'bold'}}>name {`\u2191`}</p>
							:
							sortOrder === SORT_ORDER_INDEXES[3] ?
								<p onClick={() => onSortOrderClicked(2)} style={{color: Colors.primaryTextColor, padding: '5px 0xp', fontWeight: 'bold'}}>name {`\u2193`}</p>
								:
								<p onClick={() => onSortOrderClicked(3)} style={{color: Colors.primaryTextColor, padding: '5px 0xp'}}>name</p>
					}
				</div>
			</div>
		)
	}

	const renderVehicles = () => {
		if(fetchingBlockedVehicles) {
			return (
				<div style={{height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<CustomLoaderSpinner />
				</div>
			)
		}

		let avaVehicles = availableVehicles.map((vehicle,index) => renderVehicleRow(vehicle, index, false))
		let unAvaVehicles = unavailableVehicles.map((vehicle,index) => renderVehicleRow(vehicle, index, false))
		return (
			<div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20, height: '100%'}}>
				<div style={{position: 'relative', marginBottom: 0, width: '93%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<label
						className={GeneralStyles.darkText}
						style={{fontWeight: 'bold', width: 160, fontSize: 18}}>Availability - ({availableVehicles.length})</label>
					<label className={GeneralStyles.darkText} style={{marginLeft: 100, borderBottom: `1px solid ${Colors.theme}`, padding: '5px 0px'}}>Sort By: </label>
					{getSortButtons()}
					<div style={{position: 'absolute', right: 0}}>
						<VehicleTypeSelector selectedTab={selectedType} onTabChange={setSelectedType}/>
					</div>
				</div>
				{avaVehicles}
				{unAvaVehicles}
			</div>
		)
	}

	const renderChauffeurDetails = () => {
		return (
			<div style={{width: '100%', marginTop: 10}}>
				{
					locations?.map((location,index) => (
						<div key={index.toString()} style={{width: '100%', marginBottom: 10}}>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
								<div
									style={{marginTop: 4, width: index !== 0 && index !== 1 ? '95%' : '98%'}}>
									<CustomGooglePlacesNew
										label={index === 0 ? 'First Pick-Up Location' : index === 1 ? 'Drop-off Location' : `Additional Location ${index-1}`}
										placeholder={index === 0 ? 'First Pick-Up Location' : index === 1 ? 'Drop-off Location' : `Additional Location ${index-1}`}
										value={locations[index]}
										onChange={(value) => {
											let temp = [...locations]
											temp[index] = value
											setLocations(temp)
										}}
									/>
								</div>

								{
									index !== 0 && index !== 1 &&
									<DeleteIcon
										onClick={() => {
											let temp = locations.filter((item,i) => i !== index)
											setLocations(temp)
										}}
										size={40}
										style={{color: Colors.theme, marginLeft: 20, cursor: 'pointer'}}/>
								}
							</div>
						</div>
					))
				}

				<CustomButtonContained
					style={{marginTop: 2}}
					text={'Add Additional Location'}
					onClick={() => {
						let temp = [...locations]
						temp.push(null)
						setLocations(temp)
					}}
				/>

			</div>
		)
	}

	const renderSalesInfo = () => {
		if(!salesUsers)
			return

		let options = salesUsers.map(user =>  {
			return {label: user.name, value: user._id}
		})
		options.unshift({label: 'None', value: null})

		return (
			<div style={{width: '100%'}}>
				<label style={{width: '100%', fontSize: Constants.entryTextSize, color: Colors.primaryTextColor}}>Sales Person</label>
				<div
					className={GeneralStyles.boxShadow}
					style={{width: '100%', height: 40, borderRadius: 10, marginTop: 4}}>
				<CustomSelect
						placeholder={'select'}
						borderColor={'transparent'}
						value={salesPerson}
						options={options}
						onChange={setSalesPerson}
					/>

				</div>
			</div>
		)
	}

	const renderClientInfo = () => {
		let options = clientsState.clients.map(client => {return {label: `${client.fName} ${client.lName}`, value: client._id}})
		options.unshift({label: 'New Client', value: 'none'})

		return (
			<div style={{width: '100%'}}>
				<p style={{fontWeight: 'bold', width: '100%', fontSize: 18, color: Colors.primaryTextColor}}>Client Information</p>
				<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
					<div style={{width: '60%'}}>

						<div className={GeneralStyles.boxShadow}
						     style={{width: '99%', borderRadius: 10, marginBottom: 20}}>
						<CustomSelect
								placeholder={'select'}
								borderColor={'transparent'}
								value={selectedClient}
								options={options}
								onChange={setSelectedClient}
							/>
						</div>

					<label
						className={GeneralStyles.darkText}
						style={{fontSize: Constants.labelSize}}>
						Full Name:
					</label>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginBottom: 20, marginTop: 5}}>
						<div style={{width: '47%'}}>
							<CustomTextFieldNew
								width={'100%'}
								label={'First name'}
								placeholder={'First name'}
								value={customerInfo.fName}
									onChange={(text) => {
										if(selectedClient && selectedClient.value !== 'none')
											setClientInfoUpdated(true)

										setCustomerInfo(Object.assign({}, customerInfo, {fName: text}))
									}}
							/>
							</div>
							<div style={{width: '47%', marginLeft: '4%'}}>
								<CustomTextFieldNew
									width={'100%'}
									label={'Last name'}
									placeholder={'Last name'}
									value={customerInfo.lName}
									onChange={(text) => {
										if(selectedClient && selectedClient.value !== 'none')
											setClientInfoUpdated(true)

										setCustomerInfo(Object.assign({}, customerInfo, {lName: text}))
									}}
								/>
							</div>
						</div>

						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 20, marginBottom: 20}}>
							<div style={{width: '47%'}}>
								<label
									className={GeneralStyles.darkText}
									style={{fontSize: Constants.labelSize}}>
									Email:
								</label>
								<CustomTextFieldNew
									marginTop={5}
									width={'100%'}
									label={'Email'}
									placeholder={'Email'}
									value={customerInfo.email}
									onChange={(text) => {
										if(selectedClient && selectedClient.value !== 'none')
											setClientInfoUpdated(true)

										setCustomerInfo(Object.assign({}, customerInfo, {email: text}))
									}}
								/>
							</div>
							<div style={{width: '47%', marginLeft: '4%'}}>
								<label
									className={GeneralStyles.darkText}
									style={{fontSize: Constants.labelSize}}>
									Phone:
								</label>
								<CustomTextFieldNew
									marginTop={5}
									width={'100%'}
									label={'Phone Number'}
									placeholder={'Phone #'}
									value={customerInfo.phone}
									onChange={handlePhoneChange}/>
							</div>
						</div>


						<div style={{width: '98%', marginBottom: 20}}>
							<label
								className={GeneralStyles.darkText}
								style={{fontSize: Constants.labelSize}}>
								Address:
							</label>
							<div style={{marginTop: 5}}>
								<CustomGooglePlacesNew
									label={'Address'}
									placeholder={'search customer address'}
									borderColor={'transparent'}
									value={customerInfo.address}
									onChange={(value) => {
										if(selectedClient && selectedClient.value !== 'none')
											setClientInfoUpdated(true)

										setCustomerInfo(Object.assign({}, customerInfo, {address: value}))
									}}
								/>
							</div>
						</div>

						{/*license info*/}
						<label
							className={GeneralStyles.darkText}
							style={{fontSize: Constants.labelSize}}>
							License Info:
						</label>
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 5}}>
							<div style={{width: '47%'}}>
								<CustomTextFieldNew
									width={'100%'}
									label={'License #'}
									placeholder={'License #'}
									value={licenseInfo?.number}
									onChange={(text) => {
										if(selectedClient && selectedClient.value !== 'none')
											setClientInfoUpdated(true)

										setLicenseInfo(Object.assign({}, licenseInfo, {number: text}))
									}}
								/>
							</div>
							<div style={{width: '47%', marginLeft: '4%'}}>
								<CustomTextFieldNew
									width={'100%'}
									label={'State'}
									placeholder={'State'}
									value={licenseInfo?.state}
									onChange={(text) => {
										if(selectedClient && selectedClient.value !== 'none')
											setClientInfoUpdated(true)

										setLicenseInfo(Object.assign({}, licenseInfo, {state: text}))
									}}
								/>
							</div>
						</div>

						<div style={{width: '100%', marginTop: 10, marginBottom: 20}}>
							<div
								onClick={() => setShowLicenseDateSelector(true)}
								className={GeneralStyles.boxShadow}
								style={{width: '46%', padding: '4px 10px', borderRadius: 10, marginTop: 5, display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
								<label className={GeneralStyles.darkText} style={{fontSize: 14, fontWeight: 'bold'}}>
									Expiry Date
								</label>
								<label className={GeneralStyles.darkText}>{moment(licenseInfo?.expiry_date).format('MM/DD/YYYY')}</label>
							</div>

						</div>

						{/*insurance info*/}
						<label
							className={GeneralStyles.darkText}
							style={{fontSize: Constants.labelSize}}>
							Insurance Info:
						</label>
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 5}}>
							<div style={{width: '47%'}}>
								<CustomTextFieldNew
									width={'100%'}
									label={'Policy Provider'}
									placeholder={'Policy Provider'}
									value={insuranceInfo?.provider}
									onChange={(text) => {
										if(selectedClient && selectedClient.value !== 'none')
											setClientInfoUpdated(true)

										setInsuranceInfo(Object.assign({}, insuranceInfo, {provider: text}))
									}}
								/>
							</div>
							<div style={{width: '47%', marginLeft: '4%'}}>
								<CustomTextFieldNew
									width={'100%'}
									label={'Policy #'}
									placeholder={'Policy #'}
									value={insuranceInfo?.policy_no}
									onChange={(text) => {
										if(selectedClient && selectedClient.value !== 'none')
											setClientInfoUpdated(true)

										setInsuranceInfo(Object.assign({}, insuranceInfo, {policy_no: text}))
									}}
								/>
							</div>
						</div>

					</div>

				</div>
			</div>
		)
	}

	const renderAddOns = (addOns) => {
		return (
			<div style={{width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: 2}}>
				<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor, paddingLeft: 8}}>ADD-ONS: </label>
				<div style={{width: '40%', display: 'flex', flexDirection: 'column'}}>
					{Object.keys(addOns).map(field => {
						switch(field) {
							case 'fuel' : return <label style={{textAlign: 'left', color: Colors.primaryTextColor, fontSize: Constants.entryTextSize}}>{`Add ${addOns[field].value}% Fuel Charge`}</label>
							case 'mileage' : return <label style={{textAlign: 'left', color: Colors.primaryTextColor, fontSize: Constants.entryTextSize}}>{`Add ${addOns[field].value} miles`}</label>
							default:
						}
					})}
				</div>
				<div style={{width: '20%', display: 'flex', flexDirection: 'column'}}>
					{Object.values(addOns).map(value => {
						return(
							<label style={{textAlign: 'right', color: Colors.primaryTextColor, fontSize: Constants.entryTextSize}}>{`$${Number(value?.price)?.toFixed(2)}`}</label>
						)
					})}
				</div>
			</div>
		)
	}

	const getDiscountedAmount = (subTotal) => {
		if(pricing?.discount?.label) {
			if(pricing.discount.label.toLowerCase() === 'custom amount')
				return pricing.discount.value
			else
				return (pricing.discount.value * subTotal)/100
		} else {
			return 0
		}
	}

	const renderPricingInfo = () => {
		let reservationToUpdate = reservationState.reservation_to_update
		let addOns = {}
		if(reservationToUpdate && reservationToUpdate.check_in && reservationToUpdate.check_out) {
			//if te check out info has been added, calculate the add ons for mileage and fuel
			// let startFuel = reservationToUpdate.check_in.fuel
			// let endFuel = reservationToUpdate.check_out.fuel
			// if(endFuel < startFuel)
			// 	addOns['fuel'] = {
			// 		value: startFuel - endFuel,
			// 		price: Math.round((startFuel - endFuel)/10 * pricing?.fuel_charge)
			// 	}

			let startMileage = reservationToUpdate.check_in.mileage
			let endMileage = reservationToUpdate.check_out.mileage
			let totalDays = Math.ceil(getTotalTime()/24)
			let mileageAllowance = reservationToUpdate.check_in.mileage_allowance * totalDays
			if((endMileage - startMileage) > mileageAllowance) {
				addOns['mileage'] = {
					value: endMileage - startMileage - mileageAllowance,
					price: Number((endMileage - startMileage - mileageAllowance) * pricing?.mileage_overage_price)
				}
			}
		}

		let tempTotalPrice = totalPrice
		if(Object.keys(addOns).length > 0)
			Object.values(addOns).forEach(value => {
				tempTotalPrice += Number(value.price)
			})

		let discount = Number(getDiscountedAmount(tempTotalPrice))
		let subTotal = tempTotalPrice - discount
		let serviceFee = Number((pricing?.service_tax*subTotal)/100)
		let total = subTotal + serviceFee

		return (
			<div >
				<label style={{width: '100%', fontSize: Constants.entryTextSize}}>Pricing Info</label>
				<div
					className={GeneralStyles.boxShadow}
					style={{maxWidth: '100%', padding: '20px 10px', borderRadius: 10, marginTop: 5}}>
					<div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor}}>RENTAL RATE: </label>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor}}>{`${getTotalTime()}x Hours ($${hourlyPrice})`}</label>
						<label style={{fontSize: Constants.entryTextSize, width: '20%', textAlign: 'right', color: Colors.primaryTextColor}}>{`$${Number(totalPrice)?.toFixed(2)}`}</label>
					</div>
					{renderAddOns(addOns)}

					<div style={{paddingBottom: 6, width: '100%', display: 'flex', flexDirection: 'row'}}>
						<div style={{display: 'flex', flexDirection: 'row', width: '40%', cursor: 'pointer', marginLeft: 6}}>
							{
								pricing?.discount?.label ?
									<label
										onClick={() => {
											let temp = JSON.parse(JSON.stringify(pricing))
											temp['discount'] = {}
											setPricing(temp)
										}}
										style={{fontSize: Constants.entryTextSize, textAlign: 'left', color: Colors.primaryTextColor, cursor: 'pointer'}}>-</label>
									:
									<label
										onClick={() => setShowDiscountModal(true)}
										style={{fontSize: Constants.entryTextSize, textAlign: 'left', color: Colors.primaryTextColor, cursor: 'pointer'}}>+</label>

							}
							<label style={{fontSize: Constants.entryTextSize, textAlign: 'left', color: Colors.primaryTextColor, marginLeft: 4}}>DISCOUNTS: </label>
						</div>

						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor}}>{pricing?.discount?.label}</label>
						<label style={{fontSize: Constants.entryTextSize, width: '20%', textAlign: 'right', color: Colors.primaryTextColor}}>{pricing?.discount?.label ? `$${Number(discount)?.toFixed(2)}` : ''}</label>
					</div>


					<div style={{width: '100%', display: 'flex', flexDirection: 'row', borderTop: `1px solid ${Colors.tableLineColor}`}}>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: colors.primaryTextColor}}>SUB-TOTAL: </label>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor}}></label>
						<label style={{fontSize: Constants.entryTextSize, width: '20%', textAlign: 'right', color: Colors.primaryTextColor}}>{`$${Number(subTotal)?.toFixed(2)}`}</label>
					</div>

					<div style={{paddingBottom: 6, width: '100%', display: 'flex', flexDirection: 'row', borderBottom: `1px solid ${Colors.tableLineColor}`}}>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor, paddingLeft: 8}}>SERVICE FEE: </label>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor}}>{`${pricing?.service_tax}%`}</label>
						<label style={{fontSize: Constants.entryTextSize, width: '20%', textAlign: 'right', color: Colors.primaryTextColor}}>{`$${Number(serviceFee)?.toFixed(2)}`}</label>
					</div>

					<div style={{width: '100%', display: 'flex', flexDirection: 'row', marginTop: 6}}>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', fontWeight: 'bold', color: Colors.primaryTextColor}}>TOTAL PRICE: </label>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor}}></label>
						<label style={{fontSize: Constants.entryTextSize, width: '20%', textAlign: 'right', fontWeight: 'bold', color: Colors.primaryTextColor}}>{`$${Number(total)?.toFixed(2)}`}</label>
					</div>

					<div style={{paddingBottom: 2, width: '100%', display: 'flex', flexDirection: 'row'}}>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor, paddingLeft: 8}}>SECURITY DEPOSIT: </label>
						<label style={{fontSize: Constants.entryTextSize, width: '40%', textAlign: 'left', color: Colors.primaryTextColor}}>Refundable AMount</label>
						<label style={{fontSize: Constants.entryTextSize, width: '20%', textAlign: 'right', color: Colors.primaryTextColor}}>{`$${Number(pricing?.deposit)?.toFixed(2)}`}</label>
					</div>
				</div>
			</div>

		)
	}


	const renderResInfo = () => {
		return (
			<div style={{width: '100%'}}>
				<p style={{fontWeight: 'bold', width: '100%', fontSize: 18}}>Reservation Information</p>

				<div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
					<div style={{width: '50%'}}>
						{renderSalesInfo()}
						{renderChauffeurDetails()}
					</div>

					<div style={{width: '50%', marginLeft: 20}}>
						{renderPricingInfo()}
					</div>
				</div>
			</div>
		)
	}

	const renderPickDropInfo = (isPickUp) => {
		let reservation = reservationState.reservation_to_update
		if(!reservation)
			return null
		let checkObject = isPickUp ? reservation.check_in : reservation.check_out
		if(!checkObject)
			checkObject = {}

		return (
				<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>

					<div style={{width: '50%'}}>
						<p style={{fontWeight: 'bold', width: '100%', fontSize: 18, color: Colors.primaryTextColor}}>{`${isPickUp ? 'Pick-Up' : 'Drop-Off'} Information`}</p>
						<div style={{display: 'flex', flexDirection: 'column', marginBottom: 20}}>
							<CustomTextFieldNew
								disabled={true}
								label={`${isPickUp ? 'Starting': 'Ending'} Mileage`}
								value={`${checkObject.mileage || '-'} miles`}
							/>
						</div>

						<div style={{display: 'flex', flexDirection: 'column', marginBottom: 20}}>
							<CustomTextFieldNew
								disabled={true}
								label={'Daily Mileage Allowance'}
								value={`${checkObject.mileage_allowance || '-'} miles / DAY`}
							/>
						</div>

						<div style={{display: 'flex', flexDirection: 'column', marginBottom: 20}}>
							<label
								className={GeneralStyles.darkText}
								style={{fontSize: Constants.labelSize}}>
								{`${isPickUp ? 'Starting': 'Ending'} Fuel`}
							</label>
							<div style={{marginTop: 40, width: '100%'}}>
								<CustomSlider
									max={100}
									min={0}
									value={checkObject.fuel}
									setValue={() => null}
								/>
							</div>
						</div>


					</div>

					<div style={{width: '50%', marginLeft: 40}}>
						<p
							className={GeneralStyles.darkText}
							style={{fontSize: Constants.labelSize}}>
							{`${isPickUp ? 'Starting': 'Ending'} Images`}
						</p>
						{
							checkObject.images?.length > 0 ?
								<div style={{marginTop: 10}}>
									{renderImagesCarousel(checkObject.images)}
								</div>
								:
								<p style={{marginTop: 40, widht: '100%', textAlign: 'center'}}>No pictures to display</p>
						}
					</div>

				</div>
		)
	}

	const renderImagesCarousel = (images) => {
		return (
			<Carousel
				indicatorIconButtonProps={{style: {color: 'gray'}}}
				activeIndicatorIconButtonProps={{style: {backgroundColor: Colors.theme, color: Colors.theme}}}
			>
				{images.map((image,index) => {
					return (
						<img alt="not fount" width={'100%'} height={260} src={image} style={{borderRadius: 10}}/>
					)
				})}
			</Carousel>
		)
	}

	const renderDocInfo = () => {
		return (
			<div style={{width: '100%'}}>
				<p style={{fontWeight: 'bold', width: '100%', fontSize: 18, color: Colors.primaryTextColor}}>Chauffeur Agreement</p>
				{renderContractBox()}
			</div>
		)
	}

	const renderTabContent = () => {
		switch(tab) {
			case 1:
				return renderClientInfo()
			case 2:
				return renderResInfo()
			case 3:
				return renderDocInfo()
			case 4:
				return renderPickDropInfo(true)
			case 5:
				return renderPickDropInfo(false)
			default:
		}
	}

	const renderEntryForm = () => {
		let reservation = reservationState.reservation_to_update || {}
		return (
			<div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20, height: '100%'}}>
				{renderVehicleRow(selectedVehicle, 1, true)}

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<Tab tab={tab} onChange={setTab} />
					{
						(reservation.rental_status === RENTAL_STATUS.RENTING || reservation.rental_status === RENTAL_STATUS.RETURNED) &&
						<CheckTab tab={tab} onChange={setTab} />
					}
				</div>


				{renderTabContent()}

				{/*<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>*/}

				{/*	<div style={{width: '25%', marginLeft: 30, display: 'flex', flexDirection: 'column'}}>*/}
				{/*		<div style={{marginBottom: 30, height: '50%', display: reservationState.reservation_to_update ? 'block' : 'none'}}>*/}
				{/*			<label style={{fontSize: 18}}>Rental Agreement</label>*/}
				{/*			<div style={{height: '100%', backgroundColor: Colors.theme, borderRadius: 10, marginTop: 4}}>*/}
				{/*				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center'}}>*/}
				{/*					<img src={ScrollDocImage} height={18} width={18}/>*/}
				{/*					<p style={{fontWeight: 'bold', marginLeft: 5, fontSize: 14}}>Reservation Contract</p>*/}
				{/*				</div>*/}
				{/*				<div*/}
				{/*					style={{*/}
				{/*						display: 'flex',*/}
				{/*						flexDirection: 'row',*/}
				{/*						alignItems: 'center',*/}
				{/*						width: '100%',*/}
				{/*						justifyContent: 'space-evenly',*/}
				{/*						marginTop: 20,*/}
				{/*					}}>*/}
				{/*					<CustomButtonContained*/}
				{/*						text={'Print'}*/}
				{/*						onClick={onPrintClicked}*/}
				{/*						style={{height: 34}}*/}
				{/*						textPadding={'4px 10px'}*/}
				{/*					/>*/}
				{/*					<CustomButtonContained*/}
				{/*						color={"secondary"}*/}
				{/*						text={'Download'}*/}
				{/*						onClick={onPrintClicked}*/}
				{/*						style={{height: 34, border: '1px solid white'}}*/}
				{/*						textPadding={'4px 10px'}*/}
				{/*					/>*/}
				{/*				</div>*/}

				{/*			</div>*/}
				{/*		</div>*/}

				{/*		<div style={{height: '30%', marginTop: reservationState.reservation_to_update ? 0 : 18}}>*/}
				{/*			<div style={{height: '100%', backgroundColor: Colors.theme, borderRadius: 10, padding: '3%', marginTop: 4}}>*/}
				{/*				<textarea*/}
				{/*					placeholder={'reservation notes'}*/}
				{/*					style={{width: '100%', height: '100%', borderRadius: 10, backgroundColor: Colors.theme, border: '0px solid gray', color: Colors.primaryTextColor}}*/}
				{/*					name="notes"*/}
				{/*					value={notes}*/}
				{/*					onChange={(e) => setNotes(e.target.value)}*/}
				{/*				/>*/}
				{/*			</div>*/}
				{/*		</div>*/}

				{/*		{renderSalesInfo()}*/}
				{/*	</div>*/}


				{/*</div>*/}



			</div>

		)
	}

	if(showLoading) {
		return (
			<div className={GeneralStyles.container} style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}


	const renderBlockingReservationModal = () => {
		if(!blockingReservationToDisplay)
			return

		let clientsMap = clientsMapSelector(clientsState)
		let customer = clientsMap[blockingReservationToDisplay.client] || {}

		return (
			<CustomModal
				show={showBlockingReservationModal}
				handleClose={() => {
					setShowBlockingReservationModal(false)
					setBlockingReservationToDisplay(null)
				}}
				containerWidth={window.innerWidth/2.9}
				containerHeight={window.innerHeight/2.7}
				containerPadding={0}
			>
				{
					blockingReservationToDisplay.end_date ?
						<div style={{height: '100%',width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 20 }}>
							<label style={{width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: Constants.headerSize, color: Colors.primaryTextColor}}>Blocking Vehicle Maintenance</label>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
								<p className={GeneralStyles.darkText} style={{width: '30%'}}>Maintenance ID</p>
								<p>: {blockingReservationToDisplay?._id}</p>
							</div>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
								<p className={GeneralStyles.darkText} style={{width: '30%'}}>Start Time</p>
								<p>: {moment(blockingReservationToDisplay.start_date).format('MMM Do, YYYY hh:mm A')}</p>
							</div>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
								<p className={GeneralStyles.darkText} style={{width: '30%'}}>Drop-off Time</p>
								<p>: {moment(blockingReservationToDisplay.end_date).format('MMM Do, YYYY hh:mm A')}</p>
							</div>
						</div>
						:
						<div style={{height: '100%',width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column',padding: 20 }}>
							<label style={{width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: Constants.headerSize, color: Colors.primaryTextColor}}>Blocking Reservation</label>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
								<p className={GeneralStyles.darkText} style={{width: '30%'}}>Reservation_Id</p>
								<p>: {blockingReservationToDisplay?._id}</p>
							</div>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
								<p className={GeneralStyles.darkText} style={{width: '30%'}}>Pick-up Time</p>
								<p>: {moment(blockingReservationToDisplay.pickup_datetime).format('MMM Do, YYYY hh:mm A')}</p>
							</div>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
								<p className={GeneralStyles.darkText} style={{width: '30%'}}>Drop-off Time</p>
								<p>: {moment(blockingReservationToDisplay.dropoff_datetime).format('MMM Do, YYYY hh:mm A')}</p>
							</div>
							<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
								<p className={GeneralStyles.darkText} style={{width: '30%'}}>Customer</p>
								<p>: {`${customer.fName} ${customer.lName}`}</p>
							</div>
						</div>
				}
			</CustomModal>
		)

	}

	const renderDiscountModal = () => {
		let hideCustomDiscount = false
		let rolesMap = Helpers.getRolesMap(settingsState)
		if(!mongoUser || Object.keys(rolesMap).length === 0)
			return

		if(!Helpers.checkAccess(mongoUser, rolesMap, 'custom_discounts')) {
			hideCustomDiscount = true
		}


		let availableDiscounts = []
		let array = priceSettings?.discounts || []
		array.forEach(entry => {
			let temp = `${entry.name} ${entry.discount}% OFF`
			if(entry.status)
				availableDiscounts.push({label: temp, value: entry.discount})
		})

		return (
			<CustomModal
				show={showDiscountModal}
				handleClose={() => {
					setShowDiscountModal(false)
					setCustomDiscount('')
					setDiscountCode(null)
				}}
				containerWidth={window.innerWidth/3}
				containerHeight={400}
			>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<p style={{textAlign: 'center', width: '100%', fontWeight: 'bold', marginBottom: 20, color: Colors.primaryTextColor}}>Add Discount </p>
					{
						hideCustomDiscount ?
							<label style={{textAlign: 'center', width: '100%', marginBottom: 20, color: Colors.primaryTextColor}}>Select a code from dropdown</label>
							:
							<label style={{textAlign: 'center', width: '100%', marginBottom: 20, color: Colors.primaryTextColor}}>Select a code from dropdown or add a custom amount</label>
					}
					<div
						className={GeneralStyles.boxShadow}
						style={{width: '82.5%', borderRadius: 10, position: 'relative', left: 6}}>
						<CustomSelect
							placeholder={'select'}
							borderColor={'transparent'}
							value={discountCode}
							options={availableDiscounts}
							onChange={setDiscountCode}
						/>
					</div>
					{
						!hideCustomDiscount &&
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<label style={{margin: 14, color: Colors.primaryTextColor}}>OR</label>
							<div style={{width: '80%'}}>
								<CustomTextFieldNew
									label={'Custom Discount'}
									placeholder={'Custom Discount Amount'}
									value={customDiscount}
									onChange={(text) => {
										if(isNaN(text))
											return
										setCustomDiscount(text)
									}}/>
							</div>
						</div>
					}

					<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom : 20}}>
						<CustomButtonContained
							style={{}}
							text={`\u2713 Add Discount Code`}
							onClick={onAddDiscountClicked}
						/>
					</div>
				</div>
			</CustomModal>

		)
	}

	const renderPdfModal = () => {
		if(!reservationState || !reservationState.reservation_to_update)
			return null
		let finalPickupDatetime = moment(startDate).hours(pickupTime.getHours()).minutes(pickupTime.getMinutes())
		let finalReturnDatetime = moment(endDate).hours(returnTime.getHours()).minutes(returnTime.getMinutes())
		let url = `${Config.CONTRACT_URL}contract/chauffeur?_id=${reservationState.reservation_to_update?._id}&token=${token}&bus=${Config.BUSINESS}`


		return (
			<CustomModal
				show={showPdfModal}
				handleClose={() => setShowPdfModal(false)}
				containerWidth={window.innerWidth-200}
				containerHeight={window.innerHeight-20}
			>
				<iframe src={url} height={'100%'} width={'100%'}/>

				{/*<CustomPDFChauffeur*/}
				{/*	vehicle={selectedVehicle}*/}
				{/*	customer={customerInfo}*/}
				{/*	pickUpDateTime={finalPickupDatetime}*/}
				{/*	returnDateTime={finalReturnDatetime}*/}
				{/*	hourlyPrice={hourlyPrice || selectedVehicle?.hourlyPrice}*/}
				{/*	totalPrice={totalPrice}*/}
				{/*	locations={locations}*/}
				{/*/>*/}

			</CustomModal>
		)
	}

	const getCheckInCheckOutButton = () => {
		let reservation = reservationState.reservation_to_update
		if(reservation.rental_status === 1) {
			//waiting pickup so start reservation
			return (
				<CustomButtonContained
					color={'tertiary'}
					style={{marginRight: 2}}
					text={'Start Reservation'}
					onClick={() => navigate('/home/reservation/check-in')}
				/>
			)
		} else if(reservation.rental_status === 2) {
			return (
				<CustomButtonContained
					color={'tertiary'}
					style={{marginRight: 2}}
					text={'End Reservation'}
					onClick={() => navigate('/home/reservation/check-out')}
				/>
			)
		}
		else  {
			return null
		}
	}

	// if(showPdfModal) {
	// 	return (
	// 		<div style={{height: '100vh', width: '100%'}}>
	// 			<ChauffeurPdfViewerComponent
	// 				document={"pdfDemo.pdf"}
	// 			/>
	// 		</div>
	// 	)
	// }

	return (
		<div className={GeneralStyles.container} style={{overflowY: 'scroll',height: '98vh'}}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader
					header={reservationState.reservation_to_update ? `Update Chauffeur Reservation - #${reservationState.reservation_to_update._id}` : 'New Chauffeur'}
					subHeader={selectedVehicle ? 'Step 2 - Enter Information' : 'Step 1 - Availability'}
					reservation={reservationState.reservation_to_update || null}
				/>
				<div style={{position: 'absolute', right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					{
						reservationState.reservation_to_update &&
						getCheckInCheckOutButton()
					}
					{
						selectedVehicle &&
						<CustomButtonContained
							style={{marginRight: 2}}
							text={reservationState.reservation_to_update ? `\u2713 Save Changes` : `\u2713 Confirm New Booking`}
							onClick={onSubmitClicked}
						/>
					}
					<img
						onClick={onBackClicked}
						src={BackArrow} style={{width: 40, height: 40, cursor: 'pointer'}}/>
				</div>
			</div>
			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setErrorMessage('')
				}}
				severity={'error'}
				openFlag={showError}
				message={errorMessage}
			/>
			{renderDateSelectors()}
			{selectedVehicle ?
				renderEntryForm()
				:
				renderVehicles()
			}
			{renderDateSelectorModal()}

			{renderPdfModal()}
			{renderBlockingReservationModal()}
			{renderLicenseDateModal()}
			{renderDiscountModal()}
		</div>
	)
}


const VehicleTypeSelector = ({selectedTab, onTabChange}) => {

	return (
		<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
			<label
				onClick={() => onTabChange('all')}
				style={{
					fontWeight: selectedTab === 'all' ? 600 : 'normal',
					color: Colors.primaryTextColor,
					marginRight: 25,
					cursor: 'pointer',
					borderBottom: selectedTab === 'all' ? `1px solid ${Colors.theme}` : 0,
					padding: '5px 0px',
				}}
			>Show All</label>
			{VEHICLE_TYPES.map((type,index) => (
				<label
					key={index.toString()}
					onClick={() => onTabChange(type)}
					style={{
						fontWeight: selectedTab === type ? 600 : 'normal',
						padding: '5px 0px',
						borderBottom: selectedTab === type ? `1px solid ${Colors.theme}` : 0,
						color: Colors.primaryTextColor,
						marginRight: 25,
						cursor: 'pointer'
					}}
				>{type}</label>
			))}
		</div>
	)
}

const Tab = ({tab, onChange}) => {
	return (
		<div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme, borderColor: Colors.theme}}>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Client Info</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 2 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(2)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Res. Info</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 3 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(3)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Documents</p>
			</div>
		</div>
	)
}

const CheckTab = ({tab, onChange}) => {
	return (
		<div style={{width: '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme, borderColor: Colors.theme, marginLeft: 40}}>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 4 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(4)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Pick-Up</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 5 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(5)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Drop-Off</p>
			</div>

		</div>
	)
}

export default NewChauffeur
