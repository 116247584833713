import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {API_CALL_STATUS} from '../../metadata/enums'
import {GET_ALL_CLIENTS, UPDATE_CLIENT, ADD_CLIENT, GET_CLIENT_DETAILS} from '../actionTypes'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";

const initialState = {
	clients: [],
	client_to_update: null,
	status: API_CALL_STATUS.IDLE,
	error: null,
	client_added: false,
	client_updated: null,
}

export const getAllClients = createAsyncThunk(GET_ALL_CLIENTS, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}clients`,
		headers: {Authorization: payload.token},
	}

	try {
		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting all clients', err)
		return []
	}

})

export const getClientDetails = createAsyncThunk(GET_CLIENT_DETAILS, async (payload) => {
	try {
		const config = {
			method: 'get',
			url: `${Config.BACKEND_URL}clients/${payload._id}`,
			headers: { Authorization: payload.token, contentType: "application/json",},
		}

		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.log('error when adding adding client', err)
	}

	// return order
})

export const addClientAndConfirm = createAsyncThunk(ADD_CLIENT, async (payload) => {
	try {
		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}clients`,
			headers: { Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		let res = await axios(config)
		let data = Object.assign({}, payload.data)
		data._id = res.data?._id
		return data
	}catch(err) {
		LOGGER.log('error when adding adding client', err)
	}

	// return order
})

export const updateClientAndConfirm = createAsyncThunk(UPDATE_CLIENT, async (payload) => {
	try {
		let id = payload.data._id
		const config = {
			method: 'put',
			url: `${Config.BACKEND_URL}clients/${id}`,
			headers: { Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		await axios(config)
		return payload.data
	}catch(err) {
		LOGGER.log('error when adding updating client', err)
	}

	// return order
})

const clientsSlice = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		addReservationForClient(state, action) {
			let id = action.payload
			let temp = []
			state.clients.forEach(client => {
				if(client._id === id) {
					let tempClient = Object.assign({}, client, {total_reservations: client.total_reservations + 1})
					temp.push(tempClient)
				} else {
					temp.push(client)
				}
			})

			return Object.assign({}, state, {clients: temp})
		},
		updateUpdatedClientStatus(state, action) {
			return Object.assign({}, state, {client_updated: action.payload})
		},
		updateAddedClientStatus(state, action) {
			return Object.assign({}, state, {client_added: action.payload})
		},
		updateClientToUpdate(state, action) {
			return Object.assign({}, state, {client_to_update: action.payload})
		},
		addClient(state, action) {
			let newArray = [...state.clients]
			let newClient = action.payload
			let temp = state.clients.filter(client => client._id === newClient._id)
			if(temp.length === 0) {
				newArray.unshift(newClient)
				return Object.assign({}, state, {clients: newArray})
			}

			return Object.assign({}, state)
		},
		updateClient(state,action) {
			let updatedClient = action.payload
			let newArray = []
			state.clients.forEach(client => {
				if(client._id === updatedClient._id)
					newArray.push(updatedClient)
				else
					newArray.push(client)
			})
			return Object.assign({}, state, {clients: newArray})
		}
	},
	extraReducers(builder) {
		builder
			.addCase(getAllClients.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(getAllClients.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(getAllClients.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.clients = action.payload
			})
			.addCase(getClientDetails.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(getClientDetails.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(getClientDetails.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.client_to_update = action.payload
			})
			.addCase(addClientAndConfirm.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(addClientAndConfirm.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(addClientAndConfirm.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.clients.unshift(action.payload)
				state.client_added = true
			})
			.addCase(updateClientAndConfirm.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(updateClientAndConfirm.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(updateClientAndConfirm.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				let updatedClient = action.payload
				let temp = []

				state.clients.forEach(client => {
					if(client._id === updatedClient._id) {
						temp.push(updatedClient)
					}
					else
						temp.push(client)
				})
				state.clients = temp
				state.client_updated = true
			})
	}
})

export const clientsMapSelector = (clientsState) => {
	if(!clientsState || clientsState.status === API_CALL_STATUS.LOADING) {
		return {}
	} else {
		let map = {}
		clientsState.clients.forEach(client => map[client._id] = client)
		return map
	}
}


export const { addClient ,updateClient, updateClientToUpdate, updateAddedClientStatus, addReservationForClient, updateUpdatedClientStatus} = clientsSlice.actions

export default clientsSlice.reducer
