import React, {useState, useEffect, useRef} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS, PERMISSIONS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import CONSTANTS from '../../config/contants'
import Config from '../../config'
import Styles from './styles/Settings.module.scss'
import Helpers from '../../utils/helpers'
import DeleteIcon from "@mui/icons-material/Delete";
import {useNavigate} from 'react-router-dom'
import {
	getPriceSettings,
	getSalesSettings,
	updateSettingsData,
	updateUpdatedStatus,
	getPermissionSettings
} from '../../redux/slices/settingsSlice'
import CustomButtonContained from "../../components/general/CustomButtonContained";
import CustomModal from "../../components/general/CustomModal";
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import CustomTextFieldNew from '../../components/general/CustomTextFieldNew'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomSwitch from '../../components/general/CustomSwitch'
import {updateUserAndConfirm, updateUserUpdatedStatus, addUserAndConfirm, updateNewUserStatus} from '../../redux/slices/userSlice'
import use from "use";
import Constants from "../../config/contants";
import CustomSelect from "../../components/general/CustomSelect";
import Checkbox from '@mui/material/Checkbox';
import {changeUserAccess} from "../../redux/slices/authSlice";
import UploadIcon from "../../assets/logos/uploadIcon.svg";
import ReactS3Client from "../../services/S3Client";

const getEmptyUser = () => {
	return {
		name: '',
		email: '',
		phone: '',
		role: null,
		enabled: true
	}
}

const Settings = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()
	let fileRef = useRef(null)

	const token = useSelector(state => state.auth.token)
	const users = useSelector(state => state.user.users)
	const userState = useSelector(state => state.user)
	const mongoUser = useSelector(state => state.user.mongoUser)
	const settingsState = useSelector(state => state.settings)

	const [loading, setLoading] = useState(true)
	const [loading2, setLoading2] = useState(true)
	const [message, setMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [showUserModal, setShowUserModal] = useState(false)
	const [showDiscountModal, setShowDiscountModal] = useState(false)
	const [toAddPerson, setToAddPerson] = useState('')
	const [expanded, setExpanded] = useState(null)
	const [discountModalDetails, setDiscountModalDetails] = useState({name: '', discount: ''})
	const [salesSettings, setSalesSettings] = useState(null)
	const [roles, setRoles] = useState([])
	const [rolesMap, setRolesMap] = useState({})
	const [permChanged, setPermChanged] = useState(false)
	const [enableUserEdit, setEnableUserEdit] = useState(false)
	const [businessDetails, setBusinessDetails] = useState(null)
	const [selectedLogo, setSelectedLogo] = useState(null)

	const [modalUser, setModalUser] = useState(getEmptyUser())
	const [pwd, setPwd] = useState('')
	const [priceSettings, setPriceSettings] = useState({
		sales_tax: '',
		service_tax: {rentals: '', chauffeur: ''},
		enabled: true,
		location_fee: ''
	})
	const [rolesTab, setRolesTab] = useState('users')

	//this is to check
	useEffect(() => {
		settingsState.settings?.forEach(setting => {
			if(setting._id === 'businessDetails')
				setBusinessDetails(setting)
		})

		let rolesMap = Helpers.getRolesMap(settingsState)
		if(mongoUser && Object.keys(rolesMap).length > 0) {
			//if they dont have access, go back and show message
			if(!Helpers.checkAccess(mongoUser, rolesMap, 'view_analytics')) {
				dispatch(changeUserAccess(false))
				navigate(-1)
				return
			}

			let adminRole
			settingsState.settings.forEach(setting => {
				if(setting._id === 'permissions') {
					setting.roles.forEach(role => {
						if(role.name === 'Administrator')
							adminRole = role
					})
				}
			})

			if(Config.WHITELIST_UIDS[mongoUser.uid] || mongoUser.role === adminRole.id) {
				setEnableUserEdit(true)
			} else {
				setEnableUserEdit(false)
			}
		}
	},[mongoUser, settingsState])

	useEffect(() => {
		if(userState.status === API_CALL_STATUS.LOADING || userState.status === API_CALL_STATUS.IDLE) {
			setLoading(true)
		} else if(userState.status === API_CALL_STATUS.FAILED) {
			setLoading(false)
			setShowError(true)
			setMessage('something went wrong when updating user')
			LOGGER.error('error effect', userState.error)
		} else {
			setLoading(false)
			setShowUserModal(false)
			if(userState.updated_user) {
				setExpanded('perms')
				setShowSuccess(true)
				setMessage('Successfully updated user information')
				dispatch(updateUserUpdatedStatus(false))
			}

			if(userState.new_user) {
				setExpanded('perms')
				setShowUserModal(false)
				setShowSuccess(true)
				setMessage('Successfully added new user')
				dispatch(updateNewUserStatus(false))
			}
		}
	}, [userState])

	useEffect(() => {
		console.log('effect', settingsState)
		if(settingsState.status === API_CALL_STATUS.LOADING || settingsState.status === API_CALL_STATUS.IDLE) {
			setLoading2(true)
		} else if(settingsState.status === API_CALL_STATUS.FAILED) {
			setLoading2(false)
			setShowError(true)
			setMessage('something went wrong when getting the settings data')
			LOGGER.error('error effect', settingsState.error)
		} else {
			setLoading(false)
			setLoading2(false)
			if(settingsState.updated_settings) {
				setShowSuccess(true)
				setMessage('Successfully updated')
				setToAddPerson('')
				dispatch(updateUpdatedStatus(false))

			}
			setSalesSettings(getSalesSettings(settingsState))
			setPriceSettings(getPriceSettings(settingsState))
			let permissionSettings = getPermissionSettings(settingsState)
			setRoles(permissionSettings.roles)
			let map = {}
			permissionSettings.roles.forEach(role => map[role.id] = role)
			setRolesMap(map)
		}
	},[settingsState])

	const deleteUerClicked = (name) => {
		if(window.confirm(`Are you sure you want to remove ${name}`)) {
			let tempSettings = JSON.parse(JSON.stringify(salesSettings))
			let inActive = tempSettings['in_active']
			let active = tempSettings['active']
			inActive.push(name)
			for( let i = 0; i < active.length; i++){
				if ( active[i] === name) {
					active.splice(i, 1);
				}
			}

			dispatch(updateSettingsData({token, data: tempSettings}))
		}

	}

	const onAddDiscountClicked = () => {
		let {discount, name} = discountModalDetails
		if(!name || name.length === 0) {
			setShowError(true)
			setMessage('Please enter a valid name for the discount')
			return
		}

		if(!discount || discount.length === 0) {
			setShowError(true)
			setMessage('Please enter a valid discount percentage')
			return
		}

		setShowDiscountModal(false)
		setLoading(true)

		let currentDiscounts = priceSettings?.discounts || []
		let temp = [...currentDiscounts]
		temp.push({name: name, discount: Number(discount), status: true})

		let toSave = {
			_id: 'pricing',
			discounts: temp
		}

		dispatch(updateSettingsData({token, data: toSave}))
	}

	const onAddPersonConfirmed = () => {
		let flag = false
		let {email, name, role, phone} = modalUser
		if(!email || email.length === 0 || !Helpers.validateEmail(email)) {
			flag = true
			setMessage('Please enter a valid email')
		}

		if(!name || name.length === 0) {
			flag = true
			setMessage('Please enter first and last name')
		}

		if(!phone || phone.length !== 14) {
			flag = true
			setMessage('Please enter a valid phone number')
		}

		if(!pwd || pwd.length < 6) {
			flag = true
			setMessage('Password must be minimum 6 characters')
		}

		if(!role || typeof role !== 'number') {
			flag = true
			setMessage('Please select a user role from the dropdown')
		}

		if(flag) {
			setShowError(true)
		} else {
			setShowUserModal(false)
			setLoading(true)
			let temp = Object.assign({}, modalUser, {password: pwd})
			dispatch(addUserAndConfirm({token: token, data: temp}))
		}
	}

	const reAddUserClicked = (name) => {
		if(window.confirm(`Are you sure you want to re-add ${name}`)) {
			let tempSettings = JSON.parse(JSON.stringify(salesSettings))
			let inActive = tempSettings['in_active']
			let active = tempSettings['active']
			active.push(name)
			for( let i = 0; i < active.length; i++){
				if ( inActive[i] === name) {
					inActive.splice(i, 1);
				}
			}

			dispatch(updateSettingsData({token, data: tempSettings}))
		}

	}

	const onSaveCompanyClicked = () => {
		let flag = false
		Object.keys(businessDetails).forEach(field => {
			if(!businessDetails[field] || businessDetails[field].length === 0)
				flag = true
		})

		if(flag) {
			setShowError(true)
			setMessage('Missing some company details or incorrect data')
			return
		}

		if(!Helpers.validateEmail(businessDetails.email)) {
			setShowError(true)
			setMessage('Incorrect email format')
			return
		}

		if(businessDetails.phone?.length !== 14) {
			setShowError(true)
			setMessage('Incorrect phone number')
			return
		}
		setLoading(true)

		if(selectedLogo) {
			//need to upload logo
			handleUploadImage()
		} else {
			dispatch(updateSettingsData({token, data: businessDetails}))
		}
	}

	const handleUploadImage = () => {
		ReactS3Client
			.uploadFile(selectedLogo)
			.then(data => {
				LOGGER.log('success', data)
				let url = Config.AWS_CLOUDFRONT_URL + data.key
				onLogoUploaded(url)
			})
			.catch(err => LOGGER.error('error ', err.message))
	}

	const onLogoUploaded = (url) => {
		let temp = Object.assign({}, businessDetails, {logo_url: url})
		dispatch(updateSettingsData({token, data: temp}))
	}

	const onSavePermsClicked = () => {
		let toSave = {
			_id: 'permissions',
			roles: roles
		}

		dispatch(updateSettingsData({token, data: toSave}))
	}

	const onSavePriceClicked = () => {
		let salesTax = priceSettings['sales_tax']
		if(!salesTax || salesTax.length === 0) {
			setShowError(true)
			setMessage('Missing Sales Tax')
			return
		}

		let serviceTax = priceSettings['service_tax']
		if(!serviceTax.rentals || !serviceTax.chauffeur || serviceTax.rentals.length === 0 || serviceTax.chauffeur.length === 0 ) {
			setShowError(true)
			setMessage('Missing Service Tax')
			return
		}

		let locationFee = priceSettings['location_fee']
		if(!locationFee || locationFee.length === 0) {
			setShowError(true)
			setMessage('Missing Additional Location Fee')
			return
		}

		dispatch(updateSettingsData({token, data: {
				_id: 'pricing',
				sales_tax: Number(priceSettings.sales_tax),
				service_tax: {rentals: Number(priceSettings.service_tax?.rentals), chauffeur: Number(priceSettings.service_tax?.chauffeur)},
				enabled: priceSettings.enabled,
				location_fee: Number(priceSettings.location_fee),
				discounts: priceSettings.discounts
			}}))

	}

	const handleExpandChange = (id) => {
		if(id === expanded)
			setExpanded(null)
		else
			setExpanded(id)
	}


	const renderSalesPeople = () => {
		if(!salesSettings)
			return

		let activePeople = salesSettings.active.map(person => (
			<div className={Styles.salesRow}>
				<p style={{width: '80%'}}>{person}</p>
				<DeleteIcon
					onClick={() => deleteUerClicked(person)}
					size={40}
					style={{color: 'white', marginLeft: 20, cursor: 'pointer'}}/>
			</div>
		))

		let inActivePeople = salesSettings.in_active.map(person => (
			<div className={Styles.salesRow}>
				<p style={{width: '80%'}}>{person}</p>
				<p
					onClick={() => reAddUserClicked(person)}
					style={{textDecoration: 'underline', color: Colors.secondaryTextColor, marginLeft: 20, cursor: 'pointer'}}>Add</p>
			</div>
		))

		return (
			<div>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10}}>
					<p style={{fontWeight: 'bold', fontSize: CONSTANTS.headerSize}}>Salespeople</p>
					<CustomButtonContained
						text={'+ Add Salesperson'}
						onClick={() => {

						}}
						style={{height: 34, marginLeft: 5}}
						textPadding={'4px 10px'}
					/>
				</div>
				<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly'}}>
					<div className={Styles.salesPplContainer}>
						<p style={{width: '100%', textAlign: 'center', fontWeight: 'bold', color: 'green', borderBottom: '1px solid gray'}}>ACTIVE</p>
						{activePeople}
					</div>

					<div className={Styles.salesPplContainer}>
						<p style={{width: '100%', textAlign: 'center', fontWeight: 'bold', color: 'red',borderBottom: '1px solid gray'}}>INACTIVE</p>
						{inActivePeople}
					</div>
				</div>
			</div>
		)
	}

	const renderDiscounts = () => {
		let discounts = priceSettings?.discounts || []
		let elements = discounts.map((entry,index) => (
			<div
				key={index.toString()}
				style={{width: '100%', display: 'flex', flexDirection: 'row', height: 40, borderBottom: `1px solid ${Colors.tableLineColor}`, alignItems: 'center', marginTop: 4}}>
				<div style={{width: '40%',maxWidth: '40%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
					<label style={{fontSize: CONSTANTS.entryTextSize, width: '100%', textAlign: 'left', paddingLeft: 20, color: Colors.primaryTextColor}}>{entry.name}</label>
				</div>
				<div style={{width: '40%', marginLeft: 20, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
					<label style={{fontSize: CONSTANTS.entryTextSize, width: '100%', textAlign: 'left', paddingLeft: 20, color: Colors.primaryTextColor}}>{entry.discount}% OFF</label>
				</div>
				<div style={{width: '20%', marginLeft: 20, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
					<CustomSwitch
						onChange={(value) => {
							let temp = JSON.parse(JSON.stringify(priceSettings))
							temp['discounts'][index]['status'] = value
							setPriceSettings(temp)
						}}
						containerStyle={{}}
						checked={entry.status}/>
				</div>


			</div>
		))
		return (
			<div style={{width: '100%', height: '60%'}}>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Discounts</label>
					<label
						onClick={() => setShowDiscountModal(true)}
						style={{fontSize: CONSTANTS.headerSize, marginLeft: 40, cursor: 'pointer', color: Colors.primaryTextColor}}>+</label>
				</div>

				<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
					<div style={{width: '40%',maxWidth: '40%', backgroundColor: Colors.theme, height: 40, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
						<label style={{fontWeight: 'bold', fontSize: CONSTANTS.entryTextSize, paddingLeft: 20}}>Name</label>
					</div>
					<div style={{width: '40%', backgroundColor: Colors.theme, height: 40, marginLeft: 20, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
						<label style={{fontWeight: 'bold', fontSize: CONSTANTS.entryTextSize, paddingLeft: 20}}>Discount Percentage</label>
					</div>
					<div style={{width: '20%', backgroundColor: Colors.theme, height: 40, marginLeft: 20, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
						<label style={{fontWeight: 'bold', fontSize: CONSTANTS.entryTextSize, paddingLeft: 20}}>Status</label>
					</div>
				</div>

				<div style={{width: '100%', height: '100%', marginTop: 10}}>
					{elements}
				</div>
			</div>
		)
	}

	const renderUserRow = (user, index) => {
		let options = []
		let currentRole

		roles.forEach(role => {
			options.push({label: role.name, value: role.id})
			if(role.id === user.role)
				currentRole = role
		})

		let roleValue = null
		if(user.role)
			roleValue = {value: user.role, label: currentRole?.name}


		return (
			<div
				key={index.toString()}
				style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid ${Colors.tableLineColor}`, marginTop: 10, marginBottom: 10, padding: '4px 0px'}}>
				<div style={{display: 'flex',  width: '25%'}}>
					<label style={{color: Colors.primaryTextColor, fontSize: CONSTANTS.entryTextSize, marginLeft: 10}}>{user.name}</label>
				</div>

				<div style={{display: 'flex',  width: '22%', marginLeft: 20}}>
					<label style={{color: Colors.primaryTextColor, fontSize: CONSTANTS.entryTextSize, marginLeft: 10}}>{user.email}</label>
				</div>

				<div style={{display: 'flex',  width: '22%', marginLeft: 20}}>
					<label style={{color: Colors.primaryTextColor, fontSize: CONSTANTS.entryTextSize, marginLeft: 10}}>{user.phone}</label>
				</div>

				<div style={{display: 'flex',  width: '21%', marginLeft: 20}}>
					{
						enableUserEdit ?
							<div
								className={GeneralStyles.boxShadow}
								style={{width: '100%', height: 40, borderRadius: 10, marginTop: 4}}>
								<CustomSelect
									placeholder={'select'}
									borderColor={'transparent'}
									value={roleValue}
									options={options}
									onChange={(selected) => {
										let temp = JSON.parse(JSON.stringify(user))
										temp.role = selected.value
										dispatch(updateUserAndConfirm({token: token, data: temp}))
									}}
								/>

							</div>
							:
							<label style={{color: Colors.primaryTextColor, fontSize: CONSTANTS.entryTextSize, marginLeft: 10}}>{rolesMap[user.role]?.name}</label>
					}
				</div>

				<div style={{display: 'flex',  width: '10%', marginLeft: 20}}>
					<CustomSwitch
						onChange={(value) => {
							let temp = JSON.parse(JSON.stringify(user))
							temp.enabled = value
							dispatch(updateUserAndConfirm({token: token, data: temp}))
						}}
						containerStyle={{marginLeft: 30}}
						checked={user.enabled}/>
				</div>
			</div>
		)
	}

	const renderUsers = () => {
		let tempRole
		let userElements = users && users.map(renderUserRow)

		return (
			<div style={{width: '100%', marginTop: 20}}>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<div
						className={Styles.tableHeader}
						style={{width: '25%'}}
						>
						<label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.tableHeaderSize, marginLeft: 10}}>User Name</label>
					</div>

					<div
						className={Styles.tableHeader}
						style={{width: '22%'}}
					>
						<label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.tableHeaderSize, marginLeft: 10}}>Email Address</label>
					</div>

					<div
						className={Styles.tableHeader}
						style={{width: '22%'}}
					>
						<label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.tableHeaderSize, marginLeft: 10}}>Phone Number</label>
					</div>

					<div
						className={Styles.tableHeader}
						style={{width: '21%'}}
					>
						<label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.tableHeaderSize, marginLeft: 10}}>Role</label>
					</div>

					<div
						className={Styles.tableHeader}
						style={{width: '10%'}}
					>
						<label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.tableHeaderSize, marginLeft: 10}}>Status</label>
					</div>
				</div>

				{
					!users || users.length === 0 ?
						<div style={{width: '100%', marginTop: 40,}}>
							<p style={{color: Colors.primaryTextColor, fontSize: CONSTANTS.entryTextSize, textAlign: 'center', width: '100%'}}>No Users</p>
						</div>
						:
						<div style={{height: '100%', overflowY: 'scroll', paddingBottom: 150}}>
							{userElements}
						</div>
				}
			</div>
		)
	}

	const onPermChange = (value, permissionKey, roleId) => {
		let temp = JSON.parse(JSON.stringify(roles))
		temp.every(role => {
			if(role.id === roleId) {
				role.permissions[permissionKey] = value
				return false
			}
			return true
		})

		setPermChanged(true)
		setRoles(temp)

	}

	const renderPerms = () => {
		let length = roles.length
		let headers = roles.map((role,index) => (
			<div
				key={index.toString()}
				className={Styles.tableHeader}
				style={{width: `${50/length}%`}}
				>
				<label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.entryTextSize, marginLeft: 10}}>{role.name}</label>
			</div>
		))

		let entries = Object.keys(PERMISSIONS).map((key, index) => {
			let permission = PERMISSIONS[key]
			let roleEntries = roles.map((role, index2) => {
				let value = role.permissions[key]

				return (
					<div
						key={index2.toString()}
						style={{display: 'flex',  width: `${50/length}%`, padding: '5px 0px', marginLeft: 20, flexDirection: 'row', justifyContent: 'center'}}>
						<Checkbox
							checked={value}
							onChange={(event) => onPermChange(event.target.checked, key, role.id)}
							sx={{
								color: 'gray',
								'&.Mui-checked': {
									color: Colors.buttonTheme,
								},
							}}
						/>
					</div>
				)
			})

			return (
				<div
					key={index.toString()}
					style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid ${Colors.tableLineColor}`,}}>
					<div style={{display: 'flex',  width: '50%', padding: '10px 0px'}}>
						<label style={{color: Colors.primaryTextColor, fontSize: CONSTANTS.entryTextSize, marginLeft: 10}}>{permission}</label>
					</div>
					{roleEntries}
				</div>
			)
		})
		return (
			<div style={{width: '100%', marginTop: 20}}>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<div
					className={Styles.tableHeader}
					style={{width: '50%'}}
					>
						<label style={{color: Colors.tertiaryTextColor, fontSize: CONSTANTS.entryTextSize, marginLeft: 10}}>Permissions</label>
					</div>
					{headers}
				</div>
				{entries}
			</div>
		)
	}

	const renderCompanyInfo = () => {
		return (
			<Accordion
				sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 2}}
				expanded={expanded === 'business'}
				onChange={() => handleExpandChange('business')}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
					aria-controls="permissions-content"
					id="permissions-header"
					sx={{height: 40}}
				>
					<p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>Company Information</p>
				</AccordionSummary>
				<AccordionDetails>
					<div style={{width: '100%', padding: '10px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
						<div style={{width: '50%'}}>
							<CustomTextFieldNew
								width={'100%'}
								label={'Company Name'}
								placeholder={'Company Name'}
								value={businessDetails?.name}
								onChange={(text) => {
									let temp = Object.assign({}, businessDetails)
									temp['name'] = text
									setBusinessDetails(temp)
								}}
							/>

							<CustomTextFieldNew
								marginTop={10}
								width={'100%'}
								label={'Company Email'}
								placeholder={'Email Address'}
								value={businessDetails?.email}
								onChange={(text) => {
									let temp = Object.assign({}, businessDetails)
									temp['email'] = text
									setBusinessDetails(temp)
								}}
							/>

							<CustomTextFieldNew
								marginTop={10}
								width={'100%'}
								label={'Company Phone #'}
								placeholder={'Phone Number'}
								value={businessDetails?.phone}
								onChange={(text) => {
									let temp = Object.assign({}, businessDetails)
									temp['phone'] = Helpers.checkPhoneNumberFormat(text)
									setBusinessDetails(temp)
								}}
							/>

							<CustomTextFieldNew
								marginTop={10}
								width={'100%'}
								label={'Company Address'}
								placeholder={'Address Line 1'}
								value={businessDetails?.address}
								onChange={(text) => {
									let temp = Object.assign({}, businessDetails)
									temp['address'] = text
									setBusinessDetails(temp)
								}}
							/>

							<CustomTextFieldNew
								marginTop={10}
								width={'100%'}
								label={'Company City/State'}
								placeholder={'City & State'}
								value={businessDetails?.city_state}
								onChange={(text) => {
									let temp = Object.assign({}, businessDetails)
									temp['city_state'] = text
									setBusinessDetails(temp)
								}}
							/>


							<CustomTextFieldNew
								marginTop={10}
								width={'100%'}
								label={'Company Zip Code'}
								placeholder={'Zip Code'}
								value={businessDetails?.zip}
								onChange={(text) => {
									let temp = Object.assign({}, businessDetails)
									temp['zip'] = text
									setBusinessDetails(temp)
								}}
							/>

						</div>

						<div style={{width: '45%', position: 'relative'}}>
							<div>
								<label className={GeneralStyles.darkText} style={{fontWeight: '600', fontSize: 15}}>Company Logo</label>
								{renderCompanyLogoSelector()}
								<input
									id="image"
									name="user[image]"
									type="file"
									accept="image/*"
									style={{display: 'none'}}
									onChange={handleFileChange}
									ref={fileRef} />
							</div>

							<CustomButtonContained
								style={{height: 36, position: 'absolute', bottom: 0, right: 0}}
								text={`\u2713 Save Changes`}
								onClick={onSaveCompanyClicked}
							/>

						</div>

					</div>
				</AccordionDetails>
			</Accordion>
		)
	}

	const handleFileChange = (event) => {
		let selectedFile = event.target.files && event.target.files[0]
		if(!selectedFile)
			return
		setSelectedLogo(selectedFile);
	}

	const renderCompanyLogoSelector = () => {
		if(selectedLogo) {
			return (
				<div className={`${Styles.imageContainer}`}>
					<img alt="not fount" width={'80%'} height={'100%'} src={URL.createObjectURL(selectedLogo)} style={{borderRadius: 10}}/>
					<p onClick={() => fileRef.current.click()} style={{textDecoration: 'underline', cursor: 'pointer'}}>change picture</p>
				</div>
			)
		}

		if(businessDetails?.logo_url) {
			return (
				<div className={`${Styles.imageContainer}`}>
					<img alt="not fount" width={'80%'} height={'100%'} src={businessDetails?.logo_url} style={{borderRadius: 10}}/>
					<p onClick={() => fileRef.current.click()} style={{textDecoration: 'underline', cursor: 'pointer'}}>change picture</p>
				</div>
			)
		}

		return (
			<div className={`${Styles.imageContainer} ${GeneralStyles.boxShadow}`} style={{backgroundColor: 'transparent'}}>
				<img
					src={UploadIcon} style={{width: 50, height: 50, cursor: 'pointer'}}/>
				<p>Upload Company Logo</p>
				<CustomButtonContained
					style={{margin: '0 auto', height: 40}}
					text={'Browse to upload'}
					onClick={() => fileRef.current.click()}/>
			</div>
		)
	}

	const renderAccountSettings = () => {
		return (
			<Accordion
				sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 1}}
				expanded={expanded === 'perms'}
				onChange={() => handleExpandChange('perms')}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
					aria-controls="permissions-content"
					id="permissions-header"
					sx={{height: 40}}
				>
					<p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>Accounts & Permissions</p>
				</AccordionSummary>
				<AccordionDetails>
					<div style={{width: '100%', padding: '10px 0px'}}>
						<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
							<div style={{width: '30%'}}>
								<Tab tab={rolesTab} onChange={setRolesTab}/>
							</div>
							{
								rolesTab === 'users' ?
									<label
										onClick={() => setShowUserModal(true)}
										style={{color: Colors.secondaryTextColor, textDecoration: 'underline', cursor: 'pointer'}}>+ Add User</label>
									:
									permChanged &&
									<CustomButtonContained
										style={{marginRight: 2, height: 34}}
										text={`\u2713 Save Changes`}
										onClick={onSavePermsClicked}
									/>
							}
						</div>
						{
							rolesTab === 'perms' ?
								renderPerms()
								:
								renderUsers()
						}
					</div>
				</AccordionDetails>
			</Accordion>
		)
	}

	const renderPriceSettings = () => {
		return (
			<div style={{width: '100%'}}>
				<Accordion
					sx={{boxShadow: '0px 4px 25px 0px #0000001F', border: '1px solid #E7E7E7', marginTop: 1}}
					expanded={expanded === 'price'}
					onChange={() => handleExpandChange('price')}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon style={{color: Colors.primaryTextColor}}/>}
						aria-controls="settings-content"
						id="settings-header"
						sx={{height: 40}}
					>
						<p className={GeneralStyles.darkText} style={{fontWeight: 'bold'}}>Pricing & Taxes</p>
					</AccordionSummary>
					<AccordionDetails>
						<div style={{width: '100%', display: 'flex', flexDirection:'row', position: 'relative'}}>
							<div style={{width: '50%'}}>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									<label style={{fontSize: CONSTANTS.entryTextSize, color: Colors.primaryTextColor}}>Enable / Disable Taxes & Fees</label>
									<CustomSwitch
										onChange={(value) => {
											let temp = Object.assign({}, priceSettings)
											temp['enabled'] = value
											setPriceSettings(temp)
										}}
										containerStyle={{marginLeft: 30}}
										checked={priceSettings?.enabled}/>
								</div>

								<div style={{marginTop: 10}}>
									<CustomTextFieldNew
										width={'100%'}
										label={'Sales Tax %: (Rentals)'}
										placeholder={'Sales Tax'}
										value={priceSettings?.sales_tax}
										onChange={(text) => {
											if(isNaN(text))
												return
											let temp = Object.assign({}, priceSettings)
											temp['sales_tax'] = text
											setPriceSettings(temp)
										}}
									/>

								</div>

								<div style={{marginTop: 10}}>
									<CustomTextFieldNew
										width={'100%'}
										label={'Service Tax %: (Rentals)'}
										placeholder={'Service Tax'}
										value={priceSettings?.service_tax?.rentals}
										onChange={(text) => {
											if(isNaN(text))
												return
											let temp = JSON.parse(JSON.stringify(priceSettings))
											temp['service_tax']['rentals']= text
											setPriceSettings(temp)
										}}
									/>
								</div>

								<div style={{marginTop: 10}}>
									<CustomTextFieldNew
										width={'100%'}
										label={'Service Tax %: (Chauffeur)'}
										placeholder={'Service Tax Chauffeur'}
										value={priceSettings?.service_tax?.chauffeur}
										onChange={(text) => {
											if(isNaN(text))
												return
											let temp = JSON.parse(JSON.stringify(priceSettings))
											temp['service_tax']['chauffeur']= text
											setPriceSettings(temp)
										}}
									/>
								</div>

								<div style={{marginTop: 10}}>
									<CustomTextFieldNew
										width={'100%'}
										label={'Additional Location Charge $: (rentals)'}
										placeholder={'Additional Location Charge'}
										value={priceSettings?.location_fee}
										onChange={(text) => {
											if(isNaN(text))
												return
											let temp = Object.assign({}, priceSettings)
											temp['location_fee']= text
											setPriceSettings(temp)
										}}
									/>
								</div>
							</div>

							<div style={{width: '50%', marginLeft: 40, height: '25vh', overflowY: 'scroll'}}>
								{renderDiscounts()}
							</div>

							<div style={{position: 'absolute', right: 0, bottom: 0}}>
								<CustomButtonContained
									style={{marginRight: 2}}
									text={'Save Changes'}
									onClick={onSavePriceClicked}
								/>
							</div>
						</div>

					</AccordionDetails>
				</Accordion>
			</div>
		)
	}

	const handlePhoneChange = (text) => {
		setModalUser(Object.assign({}, modalUser, {phone: Helpers.checkPhoneNumberFormat(text)}))
	}

	const renderUserModal = () => {
		let options = []
		let currentRole

		roles.forEach(role => {
			options.push({label: role.name, value: role.id})
			if(role.id === modalUser.role)
				currentRole = role
		})

		let roleValue = null
		if(modalUser.role)
			roleValue = {value: modalUser.role, label: currentRole?.name}
		return (
			<CustomModal
				show={showUserModal}
				handleClose={() => setShowUserModal(false)}
				containerWidth={window.innerWidth/2.2}
				containerHeight={window.innerHeight/1.2}
			>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%', margin: '0 auto'}}>
					<p style={{textAlign: 'center', width: '100%', fontWeight: 'bold', marginBottom: 20, fontSize: CONSTANTS.headerSize}}>Add User & Assign Role</p>
					<label style={{color: Colors.primaryTextColor, fontSize: CONSTANTS.entryTextSize, width: '100%', marginBottom: 10, fontWeight: '600'}}>Profile Information</label>
					<div style={{width: '100%', marginBottom: 14}}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Full Name'}
							placeholder={'First & Last Name'}
							value={modalUser.name}
							onChange={(text) => {
								let temp = Object.assign({}, modalUser, {name: text})
								setModalUser(temp)
							}}
						/>
					</div>
					<div style={{width: '100%', marginBottom: 14}}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Phone'}
							placeholder={'Phone Number'}
							value={modalUser.phone}
							onChange={handlePhoneChange}
						/>
					</div>
					<div style={{width: '100%', marginBottom: 14}}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Email'}
							placeholder={'Email Address'}
							value={modalUser.email}
							onChange={(text) => {
								let temp = Object.assign({}, modalUser, {email: text})
								setModalUser(temp)
							}}
						/>
					</div>
					<div style={{width: '100%', marginBottom: 14}}>
						<CustomTextFieldNew
							hidden={true}
							width={'100%'}
							label={'Password'}
							placeholder={'Temporary Password'}
							value={pwd}
							onChange={setPwd}
						/>
					</div>

					<label style={{color: Colors.primaryTextColor, fontSize: CONSTANTS.entryTextSize, width: '100%', marginTop: 20, marginBottom: 10, fontWeight: '600'}}>Assign Role</label>
					<div
						className={GeneralStyles.boxShadow}
						style={{width: '100%', height: 40, borderRadius: 10, marginTop: 4}}>
						<CustomSelect
							placeholder={'select'}
							borderColor={'transparent'}
							value={roleValue}
							options={options}
							onChange={(selected) => {
								let temp = Object.assign({}, modalUser, {role: selected.value})
								setModalUser(temp)
							}}
						/>

					</div>
				</div>


				<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom : 10}}>
					<CustomButtonContained
						style={{}}
						text={`\u2713 Add User`}
						onClick={onAddPersonConfirmed}
					/>
				</div>
			</CustomModal>
		)
	}

	const renderContent = () => {
		return (
			<div style={{width: '100%'}}>
				{/*{renderSalesPeople()}*/}
				<label
					className={GeneralStyles.darkText}
					style={{fontSize: CONSTANTS.headerSize, fontWeight: 'bold'}}>Account Settings</label>
				{renderAccountSettings()}
				{renderCompanyInfo()}

				<div style={{marginTop: 30}}/>
				<label
					className={GeneralStyles.darkText}
					style={{fontSize: CONSTANTS.headerSize, fontWeight: 'bold'}}>System Settings</label>
				{renderPriceSettings()}


			</div>
		)
	}

	if(loading || loading2) {
		return (
			<div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader header={'Settings'}/>
			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setMessage('')
				}}
				severity={showSuccess ? 'success' : 'error'}
				openFlag={showError || showSuccess}
				message={message}
			/>

			{renderContent()}

			<CustomModal
				show={showDiscountModal}
				handleClose={() => {
					setShowDiscountModal(false)
					setDiscountModalDetails({name: '', discount: ''})
				}}
				containerWidth={window.innerWidth/3}
				containerHeight={300}
			>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<p style={{textAlign: 'center', width: '100%', fontWeight: 'bold', marginBottom: 20}}>Add Discount Code</p>
					<div style={{width: '80%'}}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Discount Name'}
							placeholder={'Discount Display Name'}
							value={discountModalDetails.name}
							onChange={(text) => {
								let temp = Object.assign({}, discountModalDetails)
								temp['name'] = text
								setDiscountModalDetails(temp)
							}}
						/>
					</div>

					<div style={{width: '80%', marginTop: 20}}>
						<CustomTextFieldNew
							width={'100%'}
							label={'Discount Percentage'}
							placeholder={'Discount Percentage'}
							value={discountModalDetails.discount}
							onChange={(text) => {
								if(isNaN(text))
									return
								let temp = Object.assign({}, discountModalDetails)
								temp['discount'] = text
								setDiscountModalDetails(temp)
							}}
						/>

					</div>
				</div>

				<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom : 10}}>
					<CustomButtonContained
						style={{}}
						text={`\u2713 Add Discount Code`}
						onClick={onAddDiscountClicked}
					/>
				</div>

			</CustomModal>

			{renderUserModal()}
		</div>
)
}

const Tab = ({tab, onChange}) => {
	return (
		<div style={{width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme}}>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 'users' ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange('users')}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>User Accounts</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 'perms' ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange('perms')}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Roles & Perms</p>
			</div>
		</div>
	)
}

export default Settings
