import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { collection, getDocs, addDoc } from "firebase/firestore";
import {firestore} from '../../services/firebase'
import {API_CALL_STATUS} from '../../metadata/enums'
import {
	FETCH_OLD_RESERVATIONS,
	ADD_NEW_RESERVATION,
	UPDATE_RESERVATION,
	FETCH_UPCOMING_RESERVATIONS,
	DELETE_RESERVATION
} from '../actionTypes'
import moment from 'moment'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from '../../utils/Logger'

const initialState = {
	reservations: [],
	old_reservations: [],
	status: API_CALL_STATUS.IDLE,
	new_reservation: false,
	reservation_to_update: null,
	updated_reservation: false,
	deletedReservation: false,
	error: null
}

export const deleteReservation = createAsyncThunk(DELETE_RESERVATION, async (payload) => {
	const config = {
		method: 'delete',
		url: `${Config.BACKEND_URL}reservations/${payload.id}`,
		headers: {Authorization: payload.token},
	}
	console.log('deleting res', payload)
	try {
		let res = await axios(config)
		LOGGER.log('successfully deleted')
		return payload.id
	}catch(err) {
		LOGGER.error('Error when deleting reservation', err)
		return []
	}

})

export const fetchUpcomingReservations = createAsyncThunk(FETCH_UPCOMING_RESERVATIONS, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}reservations`,
		headers: {Authorization: payload.token},
		params: {start_date: payload.startDate}
	}
	try {
		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting reservations', err)
		return []
	}

})

export const fetchOldReservations = createAsyncThunk(FETCH_OLD_RESERVATIONS, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}reservations/old`,
		headers: {Authorization: payload.token},
	}
	try {
		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting old reservations', err)
		return []
	}

})

export const addReservationAndConfirm = createAsyncThunk(ADD_NEW_RESERVATION, async (payload) => {
	try {
		const config = {
			method: 'post',
			url: `${Config.BACKEND_URL}reservations`,
			headers: { Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		let res = await axios(config)
		let reservation = JSON.parse(JSON.stringify(payload.data))
		reservation._id = res.data._id
		if(reservation.client)
			return reservation
		else {
			reservation.client = res.data.clientId
			reservation.customer._id = res.data.clientId
			return reservation
		}
	}catch(err) {
		LOGGER.log('error when adding new reservation', err)
	}

	// return order
})

export const updateReservationAndConfirm = createAsyncThunk(UPDATE_RESERVATION, async (payload) => {
	try {
		let id = payload.data._id
		console.log('id is', id)
		const config = {
			method: 'put',
			url: `${Config.BACKEND_URL}reservations/${id}`,
			headers: { Authorization: payload.token, contentType: "application/json",},
			data: payload.data
		}

		let res = await axios(config)
		let reservation = JSON.parse(JSON.stringify(payload.data))
		if(reservation.client) {
			return reservation
		} else {
			reservation.client = res.data.clientId
			reservation.customer._id = res.data.clientId
			return reservation
		}
		return payload.data
	}catch(err) {
		LOGGER.log('error when updating reservation', err)
	}

	// return order
})




const reservationsSlice = createSlice({
	name: 'reservations',
	initialState,
	reducers: {
		updateNewReservationStatus(state, action) {
			return Object.assign({}, state, {new_reservation: action.payload})
		},
		updateReservation(state, action) {
			return Object.assign({}, state, {reservation_to_update: action.payload})
		},
		setUpdateReservationStates(state, action) {
			return Object.assign({}, state, {updated_reservation: false})
		},
		setDeletedReservation(state,action) {
			return Object.assign({}, state, {deletedReservation: action.payload})
		},
		updateReservationFromSocket(state,action) {
			let data = action.payload
			let startDate = moment(data.pickup_datetime)
			let endDate = moment(data.dropoff_datetime)
			if(startDate.isBefore(moment()) && endDate.isBefore(moment())) {
				//update in old reservations list
				let newArray = []
				state.old_reservations.forEach(reservation => {
					if(reservation._id === data._id)
						newArray.push(data)
					else
						newArray.push(reservation)
				})
				return Object.assign({}, state, {old_reservations: newArray})
			} else {
				let newArray = []
				state.reservations.forEach(reservation => {
					if(reservation._id === data._id)
						newArray.push(data)
					else
						newArray.push(reservation)
				})
				return Object.assign({}, state, {reservations: newArray})
			}
		},
		addNewReservationFromSocket(state, action) {
			let reservation = action.payload
			let startDate = moment(reservation.pickup_datetime)
			let endDate = moment(reservation.dropoff_datetime)
			if(startDate.isBefore(moment()) && endDate.isBefore(moment())) {
				//should go in old rservations list
				let oldRes = [...state.old_reservations]
				oldRes.unshift(reservation)
				return Object.assign({}, state, {old_reservations: oldRes, reservation_to_update: reservation})
			} else {
				let newRes = [...state.reservations]
				newRes.unshift(reservation)
				return Object.assign({}, state, {reservations: newRes, reservation_to_update: reservation})
			}
		}
	},
	extraReducers(builder) {
		builder
			.addCase(addReservationAndConfirm.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(addReservationAndConfirm.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				let reservation = action.payload
				let startDate = moment(reservation.pickup_datetime)
				let endDate = moment(reservation.dropoff_datetime)
				if(startDate.isBefore(moment()) && endDate.isBefore(moment())) {
					//should go in old rservations list
					state.old_reservations.unshift(reservation)
				} else {
					state.reservations.unshift(reservation)
				}
				state.new_reservation = action.payload
				state.reservation_to_update = action.payload
			})
			.addCase(addReservationAndConfirm.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(fetchUpcomingReservations.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(fetchUpcomingReservations.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.reservations = action.payload
			})
			.addCase(fetchUpcomingReservations.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(updateReservationAndConfirm.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(updateReservationAndConfirm.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				let temp = []
				state.reservations.forEach(reservation => {
					if(reservation._id === action.payload._id)
						temp.push(Object.assign({}, reservation, action.payload))
					else
						temp.push(reservation)
				})

				state.reservations = temp

				let temp2 = []
				state.old_reservations.forEach(reservation => {
					if(reservation._id === action.payload._id)
						temp2.push(action.payload)
					else
						temp2.push(reservation)
				})
				state.old_reservations = temp2

				state.updated_reservation = action.payload
			})
			.addCase(updateReservationAndConfirm.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(fetchOldReservations.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(fetchOldReservations.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.old_reservations = action.payload
			})
			.addCase(fetchOldReservations.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(deleteReservation.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(deleteReservation.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.reservations.every(reservation => {
					if(reservation._id === action.payload) {
						reservation.deleted = true
						return false
					}
					return true
				})
				state.old_reservations.every(reservation => {
					if(reservation._id === action.payload) {
						reservation.deleted = true
						return false
					}
					return true
				})
				state.deletedReservation = true
			})
			.addCase(deleteReservation.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
	}

})

export const getAllReservationsSelector = (state) => {
	if(state.status === API_CALL_STATUS.LOADING)
		return []
	let oldR = state.reservations.old_reservations
	let newR = state.reservations.reservations
	return newR.concat(oldR)
}

export const reservationsMapSelector = (reservationsState) => {
	if(!reservationsState || reservationsState.status === API_CALL_STATUS.LOADING) {
		return {}
	} else {
		let map = {}
		reservationsState.reservations.concat(reservationsState.old_reservations).map(res => map[res._id] = res)
		return map
	}
}


export const { updateNewReservationStatus,setUpdateReservationStates,updateReservation,setDeletedReservation,addNewReservationFromSocket,updateReservationFromSocket } = reservationsSlice.actions

export default reservationsSlice.reducer
