import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import {firestore} from '../../services/firebase'
import {UPDATE_SETTINGS, GET_SETTINGS} from '../actionTypes'
import {API_CALL_STATUS} from '../../metadata/enums'
import Config from "../../config";
import axios from "axios";
import {LOGGER} from "../../utils/Logger";

const initialState = {
	settings: [],
	status: API_CALL_STATUS.IDLE,
	error: null,
	updated_settings: false
}

export const getSettingsData = createAsyncThunk(GET_SETTINGS, async (payload) => {
	const config = {
		method: 'get',
		url: `${Config.BACKEND_URL}settings`,
		headers: {Authorization: payload.token},
	}

	try {
		let res = await axios(config)
		return res.data
	}catch(err) {
		LOGGER.error('Error when getting settings data', err)
		return []
	}

})

export const updateSettingsData = createAsyncThunk(UPDATE_SETTINGS, async (payload) => {
	const config = {
		method: 'put',
		url: `${Config.BACKEND_URL}settings`,
		headers: {Authorization: payload.token},
		data: payload.data
	}

	try {
		let res = await axios(config)
		return payload.data
	}catch(err) {
		LOGGER.error('Error when updating settings data', err)
		return []
	}

})


const settingsSlice = createSlice({
	name: 'analytics',
	initialState,
	reducers: {
		updateUpdatedStatus(state, action) {
			return Object.assign({}, state, {updated_settings: action.payload})
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getSettingsData.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(getSettingsData.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				state.settings = action.payload
			})
			.addCase(getSettingsData.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
			.addCase(updateSettingsData.pending, (state, action) => {
				state.status = API_CALL_STATUS.LOADING
			})
			.addCase(updateSettingsData.fulfilled, (state, action) => {
				state.status = API_CALL_STATUS.SUCCEEDED
				let temp = []
				let updatedSettings = action.payload
				state.settings.forEach(setting => {
					if(setting._id === updatedSettings._id)
						temp.push(Object.assign({}, setting, updatedSettings))
					else
						temp.push(setting)
				})

				state.settings = temp
				state.updated_settings = true
			})
			.addCase(updateSettingsData.rejected, (state, action) => {
				state.status = API_CALL_STATUS.FAILED
				state.error = action.error.message
			})
	}
})


export const getSalesSettings = (state) => {
	let salesSettings = {active: [], in_active: []}
	state.settings.forEach(setting => {
		if(setting._id === 'sales')
			salesSettings = setting
	})
	return salesSettings

}

export const getBusinessSettings = (state) => {
	let pricing = {name: '', email: '', phone: ''}
	state.settings.forEach(setting => {
		if(setting._id === 'businessDetails')
			pricing = setting
	})
	return pricing
}

export const getPriceSettings = (state) => {
	let pricing = {sales_tax: 0, service_tax: {rentals: 0, chauffeur: 0}}
	state.settings.forEach(setting => {
		if(setting._id === 'pricing')
			pricing = setting
	})
	return pricing

}

export const getPermissionSettings = (state) => {
	let permissions = {roles: []}
	state.settings.forEach(setting => {
		if(setting._id === 'permissions')
			permissions = setting
	})
	return permissions
}

export const getRolesMap = (state) => {
	let map = {}
	state.settings.forEach(setting => {
		if(setting._id === 'permissions') {
			setting.roles.forEach(role => map[role.id] = role)
		}
	})
	return map
}


export const { updateUpdatedStatus } = settingsSlice.actions

export default settingsSlice.reducer
