import React from 'react'
import Colors from '../../config/colors'
import {Document, Page, PDFViewer, StyleSheet, Text, View, Image, Tspan} from "@react-pdf/renderer";
import CarImage from '../../assets/images/car.png'
import Logo from '../../assets/images/new_logo.png'
import TNCStrings from '../../metadata/TNC'
import Helpers from '../../utils/helpers'
import Constants from "../../config/contants";

const BACKGROUND_COLOR = '#C8B06A'

const pdfStyles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: '#ffffff',
	},
	section: {
		padding: '10px 20px',
		flexDirection: 'column',
		color: Colors.theme,
		height: '100%'
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%'
	},
	headerText: {
		fontSize: 14,
		fontWeight: 'bold',
		width: '100%'
	},
	divHeader: {
		height: 25,
		width: '100%',
		backgroundColor: BACKGROUND_COLOR,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		alignItems: 'center',
		justifyContent: 'center'
  },
	detailsText: {
		fontSize: 12
	},
	bigHeaderText: {
		fontSize: 22,
		color: BACKGROUND_COLOR
	},
	reservationBox: {
		width: '100%',
		height: 120,
		borderRadius: 10,
		border: `1px solid ${Colors.theme}`,
		margin: '0 auto',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#f0f0f0'
	},
	signatureBoxBig: {
		width: '100%',
		height: 330,
		borderRadius: 10,
		border: `1px solid ${Colors.theme}`,
		margin: '0 auto',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#f0f0f0',
		marginTop: 10
	},
	checkbox: {
		height: 10,
		width: 10,
		border: `1px solid ${BACKGROUND_COLOR}`
	},
	signatureBox: {
		width: '100%',
		height: 80,
		borderBottomRightRadius: '25%',
		borderTopLeftRadius: '25%',
		border: `1px solid ${Colors.theme}`,
		margin: '0 auto',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: 4
	},
	preTripBox: {
		width: '100%',
		borderBottomRightRadius: '25%',
		borderTopLeftRadius: '25%',
		border: `1px solid ${Colors.theme}`,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '20px 10px'
	},
	clientInfoBox: {
		borderBottomRightRadius: '50%',
		borderTopLeftRadius: '50%',
		height: 30,
		backgroundColor: BACKGROUND_COLOR,
		width: '100%',
		marginTop: 20,
		flexDirection: 'row',
		alignItems: 'center'
	},
	vehicleTableBox: {
		width: '100%',
		borderRadius: 10,
		margin: '0 auto',
		flexDirection: 'column',
		marginTop: 20
	},
	divHeader2: {
		height: 25,
		width: '100%',
		backgroundColor: BACKGROUND_COLOR,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		flexDirection: 'row',
		alignItems: 'center',
	},
	tableRow : {
		height: 25,
		borderLeft: `0.5px solid ${Colors.theme}`,
		borderRight: `0.5px solid ${Colors.theme}`,
		borderBottom: `0.5px solid ${Colors.theme}`,
		flexDirection: 'row',
		width: '100%'
	},
	paymentCheckbox: {
		height: 10,
		width: 10,
		border: `1px solid ${Colors.theme}`,
		borderRadius: '50%'
	},
	footer: {
		position: 'absolute',
		bottom: 10,
		left: 20,
		fontSize: 12
	}
});



const CustomPDF = ({vehicle, customer, pickUpDateTime, returnDateTime, totalPrice, addOns, dailyPrice, checkIn, pricing, subTotal,totalDays, tax, subTotalToShow}) => {

	const renderFooter = () => {
		return <Text style={pdfStyles.footer} fixed>©2023-2024 DDE Rentals, LLC</Text>
	}

	const renderReservationBox = () => {
		return (
			<View style={pdfStyles.reservationBox}>
				<View style={pdfStyles.divHeader}>
					<Text style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center',}}>
						Reservation
					</Text>
				</View>

				<View style={[pdfStyles.row, {width: '100%', justifyContent: 'space-between', padding: '0px 10px', marginTop: 20}]}>
					<View style={[pdfStyles.row, {width: '48%', alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.detailsText, {width: 80, textAlign: 'right', marginRight: 4}]}>NAME:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{`${customer.fName} ${customer.lName}`}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: '#f0f0f0'}]}>customer-name-1</Text>
						</View>
					</View>

					<View style={[pdfStyles.row, {width: '48%', alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.detailsText, {width: 80, textAlign: 'right', marginRight: 4}]}>VEHICLE:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{vehicle.make}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: '#f0f0f0'}]}>vehicle-make-1</Text>
						</View>
					</View>

				</View>

				<View style={[pdfStyles.row, {width: '96%', justifyContent: 'space-between', marginTop: 30}]}>

					<View style={[pdfStyles.row, {width: '48%', alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.detailsText, {width: 80, textAlign: 'right', marginRight: 4}]}>PICK-UP:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: '#f0f0f0'}]}>pickup-datetime-1</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{pickUpDateTime.format('MMM DD, YYYY hh:mm A')}</Text>*/}
						</View>
					</View>

					<View style={[pdfStyles.row, {width: '48%', alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.detailsText, {width: 80, textAlign: 'right', marginRight: 4}]}>DROP-OFF:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: '#f0f0f0'}]}>dropoff-datetime-1</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{returnDateTime.format('MMM DD, YYYY hh:mm A')}</Text>*/}
						</View>
					</View>

				</View>
			</View>
		)
	}

	const renderPage1 = () => {
		return (
			<View style={[pdfStyles.section, {position: 'relative'}]}>
				{renderReservationBox()}

				<Text style={[pdfStyles.headerText, {marginTop: 20}]}>PRE-CHECK REQUIREMENTS</Text>
				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Confirmed Insurance Policy Limits</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Captured Refundable Security Deposit</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Customer Signed Rental Agreement</Text>
				</View>

				<Text style={[pdfStyles.headerText, {marginTop: 20}]}>PRE-TRIP INSPECTION</Text>
				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Vehicle is Clean [Interior & Exterior]</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Vehicle has Fuel [50% or more]</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Capture Exterior Photos [Include each rim]</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Capture Interior Photos [Include Mileage and Fuel]</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Capture Exterior Photos [Include each rim]</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px', alignItems:'flex-end', marginBottom: 20}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>I-Pass is Present - #</Text>
					<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginLeft: 10}}/>
				</View>

				<View style={pdfStyles.row}>
					<View style={{width: '60%', flexDirection: 'column'}}>
						<Text style={pdfStyles.headerText}>DAMAGE NOTES</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
					</View>
					<Image src={CarImage} style={{width: '40%', height: 160}}/>
				</View>


				<Text style={[pdfStyles.headerText, {marginTop: 30, textAlign: 'center', width: '100%'}]}>SIGNATURE - I Acknowledge that I have checked all requirements</Text>
				<View style={pdfStyles.signatureBox}>
					<View style={[pdfStyles.row, {alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 200, textAlign: 'right', marginRight: 20}]}>Representative Signature:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginTop: 20}}/>
					</View>

					<View style={[pdfStyles.row, {marginTop: 20, alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 200, textAlign: 'right', marginRight: 20}]}>Date:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginTop: 20}}/>
					</View>
				</View>

				{renderFooter()}

			</View>

		)
	}

	const renderPage9 = () => {
		return (
			<View style={pdfStyles.section}>
				{renderReservationBox()}

				<Text style={[pdfStyles.headerText, {marginTop: 20}]}>POST-TRIP INSPECTION</Text>
				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Inspect Vehicle Exterior & Capture 360° Pictures</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Inspect Vehicle Interior & Capture Pictures</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Photograph Mileage & Fuel Levels</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Check for Any Warning lights</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Check for Any Smoking</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Collect the Vehicle Keys</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px'}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>Customer Signed Post-Trip Rental Agreement</Text>
				</View>
				<Text style={{fontSize: 10, marginLeft: 35}}>[Capture Any additional charges if necessary]</Text>

				<View style={[pdfStyles.row, {marginTop: 10, padding: '0px 10px', alignItems:'flex-end', marginBottom: 20}]}>
					<View style={[pdfStyles.checkbox, {marginRight: 14}]} />
					<Text style={pdfStyles.detailsText}>I-Pass is Present - #</Text>
					<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginLeft: 10}}/>
				</View>

				<View style={pdfStyles.row}>
					<View style={{width: '60%', flexDirection: 'column'}}>
						<Text style={pdfStyles.headerText}>DAMAGE NOTES</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%', marginTop: 20}}/>
					</View>
					<Image src={CarImage} style={{width: '40%', height: 160}}/>
				</View>


				<Text style={[pdfStyles.headerText, {marginTop: 30, textAlign: 'center', width: '100%'}]}>SIGNATURE - I Acknowledge that I have checked all requirements</Text>
				<View style={pdfStyles.signatureBox}>
					<View style={[pdfStyles.row, {alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 200, textAlign: 'right', marginRight: 20}]}>Representative Signature:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginTop: 20}}/>
					</View>

					<View style={[pdfStyles.row, {marginTop: 20, alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 200, textAlign: 'right', marginRight: 20}]}>Date:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginTop: 20}}/>
					</View>
				</View>

				{renderFooter()}
			</View>
		)
	}

	const renderBusinessInfo = () => {
		return (
			<View style={{flexDirection: 'column', width: '50%', marginTop: 10}}>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Name: </Text>
					<Text style={[pdfStyles.detailsText, {fontWeight: 'bold', width: '100%'}]}>DDE Rentals, LLC</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Address: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>113 W Roosevelt Rd</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>City/State: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>Villa Park, IL</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Zip Code: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>60181</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Phone: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>(630) 754 - 7280</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Email: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>info@dderentals.com</Text>
				</View>
			</View>
		)
	}

	const renderPreTripBox = () => {
		return (
			<View style={{width: '48%'}}>
				<View style={pdfStyles.preTripBox}>
					<View style={[pdfStyles.row, {width: '100%', alignItems: 'flex-end', marginBottom: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 140, color: BACKGROUND_COLOR, textAlign: 'right', marginRight: 4}]}>PICKUP DATE:</Text>
						<View style={{borderBottom: `1px solid ${BACKGROUND_COLOR}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>pickup-datetime-2</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{pickUpDateTime.format('MMM DD, YYYY hh:mm A')}</Text>*/}
						</View>
					</View>
					<View style={[pdfStyles.row, {width: '100%', alignItems: 'flex-end', marginBottom: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 140, color: BACKGROUND_COLOR, textAlign: 'right', marginRight: 4}]}>RETURN DATE:</Text>
						<View style={{borderBottom: `1px solid ${BACKGROUND_COLOR}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>dropoff-datetime-2</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{returnDateTime.format('MMM DD, YYYY hh:mm A')}</Text>*/}
						</View>
					</View>
					<View style={[pdfStyles.row, {width: '100%', alignItems: 'flex-end', marginBottom: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 140, color: BACKGROUND_COLOR, textAlign: 'right', marginRight: 4}]}>END MILES:</Text>
						<View style={{borderBottom: `1px solid ${BACKGROUND_COLOR}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>starting-miles-2</Text>
							{/*{checkIn && checkIn.mileage && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{`${checkIn?.mileage} miles`}</Text>}*/}
						</View>
					</View>
					<View style={[pdfStyles.row, {width: '100%', alignItems: 'flex-end', marginBottom: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 140, color: BACKGROUND_COLOR, textAlign: 'right', marginRight: 4}]}>FUEL LEVEL:</Text>
						<View style={{borderBottom: `1px solid ${BACKGROUND_COLOR}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>starting-fuel-2</Text>
							{/*{checkIn && checkIn.fuel && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{`${checkIn?.fuel}%`}</Text>}*/}
						</View>
					</View>
				</View>
			</View>
		)
	}

	const renderClientInfo = () =>  {
		let address = customer.address?.label || ' , '
		let components = address.split(',')
		let cityPlace = ''
		components.forEach((each,i) => {
			if(i !== 0)
				cityPlace += each
		})

		return (
			<View>
				<View style={[pdfStyles.clientInfoBox]}>
					<Text style={[pdfStyles.headerText, {marginLeft: 15}]}>Client Information</Text>
				</View>

				<View style={{flexDirection: 'column', width: '90%'}}>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>Name:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-name-2</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{`${customer.fName} ${customer.lName}`}</Text>*/}
						</View>
					</View>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>Street Address:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-street-address-2</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{components[0]}</Text>*/}
						</View>
					</View>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>City, State, Zip:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-city-place-2</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{cityPlace}</Text>*/}
						</View>
					</View>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>Phone:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-phone-2</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{customer.phone}</Text>*/}
						</View>
					</View>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>Email:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-email-2</Text>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{customer.email}</Text>*/}
						</View>
					</View>
				</View>
			</View>
		)
	}

	const renderPaymentOptions = () => {
		return (
			<View style={{flexDirection: 'row', alignItems: 'center'}}>
				<View style={{flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
					<View style={[pdfStyles.paymentCheckbox, {marginRight: 4}]} />
					<Text style={{fontSize: 12}}>Cash</Text>
				</View>
				<View style={{flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
					<View style={[pdfStyles.paymentCheckbox, {marginRight: 4}]} />
					<Text style={{fontSize: 12}}>Card</Text>
				</View>
				<View style={{flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
					<View style={[pdfStyles.paymentCheckbox, {marginRight: 4}]} />
					<Text style={{fontSize: 12}}>Other</Text>
				</View>
			</View>
		)
	}

	const getDiscountedAmount = () => {
		if(pricing?.discount?.label) {
			if(pricing.discount.label.toLowerCase() === 'custom amount')
				return Number(pricing.discount.value)
			else
				return Number((pricing.discount.value * subTotal)/100)
		} else {
			return 0
		}
	}

	const getAddOnLabel = (field) => {
		switch(field) {
			case 'fuel' : return `Add ${addOns[field]?.value}% Fuel Charge`

			case 'mileage' : return `Add ${addOns[field]?.value} miles`
			case 'location1' : return `Additional Location Charge`
			case 'location2' : return `Additional Location Charge`
			default: return ''
		}
	}

	const renderVehicleTable = (preTrip) => {
		let discount = getDiscountedAmount(pricing)
		let addOnElements = []
		addOns && Object.keys(addOns).forEach((key,index) => {
			let label = getAddOnLabel(key)
			addOnElements.push((
				<View
					style={ pdfStyles.tableRow}>
					<View style={{width: '50%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{label}</Text>}
					</View>
					<View style={{width: '18%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}} />
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{addOns[key]?.price?.toFixed(2)}</Text>}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{addOns[key]?.price?.toFixed(2)}</Text>}
					</View>
				</View>
			))

		})

		return (
			<View style={pdfStyles.vehicleTableBox}>
				<View style={pdfStyles.divHeader2}>
					<Text style={[pdfStyles.headerText, {width: '50%', textAlign: 'center'}]}>
						ITEMS
					</Text>
					<Text style={[pdfStyles.headerText, {width: '18%', textAlign: 'center'}]}>
						QUANTITY
					</Text>
					<Text style={[pdfStyles.headerText, {width: '16%', textAlign: 'center'}]}>
						PRICE
					</Text>
					<Text style={[pdfStyles.headerText, {width: '16%', textAlign: 'center'}]}>
						Amount ($)
					</Text>
				</View>

				<View
					style={ pdfStyles.tableRow}>
					<View style={{width: '50%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>vehicle-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{vehicle.make}</Text>}*/}
					</View>
					<View style={{width: '18%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>total-days-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{totalDays} day(s)</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>daily-price-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{dailyPrice}</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>total-price-t-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{dailyPrice * totalDays}</Text>}*/}
					</View>
				</View>

				<View
					style={ pdfStyles.tableRow}>
					<View style={{width: '50%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>l-1-2</Text>
					</View>
					<View style={{width: '18%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>{``}</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{totalDays} day(s)</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>l-1-c1-p-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>l-1-c1-p-2</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>l-1-c2-p-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{dailyPrice * totalDays}</Text>}*/}
					</View>
				</View>

				<View
					style={ pdfStyles.tableRow}>
					<View style={{width: '50%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>l-2-2</Text>
					</View>
					<View style={{width: '18%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>{``}</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{totalDays} day(s)</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>l-2-c1-p-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>l-1-c1-p-2</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>l-2-c2-p-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{dailyPrice * totalDays}</Text>}*/}
					</View>
				</View>

				<View
					style={ pdfStyles.tableRow}>
					<View style={{width: '50%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>m-l-2</Text>
					</View>
					<View style={{width: '18%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>{``}</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{totalDays} day(s)</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>mop-c1-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>l-1-c1-p-2</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>mop-c2-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{dailyPrice * totalDays}</Text>}*/}
					</View>
				</View>

				<View
					style={ pdfStyles.tableRow}>
					<View style={{width: '50%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>dis-label-2</Text>
					</View>
					<View style={{width: '18%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>{``}</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{totalDays} day(s)</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>dis-p-c1-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>l-1-c1-p-2</Text>}*/}
					</View>
					<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>dis-p-c2-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{dailyPrice * totalDays}</Text>}*/}
					</View>
				</View>


				{/*{*/}
				{/*	discount && discount > 0 &&*/}
				{/*	<View*/}
				{/*		style={ pdfStyles.tableRow}>*/}
				{/*		<View style={{width: '50%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>*/}
				{/*			{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>Discount - {pricing?.discount?.label}</Text>}*/}
				{/*		</View>*/}
				{/*		<View style={{width: '18%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}} />*/}
				{/*		<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>*/}
				{/*			{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{discount?.toFixed(2)}</Text>}*/}
				{/*		</View>*/}
				{/*		<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>*/}
				{/*			{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>({discount?.toFixed(2)})</Text>}*/}
				{/*		</View>*/}
				{/*	</View>*/}
				{/*}*/}


				{/*empty row*/}
				{/*<View*/}
				{/*	style={ pdfStyles.tableRow}>*/}
				{/*	<View style={{width: '50%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}} />*/}
				{/*	<View style={{width: '18%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}} />*/}
				{/*	<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}} />*/}
				{/*	<View style={{width: '16%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}} />*/}
				{/*</View>*/}



				<View style={[pdfStyles.row, {height: 25}]}>
					<Text style={{fontSize: 10, width: '50%', textAlign: 'center'}}>Please refer to rental agreement for all terms and conditions.</Text>
					<View style={{width: '18%'}}/>
					<Text style={{fontSize: 12, width: '16%', textAlign: 'center'}}>SUBTOTAL</Text>
					<View style={{width: '16.2%', border: `0.5px solid ${Colors.theme}`, height: '100%', flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>subtotal-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{subTotalToShow.toFixed(2)}</Text>}*/}
					</View>
				</View>

				<View style={[pdfStyles.row, {height: 25}]}>
					<Text style={{fontSize: 10, width: '50%', textAlign: 'center'}}>Payment is due by rental date</Text>
					<View style={{width: '18%'}}/>
					<Text style={{fontSize: 12, width: '16%', textAlign: 'center'}}>SERVICE FEE</Text>
					<View style={{width: '16.2%', border: `0.5px solid ${Colors.theme}`, height: '100%', flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>service-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{tax?.toFixed(2)}</Text>}*/}
					</View>
				</View>

				<View style={[pdfStyles.row, {height: 40}]}>
					<View style={{flexDirection: 'column', width: '68%', height: '100%', justifyContent: 'space-evenly'}}>
						<View style={[pdfStyles.row]}>
							<Text style={{fontSize: 12, marginRight: 10}}>Form of Payment: </Text>
							{renderPaymentOptions()}
						</View>
						<View style={{flexDirection: 'row'}}>
							<Text style={pdfStyles.detailsText}>Amount</Text>
							<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5}}/>
						</View>
					</View>
					<Text style={{fontSize: 12, width: '16%', textAlign: 'center'}}>TOTAL</Text>
					<View style={{width: '16.2%', border: `0.5px solid ${Colors.theme}`, height: '100%', flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>total-price-2</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{totalPrice.toFixed(2)}</Text>}*/}
					</View>
				</View>
			</View>
		)
	}

	const renderCustomerSignatureBox = () => {
		return (
			<View style={{marginTop: 14}}>
				<Text style={{fontWeight: 'bold', fontSize: 10}}>BY SIGNING YOU AGREE TO THE TOTAL STATED AMOUNT WITH ALL TERMS AND CONDITIONS</Text>
				<View style={[pdfStyles.signatureBox, {width: '80%', margin: 0}]}>
					<View style={[pdfStyles.row, {alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 140, textAlign: 'right', marginRight: 20}]}>Customer Signature:</Text>
						<View style={{width: '50%', marginTop: 4}}>
							<Text style={{fontSize: 14, color: 'white'}}>customer-signature-2</Text>
						</View>
					</View>

					<View style={[pdfStyles.row, { alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 140, textAlign: 'right', marginRight: 20}]}>Date:</Text>
						<View style={{width: '50%', marginTop: 16}}>
							<Text style={{fontSize: 14, color: 'white'}}>today-date-2</Text>
						</View>
					</View>
				</View>
			</View>
		)
	}

	const renderPage2 = (preTrip) => {
		return (
			<View style={pdfStyles.section}>
				<View style={[pdfStyles.row, {width: '100%', justifyContent: 'space-between'}]}>
					<Image src={Logo} style={{width: 160, height: 80}}/>
					<Text style={[pdfStyles.bigHeaderText]}>{preTrip ? 'PRE-TRIP INVOICE' : 'POST-TRIP INVOICE'}</Text>
				</View>

				<View style={[pdfStyles.row, {width: '100%', justifyContent: 'space-between', alignItems: 'flex-end'}]}>
					{renderBusinessInfo()}
					{renderPreTripBox()}
				</View>

				{renderClientInfo()}
				{renderVehicleTable(preTrip)}

				<View style={[pdfStyles.row, {marginTop: 20, alignItems: 'flex-end'}]}>
					<Text style={pdfStyles.detailsText}>Security Deposit Amount: </Text>
					<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5, marginRight: 10}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>security-deposit-2</Text>
						{/*{preTrip && pricing && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{`$${pricing?.deposit}`}</Text>}*/}
					</View>
					{renderPaymentOptions()}
				</View>

				{renderCustomerSignatureBox()}

				{renderFooter()}
			</View>

		)
	}

	const renderPage3 = () => {
		let elements = [0,1,2,3].map(number => {
			return (
				<Text style={{fontSize: 12, textAlign: 'left', marginTop: 20}}>
					{`${number+1}. ${TNCStrings(number)}`}
				</Text>
			)
		})

		return (
			<View style={pdfStyles.section}>
				<View style={[pdfStyles.row, {width: '100%', justifyContent: 'space-between', alignItems: 'flex-end'}]}>
					<Text style={pdfStyles.headerText}>RENTAL AGREEMENT TERMS AND CONDITIONS</Text>
					<Image src={Logo} style={{width: 160, height: 80}}/>
				</View>

				{elements}

				<View style={[pdfStyles.row, {justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 100}]}>
					<Text style={{fontSize: 14, fontWeight: 'bold'}}>Initial Here: </Text>
					<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5, marginRight: 10}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>initials-3</Text>
					</View>
				</View>
				{renderFooter()}
			</View>
		)
	}

	const renderPage4 = () => {
		let elements = [4,5,6,7].map(number => {
			let string = TNCStrings(number)
			if(number === 7)
				string = TNCStrings(number, 'ma-4', 'mop-4')
				// string = TNCStrings(number, checkIn?.mileage_allowance || '', checkIn.mileage_allowance ? pricing?.mileage_overage_price : '')
			return (
				<Text style={{fontSize: 12, textAlign: 'left', marginTop: 20}}>
					{`${number+1}. ${string}`}
				</Text>
			)
		})

		return (
			<View style={pdfStyles.section}>
				{elements}

				<View style={[pdfStyles.row, {justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 100}]}>
					<Text style={{fontSize: 14, fontWeight: 'bold'}}>Initial Here: </Text>
					<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5, marginRight: 10}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>initials-4</Text>
					</View>
				</View>

				{renderFooter()}
			</View>
		)
	}

	const renderPage5 = () => {
		let elements = [8,9,10,11,12,13,14,15].map(number => {
			return (
				<Text style={{fontSize: 12, textAlign: 'left', marginTop: 20}}>
					{`${number+1}. ${TNCStrings(number)}`}
				</Text>
			)
		})

		return (
			<View style={pdfStyles.section}>
				{elements}

				<View style={[pdfStyles.row, {justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 100}]}>
					<Text style={{fontSize: 14, fontWeight: 'bold'}}>Initial Here: </Text>
					<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5, marginRight: 10}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>initials-5</Text>
					</View>
				</View>

				{renderFooter()}
			</View>
		)
	}

	const renderPage6 = () => {
		let elements = [16,17,18].map(number => {
			return (
				<Text style={{fontSize: 12, textAlign: 'left', marginTop: 20}}>
					{`${number+1}. ${TNCStrings(number)}`}
				</Text>
			)
		})

		return (
			<View style={pdfStyles.section}>
				{elements}

				<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 20}]}>
					<Text style={{fontSize: 14, fontWeight: 'bold'}}>Drivers License Number: </Text>
					<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5, marginRight: 10}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>dl-number-6</Text>
					</View>
				</View>

				<View style={pdfStyles.signatureBoxBig}>
					<View style={pdfStyles.divHeader}>
						<Text style={{fontSize: 14, fontWeight: 'bold', textAlign: 'center',}}>
							Signature
						</Text>
					</View>

					<Text style={{fontSize: 10, marginTop: 10, textAlign: 'left', width: '100%', padding: '0px 20px'}}>
						You agree to all the terms and conditions on both sides of this Agreement, and You acknowledge that Your were given an opportunity to read this Agreement before being asked to sign. You authorize Us to process a credit/debit card voucher in Your name for all charges due under this Agreement. Your signature below is considered made on the applicable credit/debit card voucher.
					</Text>
					<Text style={{fontSize: 10, marginTop: 10, textAlign: 'left', width: '100%', padding: '0px 20px'}}>
						All charges subject to final audit
					</Text>
					<Text style={{fontSize: 12, marginTop: 10, textAlign: 'left', width: '100%', padding: '0px 20px', fontWeight: 'bold'}}>
						Customer declined insurance. Customer to use personal insurance as primary.
					</Text>

					<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '100%', marginTop: 10}}/>

					<Text style={{fontSize: 12, marginTop: 10, textAlign: 'left', width: '100%', padding: '0px 20px', fontWeight: 'bold'}}>
						I accept the Terms and Conditions applicable to this Rental Agreement without any exceptions or reservation.
					</Text>
					<Text style={{fontSize: 12, marginTop: 10, textAlign: 'left', width: '100%', padding: '0px 20px', fontWeight: 'bold'}}>
						Please sign to acknowledge you have read the above terms and agree to them.
					</Text>

					<View style={[pdfStyles.row, {padding: '0px 10px', marginTop: 20}]}>
						<Text style={[pdfStyles.headerText, {width: 80, textAlign: 'right', marginRight: 10}]}>Date:</Text>
						<View style={{width: '80%', border: `0.5px solid ${Colors.theme}`, backgroundColor: 'white', height: 30, display: 'flex', justifyContent: 'center'}} >
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>today-date-6</Text>
						</View>
					</View>

					<View style={[pdfStyles.row, {padding: '0px 10px', marginTop: 20}]}>
						<Text style={[pdfStyles.headerText, {width: 80, textAlign: 'right', marginRight: 10}]}>Signature:</Text>
						<View style={{width: '80%', border: `0.5px solid ${Colors.theme}`, backgroundColor: 'white', height: 60, display: 'flex', justifyContent: 'center'}} >
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-signature-6</Text>
						</View>
					</View>

				</View>

				<View style={[pdfStyles.row, {marginTop: 20}]}>
					<View style={{flexDirection: 'row', justifyContent: 'center', width: '100%'}}>
						<Image src={Logo} style={{width: 160, height: 100}}/>
					</View>

					<View style={[pdfStyles.row, {justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 20}]}>
						<Text style={{fontSize: 14, fontWeight: 'bold'}}>Initial Here: </Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5, marginRight: 10}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>initials-6</Text>
						</View>
					</View>
				</View>

				{renderFooter()}
			</View>
		)
	}

	const renderPage7 = () => {
		return (
			<View style={pdfStyles.section}>
				<Text style={{width: '65%', textAlign: 'center', margin: '0 auto', fontSize: 20, fontWeight: 'bold'}}>
					THERE WILL BE A <Tspan style={{color: 'red'}}>$2500</Tspan> FINE IF THE FOLLOWING OCCUR:
				</Text>

				<View style={[pdfStyles.row, {marginTop: 20}]}>
					<Text style={{marginRight: 10}}>{`\u2022`}</Text>
					<Text style={{fontSize: 14}}>EVIDENCE OF ALCOHOL USAGE IN THE VEHICLE</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 20}]}>
					<Text style={{marginRight: 10}}>{`\u2022`}</Text>
					<Text style={{fontSize: 14}}>EVIDENCE OF MARIJUANA, COGARETTES, CAPING OF ANY OTHER FORM OF SMOKING IN THE VEHICLE</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 20}]}>
					<Text style={{marginRight: 10}}>{`\u2022`}</Text>
					<Text style={{fontSize: 14}}>VEHICLE SMELLS LIKE SMOKE OR MARIJUANA</Text>
				</View>


				<View style={[pdfStyles.row, {marginTop: 20}]}>
					<Text style={{marginRight: 10}}>{`\u2022`}</Text>
					<Text style={{fontSize: 14}}>AIR FRESHENER OR ANY ODOR IS APPLIED</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 20}]}>
					<Text style={{marginRight: 10}}>{`\u2022`}</Text>
					<Text style={{fontSize: 14}}>VEHICLE IS USED ON TRACK</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 20}]}>
					<Text style={{marginRight: 10}}>{`\u2022`}</Text>
					<Text style={{fontSize: 14}}>VEHICLE IS ABUSED AND DRIVEN RECKLESSLY</Text>
				</View>

				<View style={[pdfStyles.signatureBox, {marginTop: 20, height: 150}]}>
					<View style={[pdfStyles.row, {alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 120, textAlign: 'right', marginRight: 20}]}>PRINT NAME:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginTop: 20}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-name-7</Text>
						</View>
					</View>

					<View style={[pdfStyles.row, {marginTop: 20, alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 120, textAlign: 'right', marginRight: 20}]}>SIGNATURE:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginTop: 20}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-signature-7</Text>
						</View>
					</View>

					<View style={[pdfStyles.row, {marginTop: 20, alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 120, textAlign: 'right', marginRight: 20}]}>DATE:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '50%', marginTop: 20}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>today-date-7</Text>
						</View>
					</View>
				</View>

				<View style={[pdfStyles.row, {marginTop: 200}]}>
					<View style={{flexDirection: 'row', justifyContent: 'center', width: '100%'}}>
						<Image src={Logo} style={{width: 160, height: 100}}/>
					</View>

					<View style={[pdfStyles.row, {justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 20}]}>
						<Text style={{fontSize: 14, fontWeight: 'bold'}}>Initial Here: </Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5, marginRight: 10}}>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>initials-7</Text>
						</View>
					</View>
				</View>

				{renderFooter()}
			</View>
		)
	}


	return (
		<PDFViewer style={{height: '100%', width: '100%'}}>
			<Document>
				<Page size="A4" style={pdfStyles.page}>
					{renderPage1()}
				</Page>

				<Page size="A4" style={pdfStyles.page}>
					{renderPage2(true)}
				</Page>

				<Page size="A4" style={pdfStyles.page}>
					{renderPage3()}
				</Page>

				<Page size="A4" style={pdfStyles.page}>
					{renderPage4()}
				</Page>

				<Page size="A4" style={pdfStyles.page}>
					{renderPage5()}
				</Page>

				<Page size="A4" style={pdfStyles.page}>
					{renderPage6()}
				</Page>

				<Page size="A4" style={pdfStyles.page}>
					{renderPage7()}
				</Page>

				{/*<Page size="A4" style={pdfStyles.page}>*/}
				{/*	{renderPage2(false)}*/}
				{/*</Page>*/}

				{/*<Page size="A4" style={pdfStyles.page}>*/}
				{/*	{renderPage9()}*/}
				{/*</Page>*/}
			</Document>
		</PDFViewer>
	)
}

export default CustomPDF
