import React, {useState, useEffect, useRef} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import Styles from './styles/Vehicles.module.scss'
import CustomButtonContained from '../../components/general/CustomButtonContained'
import Colors from '../../config/colors'
import CustomTextField from '../../components/general/CustomTextField'
import {Add} from '@mui/icons-material'
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {firebaseStorage} from '../../services/firebase'
import {ref, uploadBytesResumable, getDownloadURL} from "firebase/storage"
import CustomLoaderSpinner from '../../components/general/CustomLoaderSpinner'
import {useDispatch, useSelector} from 'react-redux'
import CustomTextFieldNew from '../../components/general/CustomTextFieldNew';
import {addVehicleAndConfirm, updateNewVehicleStatus, vehiclesMapSelector, setUpdatedStatus, updateVehicleAndConfirm, updateVehicle} from '../../redux/slices/vehiclesSlice'
import {API_CALL_STATUS, MAINTENANCE_STATUS} from '../../metadata/enums'
import PageHeader from "../../components/general/PageHeader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import moment from "moment";
import CustomSelect from "../../components/general/CustomSelect";
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import {useNavigate} from 'react-router-dom'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactS3Client from '../../services/S3Client';
import {LOGGER} from '../../utils/Logger'
import Config from '../../config'
import Constants from '../../config/contants'
import BackArrow from "../../assets/logos/backArrow.svg";
import SearchIcon from "../../assets/logos/searchIcon.png";
import Fuse from "fuse.js";
import Helpers from "../../utils/helpers";
import {fetchAllMaintenances, updateMaintenanceToUpdate} from "../../redux/slices/vehiclesSlice";
import UploadIcon from '../../assets/logos/uploadIcon.svg';
import GlobalStyles from '../GeneralStyles.module.scss'
import CONSTANTS from "../../config/contants";
import CustomModal from "../../components/general/CustomModal";
import {DateRangePicker} from "react-date-range";

function getEmptyMaintenanceObject(){
	return {
		start_date: null,
		end_date: null,
		description: '',
		cost: 0,
		status: null
	}
}

const Vehicles = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()


	const token = useSelector(state => state.auth.token)
	const vehiclesStore = useSelector(state => state.vehicles)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))


	const [showLoading, setShowLoading] = useState(false)
	const [showSuccessMessage, setShowSuccessMessage] = useState(null)
	const [successMessage, setSuccessMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [searchString, setSearchString] = useState('')
	const [fuse, setFuse] = useState(null)
	const [searchResults, setSearchResults] = useState([])
	const [tab, setTab] = useState(1)
	const [active, setActive] = useState([])
	const [inactive, setInactive] = useState([])

	useEffect(() => {

		if(vehiclesStore.status === API_CALL_STATUS.SUCCEEDED) {
			setShowLoading(false)

			if(vehiclesStore.new_vehicle) {
				//a new vehicle was added. Show success message
				setShowSuccessMessage(true)
				setSuccessMessage('Successfully added the vehicle')
				dispatch(updateNewVehicleStatus(false))
			}

			if(vehiclesStore.updated_vehicle) {
				setShowSuccessMessage(true)
				setSuccessMessage('Successfully updated vehicle details')
				dispatch(setUpdatedStatus(false))
				dispatch(updateVehicle(null))
			}


			let active = []
			let inActive = []
			vehiclesStore.vehicles.forEach(vehicle => {
				if(vehicle.status === false)
					inActive.push(vehicle)
				else
					active.push(vehicle)
			})
			setActive(active)
			setInactive(inActive)
			let allVehicles = active.concat(inActive)

			let temp = new Fuse(allVehicles, {
				keys: ['make', 'id']
			})
			setFuse(temp)
		} else if(vehiclesStore.status === API_CALL_STATUS.FAILED) {
			setShowError(true)
			setShowLoading(false)
			setErrorMessage('Something went wrong')
		} else {
			setShowLoading(true)
		}


	},[vehiclesStore])

	const onSearchStringChanged = (text) => {
		setSearchString(text)
		if(searchString.length === 0)
			return
		let results = fuse.search(text)
		let array = results.map(temp => temp.item)
		setSearchResults(array)
	}

	const renderStatusSelect = (index, vehicle) => {
		if(vehicle.id === 'Rolls-Royce Cullinan [Black/Orange]')
			console.log('status select', vehicle)
		const OPTIONS = [{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]
		let value = vehicle.status === false ? OPTIONS[1] : OPTIONS[0]
		return (
			<div style={{width: '95%', display: 'flex', flexDirection: 'row', marginLeft: 10}}>
				<CustomSelect
					value={value}
					options={OPTIONS}
					onChange={(value) => {
						let temp = JSON.parse(JSON.stringify(vehicle))
						temp['status'] = value.value
						dispatch(updateVehicleAndConfirm({token, data: temp}))
					}}
				/>
			</div>

		)
	}

	const onVehicleSelected = (vehicle) => {
		dispatch(updateVehicle(vehicle))
		navigate('/home/update-vehicle')
	}

	const renderVehicleRow = (vehicle,index) => {
		return (
			<div
				className={Styles.vehicleRow} key={vehicle.id} style={{borderBottom: `1px solid ${Colors.tableLineColor}`, cursor: 'pointer'}}>
				<div onClick={() => onVehicleSelected(vehicle)} style={{width: '28%', marginRight: 10}} className={Styles.tableEntryWrapper}>
					<img width={'100%'} height={'100%'} src={vehicle.pictureUrl} style={{borderRadius: 10}}/>
				</div>
				<div style={{width: '24%', marginRight: 10}} className={Styles.tableEntryWrapper}>
					<p onClick={() => onVehicleSelected(vehicle)} className={Styles.tableEntry}>{vehicle.make}</p>
				</div>
				<div style={{width: '10%', marginRight: 10}} className={Styles.tableEntryWrapper}>
					<p onClick={() => onVehicleSelected(vehicle)} className={Styles.tableEntry}>${vehicle.dailyPrice}</p>
				</div>
				<div style={{width: '20%', marginRight: 10}} className={Styles.tableEntryWrapper}>
					<div onClick={() => onVehicleSelected(vehicle)} className={Styles.tableEntry} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
						{vehicle.type.map(type => <p key={type} style={{backgroundColor: Colors.buttonTheme, marginRight: 6, borderRadius: 10, padding: '0px 10px', color: Colors.tertiaryTextColor}}>{type}</p>)}
					</div>
				</div>
				<div style={{width: '18%'}} className={Styles.tableEntryWrapper}>
					{renderStatusSelect(index, vehicle)}
				</div>


			</div>
		)

	}

	const renderFilterRow = () => {
		return (
			<div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 40}}>
				<div style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}>
					<div style={{
						width: '40%',
						backgroundColor: Colors.theme,
						borderRadius: 10,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						paddingLeft: 20
					}}>
						<img src={SearchIcon} style={{height: 16, width: 16}}/>
						<CustomTextFieldNative
							containerStyle={{width: '100%'}}
							height={36}
							label={'search'}
							placeholder={'Search vehicles'}
							value={searchString}
							onChange={onSearchStringChanged}/>
					</div>

					<div style={{width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
						<Tab tab={tab} onChange={(value) => {
							if (value === 3) {
								//get maintenance data
								dispatch(fetchAllMaintenances({token}))
							}
							setTab(value)
						}}/>
					</div>
				</div>
			</div>
		)
	}

	const getMaintenanceStatusText = (value) => {
		switch(value) {
			case MAINTENANCE_STATUS.CREATED:
				return 'Open'
			case MAINTENANCE_STATUS.COMPLETED:
				return 'Completed'
		}
	}

	const renderMaintenanceList = () => {
		if(vehiclesStore.status !== API_CALL_STATUS.SUCCEEDED)
			return

		let maintenancesList = vehiclesStore.maintenances
		let elements = maintenancesList.map((maintenance, index) => {
			return (
				<div
					onClick={() => {
						dispatch(updateMaintenanceToUpdate(maintenance))
						navigate('/home/update-maintenance')
					}}
					className={Styles.maintenanceRow} key={index.toString()} style={{borderBottom: `1px solid ${Colors.tableLineColor}`, cursor: 'pointer'}}>
					<div style={{width: '25%', marginRight: 10}}>
						<p className={Styles.tableEntry}>{vehiclesMap[maintenance.vehicle_id]?.make}</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableEntry}>{moment(maintenance.start_date).format('MM/DD/YY')}</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableEntry}>{moment(maintenance.end_date).format('MM/DD/YY')}</p>
					</div>
					<div style={{width: '30%', marginRight: 10}}>
						<p className={Styles.tableEntry}>{maintenance.description}</p>
					</div>
					<div style={{width: '10%'}}>
						<p className={Styles.tableEntry}>$ {maintenance.cost}</p>
					</div>
					<div style={{width: '15%'}}>
						<p className={Styles.tableEntry}>{getMaintenanceStatusText(maintenance.status)}</p>
					</div>

				</div>
			)
		})

		return (
			<div className={Styles.vehiclesListContainer}>
				<div style={{height: 60, display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid ${Colors.tableLineColor}`}}>
					<div style={{width: '25%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Vehicle</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Start</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableHeader}>End</p>
					</div>
					<div style={{width: '30%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Description</p>
					</div>
					<div style={{width: '10%'}}>
						<p className={Styles.tableHeader}>Cost</p>
					</div>
					<div style={{width: '15%'}}>
						<p className={Styles.tableHeader}>Status</p>
					</div>
				</div>

				{maintenancesList.length > 0 ?
					elements
					:
					<p className={GlobalStyles.darkText} style={{marginTop: 30, width: '100%', textAlign: 'center'}}>There are no entries to show</p>
				}

			</div>

		)
	}

	const renderVehiclesList = () => {
		let vehicles = searchString && searchString.length > 0 ? searchResults : tab === 1 ? active : inactive
		let vehicleElements = vehicles.map((vehicle,index) => renderVehicleRow(vehicle,index))

		return (
			<div className={Styles.vehiclesListContainer}>
				<div className={Styles.vehicleRow}>
					<div style={{width: '28%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Image</p>
					</div>
					<div style={{width: '24%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Vehicle</p>
					</div>
					<div style={{width: '10%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Daily Price</p>
					</div>
					<div style={{width: '20%', marginRight: 10}}>
						<p className={Styles.tableHeader}>Type</p>
					</div>
					<div style={{width: '18%'}}>
						<p className={Styles.tableHeader}>Status</p>
					</div>
				</div>
				{vehicleElements}
			</div>
		)
	}

	const onAddNewPressed = () => {
		if(tab === 3)
			navigate('/home/new-maintenance')
		else
			navigate('/home/new-vehicle')
	}


	const renderContent = () => {
		if(Object.keys(vehiclesMap).length === 0) {
			return (
				<div className={Styles.container}>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
						<PageHeader header={'Vehicles'} subHeader={`${moment().format('dddd, MMM Do')}`}/>
						<CustomButtonContained
							style={{position: 'absolute', right: 0}}
							text={tab === 3 ? '+ Add New Maintenance Entry' : '+ Add New Vehicle'}
							onClick={onAddNewPressed}
						/>
					</div>

					<p className={GlobalStyles.darkText} style={{width: '100%', marginTop: 40, textAlign: 'center'}}>There are no vehicles in the system yet</p>
				</div>
			)
		}

		return (
			<div className={Styles.container}>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
					<PageHeader header={'Vehicles'} subHeader={`${moment().format('dddd, MMM Do')}`}/>
						<CustomButtonContained
							style={{position: 'absolute', right: 0}}
							text={tab === 3 ? '+ Add New Maintenance Entry' : '+ Add New Vehicle'}
							onClick={onAddNewPressed}
						/>
				</div>

				{renderFilterRow()}
				{
					tab === 3 ?
						renderMaintenanceList()
						:
						renderVehiclesList()
				}


			</div>
		)
	}

	if(showLoading) {
		return (
			<div className={GeneralStyles.container} style={{height: '90vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
			<PositionedSnackbar
				onClose={() => {
					setErrorMessage('')
					setShowError(false)
					setShowSuccessMessage(false)
					setSuccessMessage('')
				}}
				openFlag={showError || showSuccessMessage}
				message={showSuccessMessage ? successMessage : errorMessage}
				severity={showError ? 'error' : 'success'}
			/>

			{renderContent()}
		</div>
	)
}

const Tab = ({tab, onChange}) => {
	return (
		<div style={{width: '70%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme, borderColor: Colors.theme}}>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Active</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 2 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(2)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Inactive</p>
			</div>
			<div
				style={{height: 30, cursor: 'pointer',width: '50%', backgroundColor: tab === 3 ? Colors.tabButtonTheme : 'transparent', display: 'flex', flexDirection: 'row', justifyContent: 'center',borderRadius: 5, alignItems: 'center'}}
				onClick={() => onChange(3)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Maintenance</p>
			</div>
		</div>
	)
}

export default Vehicles
