import {addUser, addMongoUser} from "../redux/slices/userSlice";
import store from '../redux/store'
import Config from '../config'

const functions = {
	updateUserStore: function (firebaseUser) {
		if(firebaseUser) {
			store.dispatch(addUser({
				email: firebaseUser.email,
				uid: firebaseUser.uid
			}))
		}
	},

	updateMongoUserStore: function (user) {
		if(user) {
			store.dispatch(addMongoUser(user))
		}
	},

	validateEmail: function (email) {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	},

	getKeyByValue: (object, value) => {
		return Object.keys(object).find(key => object[key] === value);
	},

	formatPhoneNumber: (phoneNumberString) => {
		let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
		let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3]
		}
		return null

	},

	checkPhoneNumberFormat: (value) => {
		// if input value is falsy eg if the user deletes the input, then just return
		if (!value) return value;

		// clean the input for any non-digit values.
		const phoneNumber = value.replace(/[^\d]/g, '');

		// phoneNumberLength is used to know when to apply our formatting for the phone number
		const phoneNumberLength = phoneNumber.length;

		// we need to return the value with no formatting if its less then four digits
		// this is to avoid weird behavior that occurs if you  format the area code to early

		if (phoneNumberLength < 4) return phoneNumber;

		// if phoneNumberLength is greater than 4 and less the 7 we start to return
		// the formatted number
		if (phoneNumberLength < 7) {
			return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
		}

		// finally, if the phoneNumberLength is greater then seven, we add the last
		// bit of formatting and return it.
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
			3,
			6
		)}-${phoneNumber.slice(6, 10)}`;
	},

	checkAccess: function(mongoUser, rolesMap, permissionKey) {
		if(Config.WHITELIST_UIDS[mongoUser?.uid]) {
			return true
		} else {
			return rolesMap[mongoUser.role]['permissions'][permissionKey]
		}
	},

	getRolesMap: (state) => {
		let map = {}
		state.settings.forEach(setting => {
			if(setting._id === 'permissions') {
				setting.roles.forEach(role => map[role.id] = role)
			}
		})
		return map
	},

	getRentalsPricingObject: function(reservation, vehicle, priceSettings) {
		return {
			deposit: reservation?.pricing ? reservation?.pricing?.deposit : vehicle?.deposit || 0,
			mileage_overage_price: reservation?.pricing ? reservation?.pricing.mileage_overage_price : vehicle?.mileage_overage_price || 0,
			service_tax: reservation?.pricing ? reservation?.pricing?.service_tax : priceSettings?.service_tax['rentals'] || 0,
			sales_tax: reservation?.pricing ? reservation?.pricing?.sales_tax : priceSettings?.sales_tax || 0,
			mileage_allowance: reservation?.pricing?.mileage_allowance ? reservation?.pricing?.mileage_allowance : reservation?.check_in?.mileage_allowance || vehicle?.mileage_allowance || 0,
			location_fee: reservation?.pricing ? reservation?.pricing?.location_fee || 0: priceSettings?.location_fee || 0,
			card_fee: reservation?.pricing ? reservation?.pricing?.card_fee || 0: priceSettings?.card_fee || 0,
			fuel_charge: reservation?.pricing ? reservation?.pricing?.fuel_charge || 0 : vehicle?.fuel_charge || 0,
			extras: reservation?.pricing?.extras || [], //array of objects {name: '', price: ''}
			discount: reservation?.pricing?.discount || {},
			mileage_package: reservation?.pricing?.mileage_package || {} //this will contain {value: 100 miles, price: $200}
		}
	},

	getChauffeurPricingObject: function(reservation, vehicle, priceSettings) {
		return {
			deposit: reservation?.pricing ? reservation?.pricing?.deposit : vehicle?.deposit || 0,
			mileage_overage_price: reservation?.pricing ? reservation?.pricing?.mileage_overage_price : vehicle?.mileage_overage_price || 0,
			service_tax: reservation?.pricing ? reservation?.pricing?.service_tax : priceSettings?.service_tax['chauffeur'] || 0,
			mileage_allowance: reservation?.pricing?.mileage_allowance ? reservation?.pricing?.mileage_allowance : reservation?.check_in?.mileage_allowance || vehicle?.mileage_allowance || 0,
			card_fee: reservation?.pricing ? reservation?.pricing?.card_fee || 0: priceSettings?.card_fee || 0,
			extras: reservation?.pricing?.extras || [], //array of objects {name: '', price: ''}
			discount: reservation?.pricing?.discount || {},
			mileage_package: reservation?.pricing?.mileage_package || {} //this will contain {value: 100 miles, price: $200}
		}
	}

}

export default functions
