import React from 'react'
import Colors from '../../config/colors'
import {Document, Page, PDFViewer, StyleSheet, Text, View, Image, Tspan} from "@react-pdf/renderer";
import CarImage from '../../assets/images/car.png'
import Logo from '../../assets/images/new_logo.png'
import TNCStrings from '../../metadata/TNC'
import Helpers from '../../utils/helpers'
import CalendarIcon from '../../assets/logos/calendar.png'
import PinIcon from '../../assets/logos/pin.png'


const BACKGROUND_COLOR = '#5481CB'
const TEXT_COLOR = '#23395D'

const pdfStyles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	sectionHeader: {
		width: '100%',
		backgroundColor: BACKGROUND_COLOR,
		height: 26,
		marginTop: 10,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	section: {
		padding: '10px 20px',
		flexDirection: 'column',
		color: TEXT_COLOR,
		height: '100%',
		width: '100%'
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%'
	},
	headerText: {
		fontSize: 13,
		width: '100%',
		color: TEXT_COLOR
	},
	divHeader: {
		height: 25,
		width: '100%',
		backgroundColor: BACKGROUND_COLOR,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		alignItems: 'center',
		justifyContent: 'center'
	},
	detailsText: {
		fontSize: 12,
		color: TEXT_COLOR
	},
	bigHeaderText: {
		fontSize: 22,
		color: BACKGROUND_COLOR
	},
	reservationBox: {
		width: '100%',
		height: 120,
		borderRadius: 10,
		border: `1px solid ${Colors.theme}`,
		margin: '0 auto',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#f0f0f0'
	},
	signatureBoxBig: {
		width: '100%',
		height: 50,
		border: `0.5px solid ${TEXT_COLOR}`,
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: 'white',
		marginTop: 5,
		padding: 10
	},
	dateBox: {
		width: '100%',
		height: 20,
		border: `0.5px solid ${TEXT_COLOR}`,
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: 'white',
		marginTop: 5,
		padding: 10
	},
	checkbox: {
		height: 10,
		width: 10,
		border: `1px solid ${BACKGROUND_COLOR}`
	},
	signatureBox: {
		width: '100%',
		height: 100,
		borderBottomRightRadius: '25%',
		borderTopLeftRadius: '25%',
		border: `1px solid ${Colors.theme}`,
		margin: '0 auto',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: 10
	},
	preTripBox: {
		width: '100%',
		borderBottomRightRadius: '25%',
		borderTopLeftRadius: '25%',
		border: `1px solid ${Colors.theme}`,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '20px 10px'
	},
	clientInfoBox: {
		borderBottomRightRadius: '50%',
		borderTopLeftRadius: '50%',
		height: 30,
		backgroundColor: BACKGROUND_COLOR,
		width: '100%',
		marginTop: 20,
		flexDirection: 'row',
		alignItems: 'center'
	},
	vehicleTableBox: {
		width: '100%',
		borderRadius: 10,
		margin: '0 auto',
		flexDirection: 'column',
		marginTop: 40
	},
	divHeader2: {
		height: 25,
		width: '100%',
		backgroundColor: BACKGROUND_COLOR,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		flexDirection: 'row',
		alignItems: 'center',
	},
	tableRow : {
		height: 25,
		borderLeft: `0.5px solid ${Colors.theme}`,
		borderRight: `0.5px solid ${Colors.theme}`,
		borderBottom: `0.5px solid ${Colors.theme}`,
		flexDirection: 'row',
		width: '100%'
	},
	paymentCheckbox: {
		height: 10,
		width: 10,
		border: `1px solid ${Colors.theme}`,
		borderRadius: '50%'
	},

	footer: {
		position: 'absolute',
		bottom: 10,
		left: 20,
		fontSize: 12
	}
});



const PdfTemplate = () => {

	const renderFooter = () => {
		// return <Text style={pdfStyles.footer} fixed>©2023-2024 DDE Rentals, LLC</Text>
		return <Text style={[pdfStyles.footer,{color: 'white'}]} fixed>footer</Text>
	}

	const renderPaymentInfo = () => {
		return (
			<View style={{width: '100%'}}>
				<View style={[pdfStyles.sectionHeader, {justifyContent: 'center', alignItems: 'center'}]}>
					<Text style={[pdfStyles.headerText, {textAlign: 'center',color: 'white'}]}>Payment Information</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%'}]}>RENTAL RATE</Text>
					<Text style={[pdfStyles.headerText, {width: '40%', color: 'white'}]}>pi-r1-c2</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>pi-r1-c3</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 5, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%', paddingLeft: 4}]}>ADD-ONS</Text>
					<Text style={[pdfStyles.headerText, {width: '40%', color: 'white'}]}>add-r1-c2</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>add-r1-c3</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 5, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%', paddingLeft: 4}]}></Text>
					<Text style={[pdfStyles.headerText, {width: '40%', color: 'white'}]}>add-r2-c2</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>add-r2-c3</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 5, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%', paddingLeft: 4}]}></Text>
					<Text style={[pdfStyles.headerText, {width: '40%', color: 'white'}]}>add-r3-c2</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>add-r3-c3</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 5, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%', paddingLeft: 4}]}>DISCOUNTS</Text>
					<Text style={[pdfStyles.headerText, {width: '40%', color: 'white'}]}>dis-c2</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>dis-c3</Text>
				</View>

				<View style={{borderBottomWidth: 0.5, borderBottomColor: TEXT_COLOR, marginTop: 10, width: '100%', alignSelf: 'center'}}/>

				<View style={[pdfStyles.row, {marginTop: 10, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%'}]}>SUB-TOTAL</Text>
					<Text style={[pdfStyles.headerText, {width: '40%'}]}></Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>sub-total-c3</Text>
				</View>
				<View style={[pdfStyles.row, {marginTop: 5, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%', paddingLeft: 4}]}>SALES TAX</Text>
					<Text style={[pdfStyles.headerText, {width: '40%', color: 'white'}]}>sales-tax-c2</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>sales-tax-c3</Text>
				</View>
				<View style={[pdfStyles.row, {marginTop: 5, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%', paddingLeft: 4}]}>SERVICE FEE</Text>
					<Text style={[pdfStyles.headerText, {width: '40%', color: 'white'}]}>service-fee-c2</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>service-fee-c3</Text>
				</View>

				<View style={{borderBottomWidth: 0.5, borderBottomColor: TEXT_COLOR, marginTop: 10, width: '100%', alignSelf: 'center'}}/>

				<View style={[pdfStyles.row, {marginTop: 10, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%'}]}>TOTAL</Text>
					<Text style={[pdfStyles.headerText, {width: '40%'}]}></Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>r-total-c3</Text>
				</View>
				<View style={[pdfStyles.row, {marginTop: 5, paddingLeft: 5}]}>
					<Text style={[pdfStyles.headerText, {width: '40%', paddingLeft: 4}]}>SECURITY DEPOSIT</Text>
					<Text style={[pdfStyles.headerText, {width: '40%'}]}>Refundable Amount</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'right', color: 'white'}]}>deposit-c3</Text>
				</View>

				<View style={{borderBottomWidth: 0.5, borderBottomColor: TEXT_COLOR, marginTop: 10, width: '100%', alignSelf: 'center'}}/>
				<View style={{borderBottomWidth: 0.5, borderBottomColor: TEXT_COLOR, marginTop: 2, width: '100%', alignSelf: 'center'}}/>
			</View>
		)
	}

	const renderRentalInfo = () => {
		return (
			<View style={{width: '100%'}}>
				<View style={[pdfStyles.sectionHeader, {justifyContent: 'center', alignItems: 'center'}]}>
					<Text style={[pdfStyles.headerText, {textAlign: 'center',color: 'white'}]}>Rental Information</Text>
				</View>

				<View style={[pdfStyles.row, {marginTop: 10}]}>
					<View style={[pdfStyles.row, {width: '50%'}]}>
						<Image src={CalendarIcon} style={{height: 18, width: 18}}/>
						<Text style={[pdfStyles.headerText, {width: '20%', marginLeft: 5}]}>Pick-Up:</Text>
						<Text style={[pdfStyles.detailsText, {width: '80%', color: 'white'}]}>pickup-date-time</Text>
					</View>

					<View style={[pdfStyles.row, {width: '50%', paddingLeft: 4}]}>
						<Text style={[pdfStyles.headerText, {width: '40%'}]}>Vehicle:</Text>
						<Text style={[pdfStyles.detailsText, {width: '60%', textAlign: 'right', color: 'white'}]}>vehicle-name</Text>
					</View>

				</View>

				<View style={[pdfStyles.row, {marginTop: 12}]}>
					<View style={[pdfStyles.row, {width: '50%'}]}>
						<Image src={CalendarIcon} style={{height: 18, width: 18}}/>
						<Text style={[pdfStyles.headerText, {width: '20%', marginLeft: 5}]}>Return:</Text>
						<Text style={[pdfStyles.detailsText, {width: '80%', color: 'white'}]}>return-date-time</Text>
					</View>

					<View style={[pdfStyles.row, {width: '50%', paddingLeft: 4}]}>
						<Text style={[pdfStyles.headerText, {width: '40%'}]}>Ending Mileage:</Text>
						<Text style={[pdfStyles.detailsText, {width: '60%',textAlign: 'right', color: 'white'}]}>ending-mileage</Text>
					</View>

				</View>

				<View style={[pdfStyles.row, {marginTop: 12}]}>
					<View style={[pdfStyles.row, {width: '50%'}]}>
						<Image src={CalendarIcon} style={{height: 18, width: 18}}/>
						<Text style={[pdfStyles.headerText, {width: '20%', marginLeft: 5}]}>Total:</Text>
						<Text style={[pdfStyles.detailsText, {width: '80%', color: 'white'}]}>rental-info-total-string</Text>
					</View>

					<View style={[pdfStyles.row, {width: '50%', paddingLeft: 4}]}>
						<Text style={[pdfStyles.headerText, {width: '40%'}]}>Ending Fuel:</Text>
						<Text style={[pdfStyles.detailsText, {width: '60%', textAlign: 'right', color: 'white'}]}>ending-fuel</Text>
					</View>

				</View>

				<View style={{borderBottomWidth: 0.5, borderBottomColor: TEXT_COLOR, marginTop: 10, width: '100%', alignSelf: 'center'}}/>

				<View style={[pdfStyles.row, {paddingLeft: 4, marginTop: 12}]}>
					<Image src={PinIcon} style={{height: 18, width: 18}}/>
					<Text style={[pdfStyles.headerText, {width: '25%', marginLeft: 5}]}>Start Location:</Text>
					<Text style={[pdfStyles.detailsText, {width: '75%', color: 'white'}]}>starting-location</Text>
				</View>

				<View style={[pdfStyles.row, {paddingLeft: 4, marginTop: 12}]}>
					<Image src={PinIcon} style={{height: 18, width: 18}}/>
					<Text style={[pdfStyles.headerText, {width: '25%', marginLeft: 5}]}>End Location:</Text>
					<Text style={[pdfStyles.detailsText, {width: '75%', color: 'white'}]}>ending-location</Text>
				</View>


			</View>
		)
	}

	const renderBusinessAndClientInfo = () => {
		return (
			<View style={{width: '100%'}}>
				<View style={[pdfStyles.sectionHeader, {borderTopLeftRadius: '50%', borderTopRightRadius: '50%'}]}>
					<View style={{width: '50%', borderRightWidth: 1, borderRightColor: 'white', height: '100%', display: 'flex', justifyContent: 'center'}}>
						<Text style={[pdfStyles.headerText, {textAlign: 'center',color: 'white'}]}>Business Information</Text>
					</View>
					<View style={{width: '50%', height: '100%', display: 'flex', justifyContent: 'center'}}>
						<Text style={[pdfStyles.headerText, {textAlign: 'center', color: 'white'}]}>Client Information</Text>
					</View>
				</View>

				<View style={pdfStyles.row}>
					<View style={{width: '50%', paddingLeft: 5}}>
						<View style={[pdfStyles.row, {marginTop: 10}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Name:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>business-name</Text>
						</View>

						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Address:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>business-address</Text>
						</View>
						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>City/State:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>business-city-state</Text>
						</View>
						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Zip Code:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>business-zip</Text>
						</View>
						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Phone:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>business-phone</Text>
						</View>

						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Email:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>business-email</Text>
						</View>

					</View>

					<View style={{width: '50%', paddingLeft: 5}}>
						<View style={[pdfStyles.row, {marginTop: 10}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Name:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>client-name</Text>
						</View>

						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Address:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>client-address</Text>
						</View>
						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>City/State:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>client-city-state</Text>
						</View>
						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Zip Code:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>client-zip</Text>
						</View>
						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Phone:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>client-phone</Text>
						</View>

						<View style={[pdfStyles.row, {marginTop: 6}]}>
							<Text style={[pdfStyles.detailsText, {width: '23%'}]}>Email:</Text>
							<Text style={[pdfStyles.detailsText, {marginLeft: 5,color: 'white'}]}>client-email</Text>
						</View>

					</View>
				</View>
			</View>
		)
	}

	const renderSignatureBox = () => {
		return (
			<View style={{width: '100%'}}>
				<View style={[pdfStyles.sectionHeader, {justifyContent: 'center', alignItems: 'center'}]}>
					<Text style={[pdfStyles.headerText, {textAlign: 'center',color: 'white'}]}>Signature</Text>
				</View>

				<Text style={[pdfStyles.detailsText, {marginTop: 10}]}>I accept all Terms and Conditions applicable to this rental agreement without any exception or reservation.</Text>

				<View style={[pdfStyles.row,{marginTop: 10, alignItems: 'flex-end'}]}>
					<View style={{width: '50%', marginRight: 5}}>
						<Text style={[pdfStyles.detailsText]}>Date:</Text>
						<View style={pdfStyles.dateBox}>
							<Text style={[pdfStyles.detailsText, {color: 'white'}]}>today-date</Text>
						</View>
					</View>

					<View style={{width: '50%', marginLeft: 5}}>
						<Text style={[pdfStyles.detailsText]}>Signature:</Text>
						<View style={pdfStyles.signatureBoxBig}>
							<Text style={[pdfStyles.detailsText, {color: 'white'}]}>customer-signature</Text>
						</View>
					</View>
				</View>

			</View>
		)
	}

	const renderPage2 = (preTrip) => {
		return (
			<View style={pdfStyles.section}>
				<View style={[pdfStyles.row, {width: '100%', justifyContent: 'space-between'}]}>
					<Text style={[pdfStyles.bigHeaderText, {width: '50%'}]}>POST-TRIP INVOICE</Text>
					<Text style={[pdfStyles.headerText, {color: BACKGROUND_COLOR, width: '50%', textAlign: 'right'}]}>
						Rental Agreement #
						<Tspan style={[pdfStyles.headerText, {color: 'white'}]}>
							reserv-id
						</Tspan>
					</Text>
				</View>

				{renderBusinessAndClientInfo()}

				{renderRentalInfo()}

				{renderPaymentInfo()}

				{renderSignatureBox()}

				{renderFooter()}

			</View>

		)
	}

	return (
		<PDFViewer style={{height: '100%', width: '100%'}}>
			<Document>
				<Page size="A4" style={pdfStyles.page}>
					{renderPage2()}
				</Page>

			</Document>
		</PDFViewer>
	)
}

export default PdfTemplate
