import React from 'react'
import Colors from '../../config/colors'
import {Document, Page, PDFViewer, StyleSheet, Text, View, Image, Tspan} from "@react-pdf/renderer";
import CarImage from '../../assets/images/car.png'
import Logo from '../../assets/images/new_logo.png'
import TNCStrings from '../../metadata/TNC'
import Helpers from '../../utils/helpers'

const BACKGROUND_COLOR = '#C8B06A'

const pdfStyles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	section: {
		padding: '10px 20px',
		flexDirection: 'column',
		color: Colors.theme,
		height: '100%'
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%'
	},
	headerText: {
		fontSize: 14,
		fontWeight: 'bold',
		width: '100%'
	},
	divHeader: {
		height: 25,
		width: '100%',
		backgroundColor: BACKGROUND_COLOR,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		alignItems: 'center',
		justifyContent: 'center'
	},
	detailsText: {
		fontSize: 12
	},
	bigHeaderText: {
		fontSize: 22,
		color: BACKGROUND_COLOR
	},
	reservationBox: {
		width: '100%',
		height: 120,
		borderRadius: 10,
		border: `1px solid ${Colors.theme}`,
		margin: '0 auto',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#f0f0f0'
	},
	signatureBoxBig: {
		width: '100%',
		height: 330,
		borderRadius: 10,
		border: `1px solid ${Colors.theme}`,
		margin: '0 auto',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#f0f0f0',
		marginTop: 10
	},
	checkbox: {
		height: 10,
		width: 10,
		border: `1px solid ${BACKGROUND_COLOR}`
	},
	signatureBox: {
		width: '100%',
		height: 100,
		borderBottomRightRadius: '25%',
		borderTopLeftRadius: '25%',
		border: `1px solid ${Colors.theme}`,
		margin: '0 auto',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: 10
	},
	preTripBox: {
		width: '100%',
		borderBottomRightRadius: '25%',
		borderTopLeftRadius: '25%',
		border: `1px solid ${Colors.theme}`,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '20px 10px'
	},
	clientInfoBox: {
		borderBottomRightRadius: '50%',
		borderTopLeftRadius: '50%',
		height: 30,
		backgroundColor: BACKGROUND_COLOR,
		width: '100%',
		marginTop: 20,
		flexDirection: 'row',
		alignItems: 'center'
	},
	vehicleTableBox: {
		width: '100%',
		borderRadius: 10,
		margin: '0 auto',
		flexDirection: 'column',
		marginTop: 40
	},
	divHeader2: {
		height: 25,
		width: '100%',
		backgroundColor: BACKGROUND_COLOR,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		flexDirection: 'row',
		alignItems: 'center',
	},
	tableRow : {
		height: 25,
		borderLeft: `0.5px solid ${Colors.theme}`,
		borderRight: `0.5px solid ${Colors.theme}`,
		borderBottom: `0.5px solid ${Colors.theme}`,
		flexDirection: 'row',
		width: '100%'
	},
	paymentCheckbox: {
		height: 10,
		width: 10,
		border: `1px solid ${Colors.theme}`,
		borderRadius: '50%'
	},

	footer: {
		position: 'absolute',
		bottom: 10,
		left: 20,
		fontSize: 12
	}
});



const CustomPDFChauffeur = ({vehicle, customer, pickUpDateTime, returnDateTime, totalPrice, hourlyPrice, locations}) => {

	const renderFooter = () => {
		return <Text style={pdfStyles.footer} fixed>©2023-2024 DDE Rentals, LLC</Text>
	}

	const renderBusinessInfo = () => {
		return (
			<View style={{flexDirection: 'column', width: '50%', marginTop: 10}}>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Name: </Text>
					<Text style={[pdfStyles.detailsText, {fontWeight: 'bold', width: '100%'}]}>DDE Rentals, LLC</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Address: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>113 W Roosevelt Rd</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>City/State: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>Villa Park, IL</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Zip Code: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>60181</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Phone: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>(630) 754 - 7280</Text>
				</View>
				<View style={[pdfStyles.row, {marginBottom: 4}]}>
					<Text style={[pdfStyles.detailsText, {width: 80}]}>Email: </Text>
					<Text style={[pdfStyles.detailsText, {width: '100%'}]}>info@dderentals.com</Text>
				</View>
			</View>
		)
	}

	const renderPreTripBox = () => {
		return (
			<View style={{width: '48%'}}>
				<View style={pdfStyles.preTripBox}>
					<View style={[pdfStyles.row, {width: '100%', alignItems: 'flex-end', marginBottom: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 100, color: BACKGROUND_COLOR, textAlign: 'right', marginRight: 4}]}>DATE & TIME:</Text>
						<View style={{borderBottom: `1px solid ${BACKGROUND_COLOR}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{pickUpDateTime.format('MMM DD, YYYY hh:mm A')}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>pickup-date-n-time</Text>
						</View>
					</View>
					<View style={[pdfStyles.row, {width: '100%', alignItems: 'flex-end', marginBottom: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 100, color: BACKGROUND_COLOR, textAlign: 'right', marginRight: 4}]}>LOCATION:</Text>
						<View style={{borderBottom: `1px solid ${BACKGROUND_COLOR}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{locations[0].label}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>pickup-location</Text>
						</View>
					</View>
					<View style={[pdfStyles.row, {width: '100%', alignItems: 'flex-end', marginBottom: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 100, color: BACKGROUND_COLOR, textAlign: 'right', marginRight: 4}]}>VEHICLE:</Text>
						<View style={{borderBottom: `1px solid ${BACKGROUND_COLOR}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{vehicle.make}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>vehicle-make</Text>
						</View>
					</View>
				</View>
			</View>
		)
	}

	const renderClientInfo = () =>  {
		let address = customer.address?.label || ' , '
		let components = address.split(',')
		let cityPlace = ''
		components.forEach((each,i) => {
			if(i !== 0)
				cityPlace += each
		})
		return (
			<View>
				<View style={[pdfStyles.clientInfoBox]}>
					<Text style={[pdfStyles.headerText, {marginLeft: 15}]}>Client Information</Text>
				</View>

				<View style={{flexDirection: 'column', width: '90%'}}>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>Name:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{`${customer.fName} ${customer.lName}`}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-name</Text>
						</View>
					</View>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>Street Address:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{components[0]}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>street-address</Text>
						</View>
					</View>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>City, State, Zip:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{cityPlace}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>city-state-zip</Text>
						</View>
					</View>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>Phone:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{customer.phone}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-phone</Text>
						</View>
					</View>
					<View style={[pdfStyles.row, {alignItems: 'flex-end', marginTop: 10}]}>
						<Text style={[pdfStyles.detailsText, {width: 120, textAlign: 'right', marginRight: 10}]}>Email:</Text>
						<View style={{borderBottom: `1px solid ${Colors.theme}`, width: '90%'}}>
							{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{customer.email}</Text>*/}
							<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>customer-email</Text>
						</View>
					</View>
				</View>
			</View>
		)
	}

	const renderPaymentOptions = () => {
		return (
			<View style={{flexDirection: 'row', alignItems: 'center'}}>
				<View style={{flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
					<View style={[pdfStyles.paymentCheckbox, {marginRight: 4}]} />
					<Text style={{fontSize: 12}}>Cash</Text>
				</View>
				<View style={{flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
					<View style={[pdfStyles.paymentCheckbox, {marginRight: 4}]} />
					<Text style={{fontSize: 12}}>Card</Text>
				</View>
				<View style={{flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
					<View style={[pdfStyles.paymentCheckbox, {marginRight: 4}]} />
					<Text style={{fontSize: 12}}>Other</Text>
				</View>
			</View>
		)
	}

	const renderLocationsTable = (preTrip) => {
		return (
			<View style={pdfStyles.vehicleTableBox}>
				<View style={pdfStyles.divHeader2}>
					<Text style={[pdfStyles.headerText, {width: '60%', textAlign: 'center'}]}>
						CHAFFEUR LOCATIONS
					</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'center'}]}>
						TIME
					</Text>
					<Text style={[pdfStyles.headerText, {width: '20%', textAlign: 'center'}]}>
						Amount ($)
					</Text>
				</View>

				{
					locations.map((location,i) =>
						<View
							style={[pdfStyles.tableRow, {height: 34}]}>
							<View style={{width: '60%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
								{/*<Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{location.label}</Text>*/}
								<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>{`location-${i}`}</Text>
							</View>
							<View style={{width: '20%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
								{
									i === 0 ?
										// <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{pickUpDateTime.format('hh:mm A')}</Text>
										<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>pickup-datetime</Text>
										:
										i === 1 ?
											// <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{returnDateTime.format('hh:mm A')}</Text>
											<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>return-datetime</Text>
											:
											<Text></Text>
								}
							</View>
							<View style={{width: '20%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
								{/*{i === 0 && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{a}</Text>}*/}
								{i === 0 && <Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>hourly-price</Text>}
							</View>
						</View>
					)
				}

				{/*discount stuff*/}
				<View
					style={ [pdfStyles.tableRow, {height: 34}]}>
					<View style={{width: '60%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>dis-label-1</Text>
					</View>
					<View style={{width: '20%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>dis-p-c1-1</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>l-1-c1-p-2</Text>}*/}
					</View>
					<View style={{width: '20%', border: `0.2px solid ${Colors.theme}`, flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>dis-p-c2-1</Text>
						{/*{preTrip && <Text style={[pdfStyles.detailsText, {marginLeft: 5}]}>{dailyPrice * totalDays}</Text>}*/}
					</View>
				</View>



				<View style={[pdfStyles.row, {height: 25}]}>
					<Text style={{fontSize: 10, width: '60%', textAlign: 'center'}}>Please refer to rental agreement for all terms and conditions.</Text>
					<Text style={{fontSize: 12, width: '20%', textAlign: 'center'}}>SUBTOTAL</Text>
					<View style={{width: '20%', border: `0.5px solid ${Colors.theme}`, height: '100%', flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>sub-total</Text>
					</View>
				</View>

				<View style={[pdfStyles.row, {height: 25}]}>
					<Text style={{fontSize: 10, width: '60%', textAlign: 'center'}}>Payment is due by chauffeur date</Text>
					<Text style={{fontSize: 12, width: '20%', textAlign: 'center'}}>SERVICE FEE</Text>
					<View style={{width: '20%', border: `0.5px solid ${Colors.theme}`, height: '100%', flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>service-fee</Text>
					</View>
				</View>

				<View style={[pdfStyles.row, {height: 40}]}>
					<View style={{flexDirection: 'column', width: '60%', height: '100%', justifyContent: 'space-evenly'}}>
						<View style={[pdfStyles.row]}>
							<Text style={{fontSize: 12, marginRight: 10}}>Form of Payment: </Text>
							{renderPaymentOptions()}
						</View>
						<View style={{flexDirection: 'row'}}>
							<Text style={pdfStyles.detailsText}>Amount</Text>
							<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5}}/>
						</View>
					</View>
					<Text style={{fontSize: 12, width: '20%', textAlign: 'center'}}>TOTAL</Text>
					<View style={{width: '20%', border: `0.5px solid ${Colors.theme}`, height: '100%', flexDirection: 'row', alignItems: 'center'}}>
						<Text style={[pdfStyles.detailsText, {marginLeft: 5, color: 'white'}]}>total-price</Text>
					</View>
				</View>
			</View>
		)
	}

	const renderCustomerSignatureBox = () => {
		return (
			<View style={{marginTop: 40}}>
				<Text style={{fontWeight: 'bold', fontSize: 10}}>BY SIGNING YOU AGREE TO THE TOTAL STATED AMOUNT WITH ALL TERMS AND CONDITIONS</Text>
				<View style={[pdfStyles.signatureBox, {width: '80%', margin: 0, padding: 10}]}>
					<View style={[pdfStyles.row, {alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 140, textAlign: 'right', marginRight: 20}]}>Customer Signature:</Text>
						<View style={{width: '50%', marginTop: 20}}>
							<Text style={{fontSize: 14, color: 'white'}}>customer-signature-1</Text>
						</View>
					</View>

					<View style={[pdfStyles.row, {marginTop: 20, alignItems: 'flex-end'}]}>
						<Text style={[pdfStyles.headerText, {width: 140, textAlign: 'right', marginRight: 20}]}>Date:</Text>
						<View style={{width: '50%', marginTop: 20}}>
							<Text style={{fontSize: 14, color: 'white'}}>today-date</Text>
						</View>
					</View>
				</View>
			</View>
		)
	}

	const renderPage2 = (preTrip) => {
		return (
			<View style={pdfStyles.section}>
				<View style={[pdfStyles.row, {width: '100%', justifyContent: 'space-between'}]}>
					<Image src={Logo} style={{width: 160, height: 80}}/>
					<Text style={[pdfStyles.bigHeaderText]}>CHAUFFEUR INVOICE</Text>
				</View>

				<View style={[pdfStyles.row, {width: '100%', justifyContent: 'space-between', alignItems: 'flex-end'}]}>
					{renderBusinessInfo()}
					{renderPreTripBox()}
				</View>

				{renderClientInfo()}
				{renderLocationsTable()}

				{/*<View style={[pdfStyles.row, {marginTop: 20, alignItems: 'flex-end'}]}>*/}
				{/*	<Text style={pdfStyles.detailsText}>Security Deposit Amount: </Text>*/}
				{/*	<View style={{borderBottom: `1px solid ${Colors.theme}`, width: 150, marginLeft: 5, marginRight: 10}}/>*/}
				{/*	{renderPaymentOptions()}*/}
				{/*</View>*/}

				{renderCustomerSignatureBox()}

				{renderFooter()}

			</View>

		)
	}

	return (
		<PDFViewer style={{height: '100%', width: '100%'}}>
			<Document>
				<Page size="A4" style={pdfStyles.page}>
					{renderPage2()}
				</Page>

			</Document>
		</PDFViewer>
	)
}

export default CustomPDFChauffeur
