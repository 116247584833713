import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {useDispatch, useSelector} from "react-redux";
import {API_CALL_STATUS} from "../../metadata/enums";
import {LOGGER} from "../../utils/Logger";
import moment from "moment";
import Styles from "./styles/ClientsList.module.scss";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import CustomButtonContained from '../../components/general/CustomButtonContained'
import SearchIcon from "../../assets/logos/searchIcon.png";
import CustomTextFieldNative from "../../components/general/CustomTextFieldNative";
import Constants from "../../config/contants";
import Fuse from "fuse.js";
import {navigate} from "react-big-calendar/lib/utils/constants";
import {useNavigate} from "react-router-dom";
import {updateUpdatedClientStatus, updateAddedClientStatus, getClientDetails} from '../../redux/slices/clientsSlice'

const ClientsList = () => {
	let navigate = useNavigate()
	let dispatch = useDispatch()

	let clientsState = useSelector(state => state.clients)
	const token = useSelector(state => state.auth.token)

	const [loading, setLoading] = useState(true)
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')
	const [searchString, setSearchString] = useState('')
	const [searchResults, setSearchResults] = useState(null)
	const [fuse, setFuse] = useState(null)
	const [tab, setTab] = useState(0)
	const [clientsList, setClientsList] = useState(null)

	useEffect(() => {
		if(clientsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if(clientsState.status === API_CALL_STATUS.FAILED) {
			setLoading(true)
			setShowError(true)
			setErrorMessage('something went wrong when getting the clients data')
			LOGGER.error('error effect', clientsState.error)
		} else {
			setLoading(false)

			if(clientsState.client_added) {
				setShowSuccess(true)
				setSuccessMessage('Successfully added client')
				dispatch(updateAddedClientStatus(false))
			}

			if(clientsState.client_updated) {
				setShowSuccess(true)
				setSuccessMessage('Successfully updated client')
				dispatch(updateUpdatedClientStatus(false))
			}


			let list = [...clientsState.clients]
			setClientsList(list)
			if(list.length > 0) {
				let temp = new Fuse(list, {
					keys: ['fName', 'lName', 'email', 'phone']
				})
				setFuse(temp)
			}
		}
	},[clientsState])

	const onSearchStringChanged = (text) => {
		setSearchString(text)
		if(searchString.length === 0)
			return
		let results = fuse.search(text)
		let array = results.map(temp => temp.item)
		setSearchResults(array)
	}

	const renderFilterRow = () => {
		return (
			<div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30}}>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{width: '62%', backgroundColor: Colors.theme, borderRadius: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 20}}>
						<img src={SearchIcon} style={{height: 16, width: 16}}/>
						<CustomTextFieldNative
							containerStyle={{width: '100%'}}
							height={36}
							label={'search'}
							placeholder={'Search clients'}
							value={searchString}
							onChange={onSearchStringChanged}/>
					</div>

					<div style={{width: '38%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
						<Tab tab={tab} onChange={(value) => setTab(value)}/>
					</div>
				</div>
			</div>
		)
	}

	const renderClientsList = () => {
		let list = searchString && searchString.length > 0 ? searchResults : clientsList
		let clientRows = []
		if(list && list.length > 0) {
			clientRows = list.map((client,index) => {
				return (
					<div
						onClick={() => {
							dispatch(getClientDetails({token: token, _id: client._id}))
							navigate('/home/clients/update-client')
						}}
						key={index.toString()}
						className={Styles.tableRow}
						style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`}}>
						<p style={{width: '25%'}} className={Styles.tableRowEntry}>{`${client.fName} ${client.lName}`}</p>
						<p style={{width: '25%'}} className={Styles.tableRowEntry}>{client.phone}</p>
						<p style={{width: '25%'}} className={Styles.tableRowEntry}>{client.email}</p>
						<p style={{width: '18%'}} className={Styles.tableRowEntry}>{client.total_reservations}</p>
					</div>
				)
			})
		}
		return(
			<div style={{width: '100%'}}>
				<div style={{marginTop: 20}}>
					<div className={Styles.tableRow} style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`}}>
						<p style={{width: '25%'}} className={Styles.tableHeader}>Customer</p>
						<p style={{width: '25%'}} className={Styles.tableHeader}>Phone #</p>
						<p style={{width: '25%'}} className={Styles.tableHeader}>Email Address</p>
						<p style={{width: '18%'}} className={Styles.tableHeader}>Total Reservations</p>
					</div>
					<div style={{height: '70vh', overflowY: 'scroll'}}>
						{clientRows}
					</div>

				</div>
			</div>
		)
	}

	const renderContent = () => {
		return (
			<div style={{width: '100%'}}>
				{renderFilterRow()}
				{renderClientsList()}
			</div>
		)
	}

	if(loading) {
		return (
			<div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: Colors.contentBackgroundColor, width: '100%'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader header={'Clients'} subHeader={'   '}/>
				<CustomButtonContained
					text={'+ New Client'}
					onClick={() => {
						navigate('/home/clients/new-client')
					}}
					style={{position: 'absolute', right: 0, zIndex: 10, top: 24}}
					color={'primary'}
				/>
			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setShowSuccess(false)
					setErrorMessage('')
					setSuccessMessage('')
				}}
				severity={showError ? 'error' : 'success'}
				openFlag={showError || showSuccess}
				message={showError ? errorMessage : successMessage}
			/>

			{
					renderContent()
			}
		</div>
	)
}

const Tab = ({tab, onChange}) => {
	return (
		<div style={{width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 10, border: '1px solid gray', padding: 4, backgroundColor: Colors.theme, borderColor: Colors.theme}}>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 0 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(0)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>All</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 1 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(1)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Members</p>
			</div>
			<div
				className={Styles.tabEntry}
				style={{backgroundColor: tab === 2 ? Colors.tabButtonTheme : 'transparent',}}
				onClick={() => onChange(2)}>
				<p style={{fontSize: Constants.entryTextSize, color: Colors.tertiaryTextColor}}>Blocklisted</p>
			</div>
		</div>
	)
}

export default ClientsList
