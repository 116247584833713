import React, {useState, useEffect} from 'react'
import GeneralStyles from "../GeneralStyles.module.scss";
import PageHeader from "../../components/general/PageHeader";
import CustomButtonContained from "../../components/general/CustomButtonContained";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getAnalyticsData, getSalesAnalyticsData, getProfitabilityAnalyticsData} from "../../redux/slices/analyticsSlice";
import {useNavigate} from 'react-router-dom'
import {API_CALL_STATUS} from "../../metadata/enums";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import {LOGGER} from "../../utils/Logger";
import Styles from "./styles/Analytics.module.scss";
import Colors from "../../config/colors";
import CustomLoaderSpinner from "../../components/general/CustomLoaderSpinner";
import {Area, AreaChart, Bar, BarChart, Tooltip, XAxis, YAxis} from "recharts";
import {CHART_AREA_DATA, CHART_BAR_DATA} from "../../metadata/chartData";
import CustomSelect from "../../components/general/CustomSelect";
import {getSalesSettings} from "../../redux/slices/settingsSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CustomModal from "../../components/general/CustomModal";
import {DateRangePicker} from "react-date-range";
import reservations from "../Reservations/Reservations";
import {updateReservation} from "../../redux/slices/reservationsSlice";
import Constants from '../../config/contants'
import Helpers from '../../utils/helpers'
import {changeUserAccess} from '../../redux/slices/authSlice'
import {getSalesUsers} from '../../redux/slices/userSlice'
import {clientsMapSelector} from "../../redux/slices/clientsSlice";
import DownloadIcon from '@mui/icons-material/Download';
import AnalyticsPdfTemplate from '../../components/general/AnalyticsPdfTemplate'

const TIMELINES = [
	{value: 'weekly', label: 'Weekly'},
	{value: 'monthly', label: 'Monthly'},
	{value: 'quarterly', label: 'Quarterly'}
]

const Analytics = () => {
	let navigate = useNavigate()
	const dispatch = useDispatch();

	const token = useSelector(state => state.auth.token)
	const salesUsers = useSelector(state => getSalesUsers(state))
	const salesSettings = useSelector(state => getSalesSettings(state.settings))
	const analyticsState = useSelector(state => state.analytics)
	const reservationsState = useSelector(state => state.reservations)
	const mongoUser = useSelector(state => state.user.mongoUser)
	const settingsState = useSelector(state => state.settings)
	const clientsMap = useSelector(state => clientsMapSelector(state.clients))
	const vehiclesState = useSelector(state => state.vehicles)


	const [newReservations, setNewReservations] = useState([])
	const [oldReservations, setOldReservations] = useState([])
	const [currentDateTime, setCurrentDateTime] = useState(moment())
	const [timeline, setTimeline] = useState(TIMELINES[0])
	const [loading, setLoading] = useState(true)
	const [salesLoading, setSalesLoading] = useState(false)
	const [profitabilityLoading, setProfitabilityLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [showError, setShowError] = useState(false)
	const [analyticsData, setAnalyticsData] = useState([])
	const [showDateSelector, setShowDateSelector] = useState(false)
	const [salesAnalyticsData, setSalesAnalyticsData] = useState([])
	const [startDate, setStartDate] = useState(moment.utc().subtract(30,'days').toDate())
	const [endDate, setEndDate] = useState(moment.utc().toDate())
	const [salesPerson, setSalesPerson] = useState({value: 'none', label: 'None'})
	const [profitabilityData, setProfitabilityData] = useState(null)
	const [selectedVehicle, setSelectedVehicle] = useState(null)
	const [showPdfModal, setShowPdfModal] = useState(false)


	//this is to check
	useEffect(() => {
		let rolesMap = Helpers.getRolesMap(settingsState)
		if(mongoUser
				&& Object.keys(rolesMap).length > 0
				&& !Helpers.checkAccess(mongoUser, rolesMap, 'view_analytics')) {
				dispatch(changeUserAccess(false))
				navigate(-1)
		}
	},[mongoUser, settingsState])

	useEffect(() => {
		setTimeout(() => {
			setCurrentDateTime(moment())
		},60000)
	},[])

	useEffect(() => {
		if(token) {
			dispatch(getAnalyticsData({token, timeline: timeline.value}))
		}
	},[timeline, token])

	useEffect(() => {
		if(reservationsState.status !== API_CALL_STATUS.LOADING) {
			setNewReservations(reservationsState.reservations)
			setOldReservations(reservationsState.old_reservations)
		}
	},[reservationsState])

	const getProfitabilityData = () => {
		dispatch(getProfitabilityAnalyticsData({
			token: token,
			data: {
				start_date: startDate.toISOString(), end_date: endDate.toISOString()
			}
		}))
	}

	const getSalesBreakdown = (sales_person) => {
		dispatch(getSalesAnalyticsData({
			token: token,
			data: {
				start_date: startDate.toISOString(), end_date: endDate.toISOString(), sales_person: sales_person
			}
		}))
	}

	useEffect(() => {
		if(analyticsState.status === API_CALL_STATUS.LOADING) {
			setLoading(true)
		} else if(analyticsState.status === API_CALL_STATUS.FAILED) {
			setShowError(true)
			setErrorMessage('something went wrong when getting the analytics data')
			LOGGER.error('error effect', analyticsState.error)
		} else if(analyticsState.status === API_CALL_STATUS.SUCCEEDED) {
			setLoading(false)
			let data = analyticsState.analytics
			if(!data || data.length === 0)
				return

			let analyticsData = []
			data.forEach(entry => {
				let date = moment(entry.date, 'MM/DD/YYYY')
				analyticsData.push({
					date: entry.date,
					day: date.format('ddd'),
					short: date.format('MM/DD'),
					count: entry.chauffeur.count + entry.rentals.count,
					sales: entry.chauffeur.sales + entry.rentals.sales,
					chauffeur: entry.chauffeur,
					rentals: entry.rentals
				})
			})

			setAnalyticsData(analyticsData)

		}


		if(analyticsState.sales_status === API_CALL_STATUS.LOADING) {
			setSalesLoading(true)
		} else if(analyticsState.sales_status === API_CALL_STATUS.FAILED) {
			setShowError(true)
			setErrorMessage('something went wrong when getting the sales analytics data')
			LOGGER.error('error effect', analyticsState.error)
		} else if(analyticsState.sales_status === API_CALL_STATUS.SUCCEEDED) {
			setSalesAnalyticsData(analyticsState.sales_analytics)
			setSalesLoading(false)
		}


		if(analyticsState.profitability_status === API_CALL_STATUS.LOADING) {
			setProfitabilityLoading(true)
		} else if(analyticsState.profitability_status === API_CALL_STATUS.FAILED) {
			setShowError(true)
			setErrorMessage('something went wrong when getting the profitability analytics data')
			LOGGER.error('error effect', analyticsState.error)
		} else if(analyticsState.profitability_status === API_CALL_STATUS.SUCCEEDED) {
			setProfitabilityData(analyticsState.profitability_analytics)
			setProfitabilityLoading(false)
		}

	},[analyticsState])

	const onDateRangeSelectorClicked = () => {
		setShowDateSelector(true)
	}

	const onReservationClicked = (id) => {
		if(!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'view_reservations')) {
			dispatch(changeUserAccess(false))
			return
		}

		let reservationToShow = null
		newReservations.every(temp => {
			if(temp._id === id) {
				reservationToShow = temp
				return false
			}

			return true
		})

		if(!reservationToShow) {
			//could be in old reservations
			oldReservations.every(temp => {
				if(temp._id === id) {
					reservationToShow = temp
					return false
				}

				return true
			})
		}

		if(reservationToShow) {
			dispatch(updateReservation(reservationToShow))
			if(reservationToShow.isChauffeur) {
				navigate('/home/new-chauffeur')
			} else {
				navigate('/home/new-reservation')
			}
		}

	}

	const getTimelineHeader = () => {
		switch(timeline.value) {
			case 'weekly': return 'Weekly';
			case 'monthly': return 'Monthly';
			case 'quarterly': return 'Quarterly';
			default: return 'Weekly'
		}
	}

	const handleDateSelect = (ranges) => {
		let {selection} = ranges

		setStartDate(selection.startDate)
		setEndDate(selection.endDate)
	}

	const renderSalesChartHeader = () => {
		return (
			<div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
				<p style={{fontSize: 22, fontWeight: 'bold', marginLeft: 20}}>Sales Attribution</p>
			</div>
		)
	}


	const renderChartHeader = (isSalesChart) => {
		return (
			<div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
				<p style={{marginLeft: 20}}>{getTimelineHeader()}
					<span style={{fontWeight: 'bold', display: 'block', fontSize: 22, color: Colors.primaryTextColor}}>
								{isSalesChart ? 'Sales' : 'Rentals'}
							</span>
				</p>

			</div>
		)
	}

	const renderRentalsChart = () => {
		return (
			<div className={`${Styles.chartWrapper} ${GeneralStyles.boxShadow}`}>
				{renderChartHeader(false)}
				<RentalsChart data={analyticsData} timeline={timeline.value}/>
			</div>
		)
	}

	const renderSalesChart = () => {
		return (
			<div className={`${Styles.chartWrapper} ${GeneralStyles.boxShadow}`} style={{marginTop: 40}}>
				{renderChartHeader(true)}
				<SalesChart data={analyticsData} timeline={timeline.value}/>
			</div>
		)
	}

	const getVehicleVisibility = (id) => {
		if(!selectedVehicle || selectedVehicle.value === 'all')
			return 'flex'

		if(selectedVehicle.value === id)
			return 'flex'
		else
			return 'none'
	}

	const renderProfitabilityTable = () => {
		if(!profitabilityData || Object.keys(profitabilityData).length === 0) {
			return (
				<div style={{marginTop: 60}}>
					<p>Nothing to display</p>
				</div>
			)
		}

		let totalRevenue = 0
		let totalExpense = 0
		let totalRes = 0

		let elements = vehiclesState.vehicles.map(vehicle => {
			let vehicleData = profitabilityData[vehicle.id] || {}
			let revenue = vehicleData['revenue'] || 0
			let expense = vehicleData['expense'] || 0
			let totalReservations = vehicleData['total_reservations'] || 0
			totalRevenue += revenue
			totalExpense += expense
			totalRes += totalReservations

			return (
				<div
					style={{
						display: getVehicleVisibility(vehicle.id),
						flexDirection: 'row',
						alignItems: 'center',
						width: '100%',
						height: 50,
						borderBottom: `0.5px solid ${Colors.secondaryTextColor}`
				}}>
					<label className={GeneralStyles.darkText} style={{width: '30%'}}>{vehicle.make}</label>
					<label className={GeneralStyles.darkText} style={{width: '18%', marginLeft: 5}}>$ {Number(revenue).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{width: '18%'}}>$ {Number(expense).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{width: '18%'}}>$ {Number(revenue - expense).toFixed(2)}</label>
					<label className={GeneralStyles.darkText} style={{width: '16%'}}>{totalReservations}</label>
				</div>
			)
		})

		return (
			<div style={{width: '94%', height: '80%'}}>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
					<p style={{fontWeight: 'bold', width: '30%'}}>Vehicle</p>
					<p style={{fontWeight: 'bold', width: '18%', marginLeft: 5}}>Revenue</p>
					<p style={{fontWeight: 'bold', width: '18%'}}>Expense</p>
					<p style={{fontWeight: 'bold', width: '18%'}}>Profit</p>
					<p style={{fontWeight: 'bold', width: '16%'}}>Total reservations</p>
				</div>
				<div style={{width: '100%', height: '70%', overflowY: 'scroll'}}>
					{elements}
				</div>

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 4}}>
					<label className={GeneralStyles.darkText}  style={{fontWeight: 'bold', width: '30%'}}></label>
					<label className={GeneralStyles.darkText}  style={{fontWeight: 'bold', width: '18%', marginLeft: 5}}>$ {Number(totalRevenue).toFixed(2)}</label>
					<label className={GeneralStyles.darkText}  style={{fontWeight: 'bold', width: '18%'}}>$ {Number(totalExpense).toFixed(2)}</label>
					<label className={GeneralStyles.darkText}  style={{fontWeight: 'bold', width: '18%'}}>$ {Number(totalRevenue - totalExpense).toFixed(2)}</label>
					<label className={GeneralStyles.darkText}  style={{fontWeight: 'bold', width: '16%'}}>{totalRes}</label>
				</div>

			</div>
		)
	}

	const renderSalesTable = () => {
		if(!salesAnalyticsData || salesAnalyticsData.length === 0) {
			return (
				<div style={{marginTop: 60}}>
					<p>Nothing to display</p>
				</div>
			)
		}
		let total = salesAnalyticsData.length
		let totalEarning = 0

		let entries = salesAnalyticsData.map(row => {
			let customer = clientsMap[row.client]
			totalEarning += Number(row.totalPrice)
			return (
				<div
					style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', cursor: 'pointer'}}>
					<p
						onClick={() => onReservationClicked(row._id)}
						style={{width: '8%', textDecoration: 'underline'}}>
						#{row._id}
					</p>
					<p style={{width: '22%'}}>{row.vehicle_id}</p>
					<p style={{width: '22%'}}>{`${customer?.fName} ${customer?.fName}`}</p>
					<p style={{width: '20%'}}>{moment(row.pickup_datetime).format('MM/DD/YY : hh:mm A')}</p>
					<p style={{width: '20%'}}>{moment(row.dropoff_datetime).format('MM/DD/YY : hh:mm A')}</p>
					<p style={{width: '8%'}}>$ {row.totalPrice}</p>
				</div>
			)
		})

		return (
			<div style={{width: '94%', height: '80%'}}>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
					<p style={{fontWeight: 'bold', width: '8%'}}>Res #</p>
					<p style={{fontWeight: 'bold', width: '22%'}}>Vehicle</p>
					<p style={{fontWeight: 'bold', width: '22%'}}>Customer</p>
					<p style={{fontWeight: 'bold', width: '20%'}}>Pick-Up</p>
					<p style={{fontWeight: 'bold', width: '20%'}}>Drop-off</p>
					<p style={{fontWeight: 'bold', width: '8%'}}>Total Price</p>
				</div>
				<div style={{height: '60%', overflowY: 'scroll'}}>
					{entries}
				</div>
				<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<p style={{fontSize: Constants.entryTextSize, color: Colors.secondaryTextColor}}>Total Reservations: <span className={GeneralStyles.darkText}>{total}</span></p>
					<p style={{fontSize: Constants.entryTextSize, marginLeft: 50, color: Colors.secondaryTextColor}}>Total Earnings: <span className={GeneralStyles.darkText}>${totalEarning}</span></p>
				</div>
			</div>
		)
	}

	const renderDateSelectors = () => {
		return (
			<div
				className={Styles.dateRangeDisplayContainer}>
				<div
					className={GeneralStyles.boxShadow}
					onClick={onDateRangeSelectorClicked}
					style={{display: 'flex', flexDirection: 'row', width: '45%', borderRadius: 5, alignItems: 'center', justifyContent: 'space-evenly', height: 40}}>
					<p>{moment(startDate).format('dddd, MMM Do YYYY')}</p>
					<CalendarMonthIcon style={{color: 'white'}}/>
				</div>
				<p style={{fontSize: 28}}>
					{`\u2B64`}
				</p>
				<div
					className={GeneralStyles.boxShadow}
					onClick={onDateRangeSelectorClicked}
					style={{display: 'flex', flexDirection: 'row', width: '45%', borderRadius: 5, alignItems: 'center', justifyContent: 'space-evenly', height: 40}}>
					<p>{moment(endDate).format('dddd, MMM Do YYYY')}</p>
					<CalendarMonthIcon style={{color: 'white'}}/>
				</div>


			</div>
		)
	}

	const renderSalesFilters = () => {
		if(!salesUsers)
			return

		let options = salesUsers.map(user =>  {
			return {label: user.name, value: user._id}
		})

		options.unshift({value: 'none', label: 'None'})
		return (
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
				<div className={GeneralStyles.boxShadow} style={{width: 200, height: 40, borderRadius: 10, marginTop: 4, marginLeft: 40}}>
					<CustomSelect
						placeholder={'select'}
						borderColor={'transparent'}
						value={salesPerson}
						options={options}
						onChange={setSalesPerson}
					/>
				</div>

				{renderDateSelectors()}

				<div style={{marginLeft: 20}}>
					<CustomButtonContained
						text={'Search'}
						onClick={() => {
							getSalesBreakdown(salesPerson?.value)
						}}
						style={{height: 34, marginLeft: 5}}
						textPadding={'4px 10px'}
					/>
				</div>

			</div>

		)
	}

	const renderProfitabilityFilters = () => {
		let vehicleOptions = vehiclesState.vehicles.map(vehicle => {
			return {label: vehicle.id, value: vehicle.id}
		})

		vehicleOptions.unshift({label: 'All Vehicles', value: 'all'})

		return (
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
				<div className={GeneralStyles.boxShadow} style={{width: 225, height: 40, borderRadius: 10, marginTop: 4, marginLeft: 40}}>
					<CustomSelect
						placeholder={'select vehicle'}
						borderColor={'transparent'}
						value={selectedVehicle}
						options={vehicleOptions}
						onChange={setSelectedVehicle}
					/>
				</div>

				{renderDateSelectors()}
				<div style={{marginLeft: 20}}>
					<CustomButtonContained
						text={'Search'}
						onClick={() => {
							getProfitabilityData()
						}}
						style={{height: 34, marginLeft: 5}}
						textPadding={'4px 10px'}
					/>
				</div>

			</div>
		)
	}

	const renderProfitabilityBreakdown = () => {
		return (
			<div className={`${Styles.chartWrapper} ${GeneralStyles.boxShadow}`} style={{marginTop: 40, height: 500}}>
				<div style={{display: 'flex', flexDirection: 'row', width: '96%', alignItems: 'center', justifyContent: 'space-between'}}>
					<p style={{fontSize: 22, fontWeight: 'bold'}}>Vehicle Profitability</p>
					{
						profitabilityData && profitabilityData.length !== 0 &&
						<div
							onClick={() => setShowPdfModal(true)}
							style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center'}}>
							<DownloadIcon size={20} style={{color: Colors.primaryTextColor}}/>
							<p style={{fontSize: 14, marginLeft: 6}}>Download</p>
						</div>
					}

				</div>

				{renderProfitabilityFilters()}
				{
					profitabilityLoading ?
						<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
							<CustomLoaderSpinner/>
						</div>
						:
						renderProfitabilityTable()
				}
			</div>
		)
	}

	const renderSalesBreakdown = () => {
		return (
			<div className={`${Styles.chartWrapper} ${GeneralStyles.boxShadow}`} style={{marginTop: 40, height: 500}}>
				{renderSalesChartHeader()}
				{renderSalesFilters()}
				{
					salesLoading ?
						<div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
							<CustomLoaderSpinner/>
						</div>
						:
						renderSalesTable()
				}
			</div>
		)
	}

	const renderDateSelectorModal = () => {
		const selectionRange = {
			startDate: startDate,
			endDate: endDate,
			key: 'selection',
		}
		return (
			<CustomModal show={showDateSelector} handleClose={() => setShowDateSelector(false)} containerWidth={window.innerWidth/2}>
				<DateRangePicker
					rangeColors={[Colors.themeLight]}
					ranges={[selectionRange]}
					onChange={handleDateSelect}
				/>
			</CustomModal>
		)
	}

	const renderProfitabilityPdfModal = () => {
		let allVehicles = vehiclesState.vehicles || []
		return (
			<CustomModal
				show={showPdfModal}
				handleClose={() => setShowPdfModal(false)}
				containerWidth={window.innerWidth - 100}
				containerHeight={window.innerHeight - 100}
			>
				<AnalyticsPdfTemplate
					startDate={startDate}
					endDate={endDate}
					vehicles={allVehicles}
					profitabilityData={profitabilityData}
				/>
			</CustomModal>
		)
	}


	const renderContent = () => {

		return (
			<div style={{paddingBottom: 50}}>
				<div style={{width: '25%', flexDirection: 'row', alignItems: 'center', display: 'flex', marginBottom: 20}}>
					<label
						className={GeneralStyles.darkText}
						style={{fontSize: 18, marginRight: 20}}>Timeline</label>
					<div
						className={GeneralStyles.boxShadow}
						style={{width: '100%', height: 40, borderRadius: 10, marginTop: 4}}>
						<CustomSelect
							placeholder={'select pick-up location'}
							borderColor={'transparent'}
							value={timeline}
							options={TIMELINES}
							onChange={setTimeline}
						/>

					</div>
				</div>

				{renderRentalsChart()}
				{renderSalesChart()}
				{renderSalesBreakdown()}
				{renderProfitabilityBreakdown()}

				{renderProfitabilityPdfModal()}
				{renderDateSelectorModal()}
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
					<PageHeader header={'Analytics'} subHeader={`${currentDateTime.format('dddd, MMM Do')} | ${currentDateTime.format('h:mm A')}`} />
					{/*<CustomButtonContained*/}
					{/*	text={'+ New Reservation'}*/}
					{/*	onClick={onNewReservationClicked}*/}
					{/*	style={{position: 'absolute', right: 40, zIndex: 10}}*/}
					{/*	color={showOverlay ? 'white' : 'primary'}*/}
					{/*/>*/}
				</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setErrorMessage('')
				}}
				severity={'error'}
				openFlag={showError}
				message={errorMessage}
			/>

			{
				loading ?
					<div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<CustomLoaderSpinner />
					</div>
					:
					renderContent()
			}
		</div>
	)
}

const SalesChart = ({data,timeline}) => {
	let width = window.innerWidth * 0.6

	return (
		<AreaChart width={width} height={200} data={data}>
			<defs>
				<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
					<stop offset="5%" stopColor={Colors.buttonTheme} stopOpacity={0.8}/>
					<stop offset="95%" stopColor={Colors.buttonTheme} stopOpacity={0}/>
				</linearGradient>
			</defs>
			<XAxis dataKey={timeline === 'weekly' ? 'day' : 'short'}/>
			<YAxis />
			<Area type="monotone" dataKey={"sales"} stroke={Colors.buttonTheme} fillOpacity={1} fill="url(#colorPv)" />
			<Tooltip content={<CustomTooltip />} />
		</AreaChart>
	)
}

const RentalsChart = ({data,timeline}) => {
	let width = window.innerWidth * 0.6


	return (
		<BarChart width={width} height={200} data={data}>
			<XAxis dataKey={timeline === 'weekly' ? 'day' : 'short'}/>
			<YAxis />
			<Tooltip cursor={{fill: '#00000000'}} content={<CustomTooltip2 />}/>
			<Bar barSize={20} dataKey="count" fill={Colors.buttonTheme}/>
		</BarChart>
	)
}

const CustomTooltip2 = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		let data = payload[0].payload

		return (
			<div className={Styles.tooltipWrapper}>
				<p style={{color: Colors.darkTextColor}}>{moment(data.date,'MM/DD/YYYY').format('MMM Do YYYY, dddd')}</p>
				<p style={{color: Colors.darkTextColor}}>{`Total: ${data.count}`}</p>
				<p style={{color: Colors.darkTextColor}}>{`Chauffeur: ${data.chauffeur.count}`}</p>
				<p style={{color: Colors.darkTextColor}}>{`Rentals: ${data.rentals.count}`}</p>
			</div>
		);
	}

	return null;
};

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		let data = payload[0].payload

		return (
			<div className={Styles.tooltipWrapper}>
				<p style={{color: Colors.darkTextColor}}>{moment(data.date,'MM/DD/YYYY').format('MMM Do YYYY, dddd')}</p>
				<p style={{color: Colors.darkTextColor}}>{`Total Sales: $${data.sales}`}</p>
				<p style={{color: Colors.darkTextColor}}>{`Chauffeur: $${data.chauffeur.sales}`}</p>
				<p style={{color: Colors.darkTextColor}}>{`Rentals: $${data.rentals.sales}`}</p>
			</div>
		);
	}

	return null;
};

export default Analytics
