import './App.scss';
import {useState, useEffect} from 'react';
import {Navigate} from "react-router-dom";
import {firestore, auth} from './services/firebase'
import {onAuthStateChanged } from "firebase/auth";
import {Outlet, useNavigate} from 'react-router-dom'
import {useDispatch} from "react-redux";
import Helpers from "./utils/helpers";
import {addUser} from "./redux/slices/userSlice";

function App() {
  let navigate = useNavigate()
  let dispatch = useDispatch()

  /*useEffect()*/
  useEffect(() =>{
    let listener = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        Helpers.updateUserStore(authUser)
        navigate('/home/overview')
      } else {
        dispatch(addUser(null))
        navigate('/login')
      }
    });
  }, []);
}

export default App;
