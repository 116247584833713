const Config = {
	// BACKEND_URL: 'http://localhost:9000/',
	// CONTRACT_URL: 'http://localhost:3006/',

	// CONTRACT_URL: 'https://contracts-test-real-testsite.web.app/',
	CONTRACT_URL: 'https://contracts.golux.app/',

	// BACKEND_URL: 'https://s0nwvs979h.execute-api.us-west-2.amazonaws.com/test/',
	// ENV: 'test',
	// BUSINESS: 'test',
	// NAME: 'Test Rentals',

	//dde things
	// BACKEND_URL: 'https://nbz1rdwbo6.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'prod',
	// BUSINESS: 'dde',
	// NAME: 'DDE Rentals',

	//drive LA things
	// BACKEND_URL: 'https://z90ld6ouq9.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'drivela',
	// BUSINESS: 'drivela',
	// NAME: 'Drive LA',

	//fantasy exotics url and env
	BACKEND_URL: 'https://12yw54h3th.execute-api.us-west-2.amazonaws.com/prod/',
	ENV: 'fantasy',
	BUSINESS: 'fantasy',
	NAME: 'Fantasy Exotics',

	// BACKEND_URL: 'https://33cbi9ny36.execute-api.us-east-2.amazonaws.com/prod/', // old test url
	// /*ENV: 'demo-prod',*/



	//777 exotics
	// BACKEND_URL: 'https://punnx1809a.execute-api.us-west-2.amazonaws.com/prod/',
	// env: '777exotics',

	//sde exotics
	// BACKEND_URL: 'https://nci5oqf6g1.execute-api.us-west-2.amazonaws.com/prod/',
	// env: 'sd-exotics',


	AWS_CLOUDFRONT_URL: 'https://d3nk8x6ofxzl5v.cloudfront.net/',
	GOOGLE_API_KEY: "AIzaSyAlmct1SHhXm2OgLlUfShrHZjNFj3fvDNM",
	WHITELIST_UIDS: [
		'dtFyaZpli2RmdRL2s6MEwFkatAm2', //support from old firebase project
		'8OROqsfWm5W5X3SBrj5EmGDrXjF2', //support@gmail.com
		'b578coCbDRhj07EvfIHoaGC2TaJ3', //info@dripdropexptocs.com
		'02FOFSwyEYPeYk49IFRD8hawyWn1', //info@dderentlas
		'kh6zsxwqacbgEhly6s9qVNs7LGw2', //info@drive-la.com
		'EKmi7ULrDkaF7N2lTdSRijGZ3Jr1' //info@fantasyexoticrentals.com
	],
	AWS_REGION: 'us-west-2',
	SQS_URL: 'https://sqs.us-west-2.amazonaws.com/472798980256/updates.fifo',
	AWS_ACCESS_KEY_ID: "AKIAW4FID5CQF6STTWEH",
	AWS_SECRET_ACCESS_KEY: "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",
}

export default Config
