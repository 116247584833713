import { useEffect, useRef } from "react";
import moment from 'moment'

export default function ChauffeurPdfViewerComponent(props) {
	const containerRef = useRef(null);

	useEffect(() => {
		const container = containerRef.current;
		let {pickUpDateTime, document} = props
		let instance, PSPDFKit;

		(async function() {
			PSPDFKit = await import("pspdfkit");
			instance = await PSPDFKit.load({
				// Container where PSPDFKit should be mounted.
				container,
				// The document to open.
				document: props.document,
				baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`
			})

			// const bbox1 = (await instance.search("customer-signature-1"))
			// 	.first()
			// 	.rectsOnPage.get(0);
			//
			// const signatureWidget = new PSPDFKit.Annotations.WidgetAnnotation({
			// 	id: PSPDFKit.generateInstantId(),
			// 	pageIndex: 0,
			// 	boundingBox: bbox1,
			// 	formFieldName: "signature-1",
			// });
			//
			// const signatureField = new PSPDFKit.FormFields.SignatureFormField({
			// 	name: "signature-1",
			// 	annotationIds: new PSPDFKit.Immutable.List([signatureWidget.id]),
			// });

			createSignatureAnnotation(PSPDFKit, instance, 'customer-signature-1', 'signature-1')

			createAnnotation(PSPDFKit, instance, 'pickup-date-n-time', 'pickup-datetime-field-1', 100, moment().format('MMM DD, YYYY hh:mm A'))


		})();

		return () => PSPDFKit && PSPDFKit.unload(container);
	}, []);

	const createSignatureAnnotation = async (PSPDFKit, instance, searchString, name) => {

		const bbox1 = (await instance.search(searchString))
			.first()
			.rectsOnPage.get(0);

		let signatureWidget = new PSPDFKit.Annotations.WidgetAnnotation({
			id: PSPDFKit.generateInstantId(),
			pageIndex: 0,
			boundingBox: bbox1,
			formFieldName: name,
		});

		let signatureField = new PSPDFKit.FormFields.SignatureFormField({
			name: name,
			annotationIds: new PSPDFKit.Immutable.List([signatureWidget.id]),
		});

		instance.create([signatureField, signatureWidget]);
	}

	const createAnnotation = async (PSPDFKit, instance, searchString, name, width, textToOverlay) => {
		const bbox = (await instance.search(searchString))
			.first()
			.rectsOnPage.get(0);

		let {left, top} = bbox

		const textAnnotation = new PSPDFKit.Annotations.TextAnnotation({
			boundingBox: new PSPDFKit.Geometry.Rect({
				left: left,
				top: top,
				width: width,
				height: 14,
			}),
			fontSize: 12,
			text: textToOverlay,
			pageIndex: 0,
			formFieldName: name,
			backgroundColor: PSPDFKit.Color.YELLOW
		});

		const formField = new PSPDFKit.FormFields.TextFormField({
			name: name,
			annotationIds: new PSPDFKit.Immutable.List([textAnnotation.id]),
		});

		instance.create([formField, textAnnotation]);

	}

	return (
		<div ref={containerRef} style={{ width: "100%", height: "100%"}}/>
	);
}
