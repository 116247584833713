
// Import the functions you need from the SDKs you need
import { initializeApp,  } from "firebase/app";
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

//prod admin config final for release
const firebaseConfig = {
	apiKey: "AIzaSyBJCdKlQGbJ8fauzvdJXNGIPZ4E6_glYy8",
	authDomain: "admin-fbf4f.firebaseapp.com",
	projectId: "admin-fbf4f",
	storageBucket: "admin-fbf4f.appspot.com",
	messagingSenderId: "1002669219437",
	appId: "1:1002669219437:web:4bc5bd12cf67dacd35a964"
};

// drip drop config
// const firebaseConfig = {
// 	apiKey: "AIzaSyDAOjd17qMO69X2lBnhyFHi_qa8ICL2Edw",
// 	authDomain: "dripdrop-5d817.firebaseapp.com",
// 	projectId: "dripdrop-5d817",
// 	storageBucket: "dripdrop-5d817.appspot.com",
// 	messagingSenderId: "1062544236826",
// 	appId: "1:1062544236826:web:4197ba43b76b5939438ca3",
// 	measurementId: "G-EGVW6X66KD"
// };


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);


export {firebaseApp, firestore, auth, firebaseStorage}

