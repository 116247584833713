import React, {useState, useEffect} from 'react'
import GeneralStyles from '../GeneralStyles.module.scss'
import CustomLoaderSpinner from '../../components/general/CustomLoaderSpinner'
import Styles from "./styles/Dashboard.module.scss";
import Colors from '../../config/colors'
import moment from 'moment'
import {useDispatch,useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {updateReservation} from "../../redux/slices/reservationsSlice";
import {RENTAL_STATUS,API_CALL_STATUS} from "../../metadata/enums";
import {vehiclesMapSelector} from "../../redux/slices/vehiclesSlice";
import { Area, Bar, XAxis, BarChart, AreaChart, Tooltip, YAxis} from 'recharts';
import {CHART_BAR_DATA, CHART_AREA_DATA} from "../../metadata/chartData";
import CustomButtonContained from '../../components/general/CustomButtonContained'
import PageHeader from '../../components/general/PageHeader'
import Helpers from "../../utils/helpers";
import {getRolesMap} from "../../redux/slices/settingsSlice";
import {changeUserAccess} from "../../redux/slices/authSlice";
import {clientsMapSelector} from '../../redux/slices/clientsSlice'
import {getVehicleAnalyticsData} from "../../redux/slices/analyticsSlice";
import PositionedSnackbar from "../../components/general/PositionedSnackbar";
import Constants from '../../config/contants'
import GaugeChart from 'react-gauge-chart'


const Dashboard = () => {
	let dispatch = useDispatch()
	let navigate = useNavigate()

	const mongoUser = useSelector(state => state.user.mongoUser)
	const settingsState = useSelector(state => state.settings)
	const allReservations = useSelector(state => state.reservations)
	const vehiclesMap = useSelector(state => vehiclesMapSelector(state))
	const clientsMap = useSelector(state => clientsMapSelector(state.clients))
	const token = useSelector(state => state.auth.token)
	const analyticsState = useSelector(state => state.analytics)

	const [showError, setShowError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [analyticsLoading, setAnalyticsLoading] = useState(true)
	const [showLoading, setShowLoading] = useState(true)
	const [currentDateTime, setCurrentDateTime] = useState(moment())
	const [pickupReservations, setPickupReservations] = useState({today: [], tomorrow: []})
	const [dropoffReservations, setDropoffReservations]= useState({today: [], tomorrow: []})
	const [pickupTab, setPickupTab] = useState(0)
	const [dropoffTab, setDropoffTab] = useState(0)
	const [showOverlay, setShowOverlay] = useState(false)


	useEffect(() => {
		setTimeout(() => {
			setCurrentDateTime(moment())
		},60000)

	},[])

	useEffect(() => {
		if(token) {
			dispatch(getVehicleAnalyticsData({token}))
		}

	},[token])

	useEffect(() => {
		if(analyticsState.vehicles_status === API_CALL_STATUS.LOADING) {
			setAnalyticsLoading(true)
		} else if(analyticsState.vehicles_status === API_CALL_STATUS.FAILED) {
			console.log('error when getting analytics', analyticsState.error)
			setShowError(true)
			setErrorMessage(analyticsState.error)
		} else {
			setAnalyticsLoading(false)
		}

	},[analyticsState])

	useEffect(() => {
		if(allReservations.status === API_CALL_STATUS.SUCCEEDED) {
			setShowLoading(false)
			let reservations = allReservations.reservations
			let todayPick = []
			let tomPick = []
			let todayDrop = []
			let tomDrop = []
			reservations.forEach(reservation => {
				let pickup = moment(reservation.pickup_datetime)
				let dropoff = moment(reservation.dropoff_datetime)
				if(moment().isSame(pickup, 'day')) {
					if(reservation.rental_status === RENTAL_STATUS.WAITING_PICKUP)
						todayPick.unshift(reservation)
					else
						todayPick.push(reservation)

				} else if(moment().add(1,'day').isSame(pickup, 'day')) {
					if(reservation.rental_status === RENTAL_STATUS.WAITING_PICKUP)
						tomPick.unshift(reservation)
					else
						tomPick.push(reservation)

				}

				if(moment().isSame(dropoff, 'day')) {
					if(reservation.rental_status === RENTAL_STATUS.RENTING)
						todayDrop.unshift(reservation)
					else
						todayDrop.push(reservation)
				} else if(moment().add(1,'day').isSame(dropoff, 'day')) {
					if(reservation.rental_status === RENTAL_STATUS.RENTING)
						tomDrop.unshift(reservation)
					else
						tomDrop.push(reservation)
				}
			})
			setPickupReservations({today: todayPick, tomorrow: tomPick})
			setDropoffReservations({today: todayDrop, tomorrow: tomDrop})
		} else {
			setShowLoading(true)
		}

	},[allReservations])

	const onNewNavigatorClicked = (key) => {
		dispatch(updateReservation(null))
		setShowOverlay(false)
		document.getElementById("overlay").style.display = "none";
		if(key === 'reservation') {
			navigate('/home/new-reservation')
		} else {
			navigate('/home/new-chauffeur')
		}
	}

	const onNewReservationClicked = () => {
		if(!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'create_reservations')) {
			dispatch(changeUserAccess(false))
		} else {
			if(showOverlay) {
				setShowOverlay(false)
				document.getElementById("overlay").style.display = "none";
			} else {
				setShowOverlay(true)
				document.getElementById("overlay").style.display = "block";
			}
		}
	}

	const onReservationClicked = (reservation) => {
		if(!Helpers.checkAccess(mongoUser, Helpers.getRolesMap(settingsState), 'view_reservations')) {
			dispatch(changeUserAccess(false))
		} else {
			dispatch(updateReservation(reservation))
			if(reservation.isChauffeur) {
				navigate('/home/new-chauffeur')
			} else {
				navigate('/home/new-reservation')
			}
		}
	}

	const getStatusText = (reservation) => {
		switch(reservation.rental_status) {
			case 1: return 'Ready for Pick-Up'
			case 2: return 'On Rent'
			case 3: return 'Completed'
			case 4: return 'Cancelled'
		}
	}

	const getEntryColor = (isPickUp, status) => {
		if(isPickUp) {
			if(status === RENTAL_STATUS.WAITING_PICKUP)
				return Colors.primaryTextColor
			else
				return Colors.secondaryTextColor
		} else {
			if(status === RENTAL_STATUS.RENTING)
				return Colors.primaryTextColor
			else
				return Colors.secondaryTextColor
		}
	}

	const renderReservationsRow = (reservations, isPickup) => {
		let elements = []
		reservations.forEach((reservation,index) => {
			if(!reservation.deleted) {
				let time = isPickup ? moment(reservation.pickup_datetime) : moment(reservation.dropoff_datetime)
				let customer = clientsMap[reservation.client] || {}
				elements.push((
					<div
						style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`}}
						onClick={() => onReservationClicked(reservation)}
						className={Styles.reservationRow} key={index.toString()}>
						<p style={{width: '30%', textAlign: 'left', color: getEntryColor(isPickup, reservation.rental_status)}} className={Styles.reservationRowEntry}>{vehiclesMap[reservation.vehicle_id]?.make}</p>
						<p style={{width: '15%', textAlign: 'left', color: getEntryColor(isPickup, reservation.rental_status)}} className={Styles.reservationRowEntry}>{time.format('hh:mm A')}</p>
						<p style={{width: '20%',textAlign: 'left', color: getEntryColor(isPickup, reservation.rental_status)}} className={Styles.reservationRowEntry}>{`${customer.fName} ${customer.lName}`}</p>
						<p style={{width: '15%',textAlign: 'left', color: getEntryColor(isPickup, reservation.rental_status)}} className={Styles.reservationRowEntry}>{customer.phone}</p>
						<p style={{width: '20%',textAlign: 'right', color: getEntryColor(isPickup, reservation.rental_status)}} className={Styles.reservationRowEntry}>{getStatusText(reservation)}</p>
					</div>
				))
			}

		})

		return elements
	}

	const renderUpcomingPickups = () => {
		let reservations = pickupTab === 0 ? pickupReservations.today : pickupReservations.tomorrow
		let reservationsRows = renderReservationsRow(reservations, true)

		return (
			<div className={Styles.rowContainer}>
				<div style={{width: '100%', height: '100%'}}>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', height: 40}}>
						<p style={{fontWeight: 'bold', fontSize: 18}}>Upcoming Pick-Ups</p>
						<div style={{position: 'absolute', right: 0}}>
							<DayTabs onTabSelect={setPickupTab}/>
						</div>
					</div>
					<div className={`${Styles.upcomingWrapper} ${GeneralStyles.boxShadow}`}>
						<div className={Styles.reservationRow} style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`}}>
							<p style={{width: '30%', textAlign: 'left'}} className={Styles.reservationRowHeader}>VEHICLE</p>
							<p style={{width: '15%',textAlign: 'left'}} className={Styles.reservationRowHeader}>PICK-UP TIME</p>
							<p style={{width: '20%',textAlign: 'left'}} className={Styles.reservationRowHeader}>CUSTOMER</p>
							<p style={{width: '15%',textAlign: 'left'}} className={Styles.reservationRowHeader}>PHONE #</p>
							<p style={{width: '20%',textAlign: 'right'}} className={Styles.reservationRowHeader}>STATUS</p>
						</div>
						{reservationsRows}

					</div>
				</div>
			</div>
		)
	}

	const renderUpcomingDropoffs = () => {
		let reservations = dropoffTab === 0 ? dropoffReservations.today : dropoffReservations.tomorrow
		let reservationsRows = renderReservationsRow(reservations, false)

		return (
			<div className={Styles.rowContainer}>
				<div style={{width: '100%', height: '100%'}}>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', height: 40}}>
						<p style={{fontWeight: 'bold', fontSize: 18}}>Upcoming Returns</p>
						<div style={{position: 'absolute', right: 0}}>
							<DayTabs onTabSelect={setDropoffTab}/>
						</div>
					</div>
					<div className={`${Styles.upcomingWrapper} ${GeneralStyles.boxShadow}`}>
						<div className={Styles.reservationRow} style={{borderBottom: `0.5px solid ${Colors.tableLineColor}`}}>
							<p style={{width: '30%', textAlign: 'left'}} className={Styles.reservationRowHeader}>VEHICLE</p>
							<p style={{width: '15%',textAlign: 'left'}} className={Styles.reservationRowHeader}>DROP-OFF TIME</p>
							<p style={{width: '20%',textAlign: 'left'}} className={Styles.reservationRowHeader}>CUSTOMER</p>
							<p style={{width: '15%',textAlign: 'left'}} className={Styles.reservationRowHeader}>PHONE #</p>
							<p style={{width: '20%',textAlign: 'right'}} className={Styles.reservationRowHeader}>STATUS</p>
						</div>
						{reservationsRows}

					</div>
				</div>
			</div>
		)
	}

	const renderChartHeader = (isSalesChart) => {
		return (
			<div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
				<p style={{marginLeft: 20}}>Weekly
					<span style={{fontWeight: 'bold', display: 'block', fontSize: 22}}>
								{isSalesChart ? 'Sales' : 'Rentals'}
							</span>
				</p>
				<div style={{cursor: 'pointer',marginLeft: 40, border: '1px solid gray', height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 10px', borderRadius: 5}}>
					<p>{`more \u2192`}</p>
				</div>
			</div>
		)
	}

	const renderAnalytics = () => {
		if(analyticsLoading) {
			return (
				<div style={{height: '30vh', alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%'}}>
					<CustomLoaderSpinner />
				</div>
			)
		}

		let utilizationAnalytics = analyticsState.vehicles_analytics
		let todayUtil = 0
		if(utilizationAnalytics) {
			let {vehicleCount, analytics} = utilizationAnalytics
			let todayKey = moment().format('MM/DD/YY')
			todayUtil = analytics[todayKey] || 0
			let rows = []
			Object.keys(analytics)?.map(dateKey => {
				let value = analytics[dateKey]
				let width = vehicleCount > 0 ? Math.round((value/vehicleCount)*100) : 0
				rows.push(
					(
						<div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
							<label className={GeneralStyles.darkText} style={{width: '30%', fontSize: 14}}>{moment(dateKey, 'MM/DD/YY').format('MMM Do, dddd')}</label>
							<div
								style={{width: '66%', marginLeft: '2%', height: 20, borderRadius: 10, border: `0.6px solid #d8d8d8`,  display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', backgroundColor: '#d8d8d8'}}
							>
								<div style={{height: '100%', backgroundColor: Colors.themeLight, width: `${width}%`, borderRadius: 10}} />
								<label
									style={{color: Colors.tertiaryTextColor, fontSize: 12, position: 'absolute', left: 10}}
								>
									{`${width}% (${value}/${vehicleCount})`}
								</label>

							</div>
						</div>
					)
				)
			})

			return (
				<div style={{flexDirection: 'row', display: 'flex', width: '100%', padding: '10px 0px',position: 'relative', top: -10}}>
					<div
						className={GeneralStyles.boxShadow}
						style={{width: '60%', display: 'flex', flexDirection: 'column', padding: '6px 20px'}}
					>
						<label className={GeneralStyles.darkText} style={{fontSize: Constants.headerSize, fontWeight: '600'}}>Utilization</label>
						<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%'}}>
							{rows}
						</div>
					</div>

					<div
						className={GeneralStyles.boxShadow}
						style={{width: '37%', marginLeft: '3%', padding: '6px 20px'}}>
						<label className={GeneralStyles.darkText} style={{fontSize: Constants.headerSize, fontWeight: '600'}}>On Rent</label>
						<div style={{width: '100%', marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
							<GaugeChart id="gauge-chart3"
							            style={{width: 300}}
							            nrOfLevels={30}
							            colors={[Colors.theme, Colors.themeLight]}
							            arcWidth={0.3}
							            percent={vehicleCount > 0 ? todayUtil/vehicleCount : 0}
							            textColor={Colors.primaryTextColor}
							/>
						</div>

						<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<label style={{color: Colors.primaryTextColor, fontSize: 16}}>{`${todayUtil} Rented`}</label>
							<label style={{color: Colors.primaryTextColor, fontSize: 16}}>{`${vehicleCount-todayUtil} Available`}</label>
						</div>
					</div>
				</div>
			)
		}
	}

	if(showLoading) {
		return (
			<div className={GeneralStyles.container} style={{height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
				<CustomLoaderSpinner />
			</div>
		)
	}

	return (
		<div className={GeneralStyles.container}>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
				<PageHeader header={'Overview'} subHeader={`${currentDateTime.format('dddd, MMM Do')} | ${currentDateTime.format('h:mm A')}`} />
				<CustomButtonContained
					text={'+ New Reservation'}
					onClick={onNewReservationClicked}
					style={{position: 'absolute', right: 0, zIndex: 10}}
					color={showOverlay ? 'white' : 'primary'}
				/>
			</div>

			<PositionedSnackbar
				onClose={() => {
					setShowError(false)
					setErrorMessage('')
				}}
				severity={'error'}
				openFlag={showError}
				message={errorMessage}
			/>


			<div className={GeneralStyles.overlay} id={'overlay'} />

			{
				showOverlay &&
				<div style={{position: 'absolute', right: 20, top: 80, backgroundColor: Colors.theme, height: 110, width: 220, borderRadius: 10, zIndex: 10}}>
					<div
						onClick={() => onNewNavigatorClicked('reservation')}
						style={{paddingLeft: 10,display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid ${Colors.secondaryTextColor}`, cursor: 'pointer'}}>
						<p style={{width: 180, color: Colors.tertiaryTextColor}}>Daily Rentals</p>
						<p style={{color: Colors.tertiaryTextColor}}>{`>`}</p>
					</div>
					<div
						onClick={() => onNewNavigatorClicked('chauffeur')}
						style={{paddingLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}}>
						<p style={{width: 180, color: Colors.tertiaryTextColor}}>Chauffeur Service</p>
						<p style={{color: Colors.tertiaryTextColor}}>{`>`}</p>
					</div>
				</div>
			}

			{renderAnalytics()}
			{renderUpcomingPickups()}
			{renderUpcomingDropoffs()}
		</div>
	)
}

const DayTabs = ({onTabSelect}) => {
	const [currentTab, setCurrentTab] = useState(0)

	const onTabClicked = (index) => {
		setCurrentTab(index)
		onTabSelect(index)
	}

	return (
		<div style={{display: 'flex', flexDirection: 'row', width: 160, justifyContent: 'space-between'}}>
			<p
				onClick={() => onTabClicked(0)}
				style={{color: currentTab === 0 ? Colors.primaryTextColor : Colors.secondaryTextColor, cursor: 'pointer'}}>
				Today
			</p>
			<p>{`\u2022`}</p>
			<p
				onClick={() => onTabClicked(1)}
				style={{color: currentTab === 1 ? Colors.primaryTextColor : Colors.secondaryTextColor, cursor: 'pointer'}}>
				Tomorrow
			</p>
		</div>
	)
}




export default Dashboard
